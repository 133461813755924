import styled from "styled-components";

export const ButtonInteressesEscolhaStyle = styled.button`
  background-color: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  border: 1px solid transparent;
  border-color: #dbdbdb;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(1em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(1em - 1px);
  text-align: center;
  white-space: normal;

  border-radius: 290486px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);

  color: ${(props) => {
    return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraPrimaria;
  }};

  .weex-remove-interesse-icon > svg > path {
    stroke: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
  }

  .weex-remove-interesse-icon {
  }

  .weex-remover-interesse-content {
    margin-left: 1em;
    background-color: ${(props) => {
      return props.theme.fundoTres;
    }};
    border-radius: 100px;
  }

  &:active {
    filter: brightness(0.5);
  }

  .disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
`;
