import React from "react";
import { AcerteTempoProvider } from "./AcerteTempoContext";
import AcerteTempo from "./AcerteTempo";

function AcerteTempoContainer() {
  return (
    <>
      <AcerteTempoProvider>
        <AcerteTempo />
      </AcerteTempoProvider>
    </>
  );
}

export default AcerteTempoContainer;
