import styled from "styled-components";

export const DetalhesDinamicaPresencialStyle = styled.div`
  .icon-center {
    height: 2rem;
    width: 2rem;
  }

  .custom-color-icon {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }
`;
