import React, { useEffect, useRef } from "react";
import { ButtonVideoStyle } from "./style";

function BotaoVideo(props) {
  let buttonRef = useRef(null);

  useEffect(() => {
    if (props.disabled) {
      return;
    }
    buttonRef.current.disabled = false;
  }, [props.disabled]);

  function onClick(event) {
    if (props.disabled) return;
    buttonRef.current.disabled = true;
    props.funcao && props.funcao(event);
  }

  return (
    <ButtonVideoStyle
      className={
        props.disabled === true
          ? `${props.classeComplementar} 'button disabled'`
          : `${props.classeComplementar}`
      }
      onClick={onClick}
      disabled={props.disabled}
      aria-label={props.nome}
      ref={buttonRef}
    >
      {props.nome}
    </ButtonVideoStyle>
  );
}

export default BotaoVideo;
