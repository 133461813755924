import styled from "styled-components";

export const ContadorRegressivoStyle = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  .weex-l-barra-regresso-container {
    height: 0.5rem;
    border-radius: 1rem;
    /* background-color: #e6e6e6db; */

    display: flex;
    align-items: center;
  }

  .weex-l-barra-regresso {
    height: 0.5rem;
    border-radius: 1rem;
    background: linear-gradient(to left, #4caf50, #ffd700, #ffa500, #ff5733);
    transition: width 1s linear;
  }

  .weex-l-barra-regresso-circle {
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
  }

  .weex-l-barra-regresso-circle--segundos {
    display: flex;
    flex-direction: column;
    width: 3rem;
    height: 3rem;
    left: 0;
  }

  .weex-l-barra-regresso-circle--contagem {
    font-size: 1.3rem;
    font-weight: 800;
    margin-top: 0.6rem;
    line-height: 0.6rem;
  }

  .weex-l-barra-regresso-circle--textoSegundos {
    font-size: 0.8rem;
  }

  .weex-l-barra-regresso-circle--img {
    min-width: 1.5rem;
    max-width: 1.5rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-top: 4rem;
    margin-bottom: 5rem;
    .weex-l-barra-regresso-container {
      flex-grow: 1;
      padding: 0.625rem;
      margin: 0.313rem;
      box-sizing: border-box;
    }

    .weex-l-barra-regresso {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }

    .weex-l-barra-regresso-circle {
      width: 3rem;
      height: 3rem;
    }

    .weex-l-barra-regresso-circle--segundos {
      width: 5rem;
      height: 5rem;
    }

    .weex-l-barra-regresso-circle--contagem {
      font-size: 2rem;
      line-height: 1rem;
    }

    .weex-l-barra-regresso-circle--textoSegundos {
      margin-top: 0.2rem;
      font-size: 1rem;
    }

    .weex-l-barra-regresso-circle--img {
      min-width: 2rem;
      max-width: 2rem;
    }
  }
  /* #endregion DESKTOP */
`;
