export default class PasswordService {
  validations = [
    {
      i18n: "senha.fraqueza.minimoCaracteres",
      label: "Tamanho mínimo de 8 caracteres.",
      regex: /^.{8,}$/,
    },
    {
      i18n: "senha.fraqueza.letraMinuscula",
      label: "Pelo menos uma letra minúscula.",
      regex: /[a-z]/,
    },
    {
      i18n: "senha.fraqueza.letraMaiuscula",
      label: "Pelo menos uma letra maiúscula.",
      regex: /[A-Z]/,
    },
    {
      i18n: "senha.fraqueza.caractereEspecial",
      label: "Pelo menos um caractere especial, por exemplo: !,@,#,$,%,&,*.",
      regex: /[!@#$%^&*(),.?":{}|<>]/,
    },
    {
      i18n: "senha.fraqueza.numero",
      label: "Pelo menos um número.",
      regex: /[0-9]/,
    },
  ];

  getPasswordWeaknesses = (password, i18n) => {
    const fraquezas = [];

    this.validations.forEach((validation) => {
      if (!validation.regex.test(password)) {
        fraquezas.push(i18n.message(validation.i18n, validation.label));
      }
    });

    return fraquezas;
  };

  calculatePasswordStrength = (password) => {
    let strength = 0;

    this.validations.forEach((validation) => {
      if (validation.regex.test(password)) {
        strength += 1;
      }
    });

    return (strength / this.validations.length) * 100;
  };
}
