import AgendaService from "./AgendaService";
import instance from "./Api";

export default class AtividadesService {
  agendaService = new AgendaService();
  carregando = false;
  iniciarAtividade(codigo, tipo, callback) {
    instance
      .post(
        this.host() +
          "/api/v1/campaign/schedule/" +
          codigo +
          "/activity/" +
          tipo,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        if (error.status === 500) {
          instance
            .post(
              this.host() +
                "/api/v1/campaign/schedule/" +
                codigo +
                "/activity/" +
                tipo,
            )
            .then((response) => {
              callback(null, response.data);
            })
            .catch((error) => {
              callback(error, null);
            });
        }
        callback(error, null);
      });
  }

  iniciarAtividadePromisse(codigo, tipo) {
    return new Promise((resolve, reject) => {
      instance
        .post(
          this.host() +
            "/api/v1/campaign/schedule/" +
            codigo +
            "/activity/" +
            tipo,
        )
        .then((response) => {
          resolve(response.data); // Resolve a Promise com os dados de resposta
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            // Tenta novamente em caso de erro 500
            instance
              .post(
                this.host() +
                  "/api/v1/campaign/schedule/" +
                  codigo +
                  "/activity/" +
                  tipo,
              )
              .then((response) => {
                resolve(response.data); // Resolve a Promise com os dados de resposta
              })
              .catch((error) => {
                reject(error); // Rejeita a Promise com o erro
              });
          } else {
            reject(error); // Rejeita a Promise com o erro para outros casos
          }
        });
    });
  }

  finalizarAtividade(codigo, codigoAtividade, tipo, corpo, callback) {
    if (this.carregando) {
      return;
    }
    this.carregando = true;
    instance
      .put(
        this.host() +
          "/api/v1/campaign/schedule/" +
          codigo +
          "/activity/" +
          codigoAtividade,
        corpo,
      )
      .then((response) => {
        this.agendaService.atualizarAgenda(
          codigo,
          codigoAtividade,
          tipo,
          corpo.numeroQuestoes,
          corpo.score,
          (erro, sucesso) => {
            if (sucesso) {
              callback(null, response.data);
            } else if (erro) {
              callback(
                "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                null,
              );
            }
            this.carregando = false;
          },
        );
      })
      .catch((error) => {
        callback(error, null);
        this.carregando = false;
      });
  }

  /**
   * Chama o endpoint de abrir ação (método iniciar atividade)
   * passando o parametro finalize=true indicando à API que a ação deve ser iniciada e na sequência fechada.
   * Isso é importante para as seguintes atividades: game, mural e extras, pois estas possuem características
   * específicas onde é necessário tal abordagem descrita acima.
   * @param {*} code - código da agenda
   * @param {*} codigoAtividade  - código da atividade
   * @param {*} callback - callback
   */
  abreFechaAcao(code, codigoAtividade, callback) {
    instance
      .post(
        this.host() +
          "/api/v1/campaign/schedule/" +
          code +
          "/activity/" +
          codigoAtividade +
          "?finalize=true",
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  registrarPontuacao(codigo, tipo, corpo, callback) {
    instance
      .post(this.host() + "/api/v1/score", corpo)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
