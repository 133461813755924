import React from "react";

export const IframeVideo = ({ video, onLoad }) => (
  <div className="video-with-desktop">
    <iframe
      className="video-container"
      title={video.title}
      frameborder="0"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
      width="100%"
      height="100%"
      allowfullscreen="allowfullscreen"
      src={video.urlParseada}
      onLoad={onLoad}
    ></iframe>
  </div>
);
