import instance from "./Api";

export default class DinamicaPresencialService {
  fazerCheckin(codigoCampanha, body, callback) {
    instance
      .post(
        this.host() +
          "/api/v1/campaign/" +
          codigoCampanha +
          "/presencialDynamic/checkin",
        body,
      )
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
