import React, { useEffect, useState } from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import LeadershipRanking from "../../comps/rankings/LeadershipRanking";
import PontuacaoUsuarioCorrente from "../../comps/rankings/PontuacaoUsuarioCorrente";
import RankingListaParticipantes from "../../comps/rankings/RankingListaParticipantes";
import SelectTreeDropdow from "../../comps/selectTreeDropdow/SelectTreeDropdow";
import WeexModal from "../../comps/weexModais/WeexModal";
import CampanhaService from "../../services/CampanhaService";
import RankingIndividualService from "../../services/RankingIndividualService";
import UsuarioService from "../../services/UsuarioService";
import { CampanhaRankingsStyled } from "./styled";

export default function CampanhaRankings() {
  const i18n = useSelector((state) => state.i18n);
  const navigate = useNavigate();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const campanhaService = new CampanhaService();
  const rankingIndividualService = new RankingIndividualService();
  const usuario = new UsuarioService().usuarioCorrente();
  const [participantFetched, setParticipantFetched] = useState(false);
  const unidades = campanhaService.getUnidades();
  const [loading, setLoading] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [rankingEvent, setRankingEvent] = useState([]);
  const [rankingTop3, setRankingTop3] = useState([]);
  const [rankingWithFormat, setRankingWithFormat] = useState([]);
  const [pontuacaoUsuario, setPontuacaoUsuario] = useState({
    name: "",
    rating: null,
    unit: "",
    unitUuid: "",
    subUnitUuid: "",
    nivel3Uuid: "",
    uuidParticipante: "",
  });
  const [participant, setParticipant] = useState({
    uuid: "",
    name: "",
    score: 0,
    position: 0,
  });
  const [filtrosParaBuscar, setFiltrosParaBuscar] = useState({
    units: [],
  });

  const [erro, setErro] = useState({
    titulo: "",
    mensagem: "",
    isWrong: false,
  });

  useEffect(() => {
    getParticipant().finally(() => setParticipantFetched(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (participantFetched) {
      onComponenteMonte();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtrosParaBuscar, participantFetched]);

  async function onComponenteMonte() {
    try {
      if (!loading) {
        setLoading(true);
      }

      const topRanking = await getRankingParticipantes();
      setRankingEvent(topRanking.data.topRanking);
      init(topRanking.data.topRanking);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const getRankingParticipantes = async () => {
    return rankingIndividualService.getRankingParticipanteCampanha(
      filtrosParaBuscar,
    );
  };

  const usuarioEstaNoRanking = (() => {
    let encontrei = false;
    let uuidParticipante = usuario.participantCode;
    rankingEvent.forEach((pontuacaoUsuario) => {
      if (pontuacaoUsuario.uuidParticipante === uuidParticipante) {
        encontrei = true;
      }
    });

    return encontrei && rankings?.length > 0;
  })();

  const init = (rank) => {
    let ranking = [];
    rank != null &&
      rank.forEach((partipante) => {
        let participant = {
          rating: partipante.score,
          name: partipante.name,
          unit: partipante.unit,
          uuidParticipante: partipante.uuidParticipante,
          position: partipante.position,
        };
        ranking.push(participant);
      });
    setRankings(ranking);
    setRankingTop3(ranking.slice(0, 3));
    positionRankingParticipant(rank);
    formaterNumber(rank);
  };

  const positionRankingParticipant = (rank) => {
    let position = 0;
    rank.forEach((participante, index) => {
      if (participant.uuid === participante.uuidParticipante) {
        position = parseInt(index) + 1;
      }
    });
    setParticipant({ ...participant, position: position });
  };

  const formaterNumber = (rank) => {
    let ranking = [];
    rank != null &&
      rank.forEach((partipante) => {
        let participant = {
          rating: partipante.score,
          name: partipante.name,
          unit: partipante.unit,
          uuidParticipante: partipante.uuidParticipante,
          isCompind: true,
          position: partipante.position,
        };
        ranking.push(participant);
      });

    let rankingWithFormat = ranking.map((ranking) => {
      let position = parseInt(ranking.position);
      if (position < 10) {
        ranking.position = "0" + position;
      } else {
        ranking.position = position.toString();
      }

      return ranking;
    });

    setRankingWithFormat(rankingWithFormat);
  };

  const getParticipant = () => {
    setLoading(true);
    return rankingIndividualService
      .getPontuacaoParticipanteCampanha()
      .then((participante) => {
        let unitParticipante = unidades.find(
          (unit) => unit.uuid === usuario.unit,
        );
        let subUnidadeParticipante = buscarElementosNasUnidades(
          unidades,
          usuario.subUnit,
        );
        let nivel3 = buscarElementosNasUnidades(unidades, usuario.nivel3);
        let unitName = unitParticipante ? unitParticipante.name : "";
        let unitUuid = unitParticipante ? unitParticipante.uuid : "";
        setParticipant({
          uuid: participante.data.uuid,
          score: participante.data.pontuacao,
          name: usuario.name,
          unit: unitName,
          isCompind: true,
        });
        setPontuacaoUsuario({
          name: usuario.name,
          rating: participante.data.pontuacao,
          uuidParticipante: participante.data.uuid,
          unit: unitName,
          unitUuid: unitUuid,
          subUnitUuid: subUnidadeParticipante
            ? subUnidadeParticipante.uuid
            : "",
          nivel3Uuid: nivel3 ? nivel3.uuid : "",
          isCompind: true,
        });
      })
      .catch((error) => {
        setPontuacaoUsuario({
          isCompind: true,
        });
      });
  };

  const buscarElementosNasUnidades = (unidades, target) => {
    let unidadeEscolhida = null;
    unidades.forEach((unidade) => {
      const unidadeTarget = searchInTree(unidade, target);
      if (unidadeTarget) {
        unidadeEscolhida = unidadeTarget;
      }
    });
    return unidadeEscolhida;
  };

  /**
   *
   * @param  {import('../../services/CampanhaService').Unit} node - nó raiz
   * @param {string} target - uuid do elemento a ser buscado
   * @returns {import('../../services/CampanhaService').Unit} retorna os nós raizes selecionados
   */
  const searchInTree = (node, target) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    if (node.uuid === target) {
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.subUnits && node.subUnits.length > 0) {
      for (const element of node.subUnits) {
        // Realiza a busca recursivamente em cada nó filho
        const result = searchInTree(element, target);
        if (result) {
          return result; // Retorna o resultado se encontrado em algum nó filho
        }
      }
    }

    return null; // Retorna null se o elemento não for encontrado
  };

  /**
   *
   * @param {import("../../services/CampanhaService").Unit[] } unidades
   * @param action
   */
  const setOnChangeSelect = (unidades, action) => {
    if (unidades && unidades.length > 0) {
      const unidadesUuids = unidades?.map((unidade) => unidade.uuid);
      setFiltrosParaBuscar({ units: unidadesUuids });
    } else {
      if (action === "SUA_UNIDADE") {
        setFiltrosParaBuscar({ units: getSuaUnidadeBusca() });
      } else if (action === "ALL") {
        setFiltrosParaBuscar({});
      } else if (action === "none") {
        setFiltrosParaBuscar({ units: [] });
      }
    }
  };

  const getSuaUnidadeBusca = () => {
    const levelUnit = getNivelUnit();
    switch (levelUnit) {
      case 1:
        return [pontuacaoUsuario.unitUuid];
      case 2:
        return [pontuacaoUsuario.subUnitUuid];
      case 3:
        return [pontuacaoUsuario.nivel3Uuid];
      default:
        return [];
    }
  };

  const getNivelUnit = () => {
    return campanhaService.campanhaCorrente().levelUnit;
  };

  const buscarParticipanteCampanha = async (search) => {
    try {
      const updatedFiltro = { ...filtrosParaBuscar, participantName: search };
      const response =
        await rankingIndividualService.getRankingParticipanteCampanha(
          updatedFiltro,
        );

      let ranking = [...response.data.topRanking];
      ranking.forEach((participante) => {
        participante.rating = participante.score;
        participante.isCompind = true;
      });

      formaterNumber(ranking);
    } catch (error) {
      if (error?.error?.message) {
        return setErro({
          titulo: i18n.message(
            "ranking.erro.buscar.participante.titulo",
            "Erro ao buscar participante",
          ),
          mensagem: error.error.message,
          isWrong: true,
        });
      }
      setErro({
        titulo: i18n.message(
          "ranking.erro.buscar.participante.titulo",
          "Erro ao buscar participante",
        ),
        mensagem: i18n.message(
          "ranking.erro.buscar.participante.mensagem",
          "Entre em contato com o suporte ou tente novamente mais tarde.",
        ),
        isWrong: true,
      });
    }
  };

  const callbackModal = () => {
    return setErro({
      titulo: "",
      mensagem: "",
      isWrong: false,
    });
  };

  return (
    <>
      {erro.isWrong === true && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <CampanhaRankingsStyled>
        <div className="dinamica-content-desktop">
          <span className="header-ranking">
            <span className="size-content">
              <BotaoAsALinkIconeEsquerda
                classeComplementar="header-como-funciona-desktop-botao"
                icon="fas fa-arrow-left "
                nome={i18n.message("geral.voltar", "Voltar")}
                funcao={() => navigate("/atividades")}
              />
            </span>
          </span>
          <div className="dinamica-container">
            <div className="ranking-page">
              <div className="ranking-top">
                {getNivelUnit() > 0 && (
                  <div>
                    <h2 className="label-select">
                      {i18n.message(
                        "rankingCampanha.selecione.filtroRanking.label",
                        "Filtrar ranking",
                      )}
                    </h2>
                    <div className="content-filtros">
                      <SelectTreeDropdow
                        unidades={unidades}
                        onChangeSelect={setOnChangeSelect}
                      />
                    </div>
                  </div>
                )}
                <div>
                  {!isDesktopMode && !usuarioEstaNoRanking ? ( // caso seja mobile
                    <>
                      <div className="info-position">
                        <p className="info-pontuacao">
                          {i18n.message(
                            "info.pontuacao",
                            "Sua posição: fora do top 50 ",
                          )}
                        </p>
                      </div>
                      <div className="isMobile pontuacao-usuario-corrente-mobile">
                        <PontuacaoUsuarioCorrente
                          pontuacaoUsuario={pontuacaoUsuario}
                          ranking={rankings}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <LeadershipRanking topRanking={rankingTop3} />
                </div>
              </div>
              <div className="ranking-list">
                <RankingListaParticipantes
                  pontuacaoUsuario={pontuacaoUsuario}
                  rankingFormat={rankingWithFormat}
                  usuarioEstaNoRanking={usuarioEstaNoRanking}
                  loading={loading}
                  search={buscarParticipanteCampanha}
                />
              </div>
            </div>
          </div>
        </div>
      </CampanhaRankingsStyled>
    </>
  );
}
