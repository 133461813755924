import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";

/**
 *
 * @param {Object} props
 * @param {function(): void} props.onChange
 * @param {String} props.placeholder
 * @param {String} props.ariaLabel
 * @returns
 */
function RankingInputSearch({ onChange, placeholder, ariaLabel }) {
  const [searchTerm, setSearchTerm] = useState("");
  const i18n = useStore().getState().i18n;
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // função setSearch que será executada após um certo tempo
      if (searchTerm.length > 2 || searchTerm.length === 0) {
        onChange(searchTerm);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  return (
    <div className="inputSearch">
      <div className="field">
        <label className="control has-icons-left has-icons-right">
          <input
            onChange={handleSearch}
            value={searchTerm}
            className="input search"
            placeholder={placeholder}
            aria-label={ariaLabel}
            type="text"
            autoComplete="off"
          />
          <span className="icon is-small is-left button-fa-times">
            <i className="fas fa-search"></i>
          </span>
          <button
            type="reset"
            aria-label={i18n.message(
              "aria.search.reset",
              "botão para limpar o campo de busca",
            )}
            className="icon button-link is-small is-right button-fa-times"
            onClick={handleClear}
          >
            <i className="fas fa-times"></i>
          </button>
        </label>
      </div>
    </div>
  );
}

export default RankingInputSearch;
