import React, { useEffect, useRef } from "react";
import { ButtonPrimarioStyle } from "../botaoPrimario/style";
import ClipboardJS from "clipboard";
import CorrenteSVG from "../svg/convite/CorrenteSVG";

export default function BotaoCopiarLink({
  link,
  texto,
  erroCopiarLink,
  linkCopiadoComSucesso,
}) {
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    let clipboard = new ClipboardJS(buttonRef.current, {
      target: () => inputRef.current,
    });

    clipboard.on("success", (e) => {
      linkCopiadoComSucesso();
      e.clearSelection();
    });

    clipboard.on("error", (e) => {
      erroCopiarLink();
      e.clearSelection();
    });

    return () => {
      clipboard.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icone = () => {
    return (
      <>
        <CorrenteSVG width={"1rem"} height={"1rem"} />
      </>
    );
  };

  return (
    <>
      <input
        type="text"
        defaultValue={link}
        ref={inputRef}
        style={{ position: "absolute", left: "-9999px" }}
      />
      <ButtonPrimarioStyle ref={buttonRef}>
        <span className="content-botao">
          {icone()}
          {texto}
        </span>
      </ButtonPrimarioStyle>
    </>
  );
}
