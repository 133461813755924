import React from "react";
import { useStore } from "react-redux";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { WeexModalDialog } from "./WeexModalDialog";
import { useState } from "react";
import { useRef } from "react";

/**
 * @param {Object} props
 * @param {string} props.titulo
 * @param {string|Array} props.conteudo
 * @param {function(): void} props.fecharModalCallback
 */
function WeexModal({ titulo, conteudo, fecharModalCallback }) {
  const i18n = useStore().getState().i18n;
  const [showModal, setShowModal] = useState(true);
  const dialogRef = useRef(null);

  const ocultar = () => {
    fecharModalCallback();
    setShowModal(false);
  };

  const renderConteudo = () => {
    if (typeof conteudo === "string") {
      return <p>{conteudo}</p>;
    } else if (Array.isArray(conteudo)) {
      return (
        <ul>
          {conteudo.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return showModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={ocultar}>
      <div className={"modal is-active animations-popIn"}>
        <div className="modal-background weex-modal-background"></div>
        <div className="modal-card weex-modal-card">
          <header className="modal-card-head weex-modal-card-header">
            <button
              onClick={ocultar}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.modal.generico.de.sucesso.erro.fechar.modal",
                "Fecha a janela e volta para a página anterior.",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <h1 className="nome-atividade nome-atividade-font-weight-normal">
              {titulo}
            </h1>
          </header>
          <section className="modal-card-body has-text-centered">
            <div className="weex-modal-card-body__texto">
              <p>{renderConteudo()}</p>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <BotaoPrimario
              nome="Ok"
              funcao={ocultar}
              ariaStatusMessage={i18n.message(
                "aria.status.weexmodal.botaook",
                "Botão ok clicado.",
              )}
            />
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default WeexModal;
