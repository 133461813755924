import React, { useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import MuralService from "../../services/MuralService";
import VideoIframe from "../VideoIframe/VideoIframe";
import BotaoIcone from "../botaoIcone/BotaoIcone";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import BotaoVideoMural from "../botaoVideoMural/BotaoVideoMural";
import Spinner from "../WeexSpinner/Spinner";
import WeexModalAnexarVideo from "./WeexModalAnexarVideo";
import { WeexModalDialog } from "./WeexModalDialog";

/**
 * @param {Object} props
 * @param {string} props.ariaTextArea
 * @param {boolean} props.isPublicacao
 * @param {function(): void} props.fecharModalCallback
 * @param {function({ postagem: string, imagemCode: any }): void} props.create // TODO: Encontrar a  tipagem de imagemCode
 */
function EscreverPublicacao({
  ariaTextArea,
  fecharModalCallback,
  isPublicacao,
  create,
}) {
  const [request, setRequest] = useState({
    postagem: "",
    imagemCode: null,
    urlVideo: null,
  });

  const i18n = useStore().getState().i18n;
  const [tamanhoTexto, setTamanhoTexto] = useState(0);
  const [botaoIsDesabled, setBotaoIsDesabled] = useState(null);
  const [isSpinnerAtivo, setisSpinnerAtivo] = useState(false);
  const [processandoImagem, setProcessandoImagem] = useState(false);
  const [imagemProcessada, setImagemProcessada] = useState(false);
  const [imagem, setImagem] = useState("");
  const [arquivo, setArquivo] = useState();
  const [mostrarDialogModal, setMostrarDialogModal] = useState(true);
  const [isAnexarVideoModal, setisModalAnexarVideoModal] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const dialogRef = useRef(null);
  const refForm = useRef(null);

  function submit(event) {
    event.preventDefault();

    if (!request?.postagem?.trim()) {
      alert(
        i18n.message(
          "dinamica.mural.publicar.alert.texto",
          "Texto não pode estar vazio!",
        ),
      );
      return;
    }

    setisSpinnerAtivo(true);
    if (isPublicacao) {
      if (!request.imagemCode) {
        delete request.imagemCode;
      }
      create(request);
    } else {
      delete request.imagemCode;
      create(request);
    }

    fecharModalCallback();
  }

  useEffect(() => {
    if (!arquivo) {
      setImagem(undefined);
      return;
    }

    const preview = URL.createObjectURL(arquivo);
    setImagem(preview);

    return () => URL.revokeObjectURL(preview);
  }, [arquivo]);

  useEffect(() => {
    if (request) {
      const isValid = refForm.current.checkValidity();

      if (!isValid) {
        setBotaoIsDesabled(false);
      } else {
        setBotaoIsDesabled(null);
      }
    }
  }, [request]);

  function ativaModalAnexarVideo() {
    setisModalAnexarVideoModal(true);
  }

  function enviarImagem(e) {
    setProcessandoImagem(true);
    const tamanhoArquivo = 1024 * 1024 * 30;
    const file = e.target.files[0];
    if (file.size > tamanhoArquivo) {
      setProcessandoImagem(false);
      alert(
        i18n.message(
          "dinamica.mural.publicar.alert.imagem",
          "Imagem maior que o tamanho permitido de 30 MB!",
        ),
      );
    } else {
      const service = new MuralService();
      service.enviarImagem(file, (erro, sucess) => {
        if (sucess) {
          setRequest({ ...request, imagemCode: sucess.code });
          setProcessandoImagem(false);
          setImagemProcessada(true);
          setArquivo(file);
        }
        if (erro) {
          setRequest({ ...request, imagemCode: null });
          alert(JSON.stringify(erro));
          setProcessandoImagem(false);
          setImagemProcessada(false);
          setImagem("");
        }
      });
    }
  }

  function anexarVideo(url) {
    setUrlVideo(url);
    setRequest({ ...request, urlVideo: url });
    desativaModalAnexarVideo();
  }

  function desativaModalAnexarVideo() {
    setisModalAnexarVideoModal(false);
  }

  function hasVideo() {
    return urlVideo.length > 0;
  }

  function renderImagem() {
    if (imagemProcessada) {
      return (
        <div className="video-publicacao">
          <img
            src={imagem}
            className="preview"
            alt={i18n.message(
              "alt.mural.publicar.imagem.erro",
              "Formato não suportado",
            )}
          ></img>
        </div>
      );
    }
  }

  function renderVideo() {
    if (hasVideo()) {
      return (
        <div className="video-publicacao">
          <VideoIframe
            urlVideo={urlVideo}
            controls={false}
            showTitle={false}
            title={i18n.message(
              "arial.mural.publicar.video.titleAnexar",
              "Video Anexado",
            )}
          ></VideoIframe>
        </div>
      );
    }
  }

  function removerVideo() {
    setUrlVideo("");
  }

  function removerImagem() {
    setImagemProcessada(false);
    setImagem("");
    setRequest({ ...request, imagemCode: null });
  }

  function handlerRemover() {
    if (hasVideo()) {
      removerVideo();
    } else if (imagemProcessada) {
      removerImagem();
    }
  }

  function preViewRender() {
    return (
      <div className="video-publicacao-container">
        {hasVideo() ? renderVideo() : renderImagem()}

        {hasVideo() || imagemProcessada ? (
          <BotaoIcone
            icon="fas fa-trash"
            type="button"
            funcao={handlerRemover}
            label={i18n.message(
              "label.mural.publicar.remover",
              "Remover imagem ou video",
            )}
          ></BotaoIcone>
        ) : (
          ""
        )}
      </div>
    );
  }

  function fecharDialogModal() {
    fecharModalCallback();
    setMostrarDialogModal(false);
    dialogRef.current.close();
  }

  const buttonClick = () => {
    //se o form for valido ele vai chamar o submit mesmo que o botao esteja desabilitado
    if (refForm.current.checkValidity()) {
      refForm.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    } else {
      //se o form for invalido ele vai chamar o reportValidity para mostrar os erros
      refForm.current.reportValidity();
    }
  };

  return mostrarDialogModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={fecharDialogModal}>
      {isAnexarVideoModal && (
        <WeexModalAnexarVideo
          visivel={isAnexarVideoModal}
          confirma={anexarVideo}
          cancela={desativaModalAnexarVideo}
        ></WeexModalAnexarVideo>
      )}
      <div className={"modal is-active"}>
        <div className="modal-background weex-modal-background"></div>
        <div className="modal-card weex-modal-card modal-card-publicacao ">
          <form ref={refForm} onSubmit={submit}>
            <header className="modal-card-head weex-modal-card-header weex-modal-card-header-publicacao">
              <button
                onClick={fecharDialogModal}
                className="close-btn"
                aria-label={i18n.message(
                  "aria.label.mural.fechar.modal",
                  "Fecha a janela e volta para a página mural social.",
                )}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message(
                    "alt.button.imagem.fechar",
                    "ícone em formato de um X indicando o encerramento de uma ação.",
                  )}
                />
              </button>
            </header>
            <section className="modal-card-body has-text-centered modal-card-body-publicacao">
              <div className="titulo-icone titulo-mural-modal">
                <div className="icon-mural-modal">
                  <span className="icon color-fundo-secundaria">
                    <i className="fa fa-comment-dots"></i>
                  </span>
                </div>
                {isPublicacao ? (
                  <h3 className="titulo-modal">
                    {i18n.message(
                      "dinamica.mural.publicar.titulo",
                      "ESCREVER PUBLICAÇÂO",
                    )}
                  </h3>
                ) : (
                  <h3 className="titulo-modal">
                    {i18n.message("dinamica.mural.comentar.titulo", "COMENTAR")}
                  </h3>
                )}
              </div>
              <div className="weex-modal-card-body__texto weex-modal-card-body__publicacao">
                <div>
                  <div>
                    <label
                      aria-label={ariaTextArea}
                      htmlFor="escreverPublicacao"
                    ></label>
                    <textarea
                      id="escreverPublicacao"
                      required
                      maxLength="280"
                      minLength="1"
                      value={request?.postagem}
                      onChange={(e) => {
                        setRequest({ ...request, postagem: e.target.value });
                        setTamanhoTexto(e.target.value.length);
                      }}
                      className="textarea text-area-feedback-video textarea-mural"
                      placeholder={
                        isPublicacao
                          ? i18n.message(
                              "dinamica.mural.publica.placeholder",
                              "Preencha sua publicação",
                            )
                          : i18n.message(
                              "dinamica.mural.comentar.placeholder",
                              "Preencha seu comentário",
                            )
                      }
                    ></textarea>
                    <p className="count-text-area">{tamanhoTexto}/280</p>
                  </div>
                  {isPublicacao ? (
                    <>
                      <div className="imagem-publicacao textos-cor-secundaria">
                        <label
                          className="color-fundo-secundaria pointer"
                          htmlFor="input-file"
                        >
                          <input
                            id="input-file"
                            name="upload"
                            type="file"
                            onChange={enviarImagem}
                            accept="image/*"
                          />
                          <span className="icon button-link color-fundo-secundaria">
                            <i className="fa fa-images"></i>
                          </span>
                          {i18n.message(
                            "dinamica.mural.publica.upload",
                            "Selecione uma imagem para anexar",
                          )}
                        </label>
                        <Spinner isVisivel={processandoImagem}></Spinner>
                      </div>
                      <div className="imagem-publicacao textos-cor-secundaria">
                        <BotaoVideoMural
                          funcao={ativaModalAnexarVideo}
                          disabled={imagemProcessada}
                        ></BotaoVideoMural>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {preViewRender()}
                </div>
              </div>
            </section>
            <Spinner isVisivel={isSpinnerAtivo}></Spinner>

            <footer
              className="modal-card-foot weex-modal-card-footer"
              style={{ textAlign: "center" }}
            >
              <BotaoPrimario
                classeComplementar={
                  botaoIsDesabled ? "weex-mural-button-disabled" : ""
                }
                type="submit"
                nome={i18n.message("dinamica.mural.publicar", "Publicar ")}
                disabled={botaoIsDesabled}
                funcao={buttonClick}
                ariaStatusMessage={i18n.message(
                  "aria.status.escreverpublicacao.publicar",
                  "Botão publicar clicado.",
                )}
              />
            </footer>
          </form>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default EscreverPublicacao;
