import React from "react";
import { useStore } from "react-redux";

const QuaseLa = () => {
  const i18n = useStore().getState().i18n;
  return (
    <>
      <svg
        id="Camada_2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 335.84 335.43"
      >
        <path
          d="m167.79,266.18c-53.73-.39-98.11-38.44-98.79-99.44-.61-54.04,44.8-98.16,99.23-97.96,54.49.2,99.65,44.65,98.62,98.58-1.18,61.65-46.2,98.6-99.06,98.81Zm.07-185.08c-47.88.52-86.14,34.28-86.9,87.22-.68,47.44,39.13,86.38,87.01,86.35,47.9-.03,87.64-38.97,86.9-86.45-.83-52.92-39.04-86.63-87.01-87.12Z"
          fill="#FE2A40"
        />
        <path
          d="m289.17,53.16c-1.29,2.11-1.99,3.89-3.23,5.15-7.05,7.2-14.2,14.32-21.38,21.4-3.2,3.15-6.6,3.32-9.21.66-2.61-2.65-2.38-5.99.8-9.19,7.19-7.24,14.44-14.43,21.67-21.64,2.06-2.05,4.65-2.93,7.11-1.39,1.7,1.06,2.74,3.17,4.24,5.01Z"
          fill="#FE2A40"
        />
        <path
          d="m82.88,76.47c-1.43,1.75-2.48,4.12-4.2,4.81-1.73.7-4.89.51-6.13-.65-8.16-7.67-16.05-15.62-23.83-23.67-2.28-2.36-1.69-5.92.66-8.03,2.47-2.21,5.68-2.19,8.39.42,5.2,5.01,10.25,10.16,15.37,15.26,2.29,2.28,4.69,4.48,6.82,6.9,1.1,1.25,1.73,2.9,2.92,4.96Z"
          fill="#FE2A40"
        />
        <path
          d="m82.95,258.86c-1.26,2.14-1.91,3.96-3.13,5.21-7.13,7.3-14.37,14.5-21.65,21.66-3.03,2.98-6.36,3.12-8.95.63-2.6-2.51-2.58-5.96.34-8.92,7.25-7.36,14.6-14.62,21.91-21.92,2.03-2.02,4.54-3.11,7.07-1.54,1.72,1.06,2.84,3.08,4.4,4.88Z"
          fill="#FE2A40"
        />
        <path
          d="m259.26,252.53c2.13,1.27,3.95,1.93,5.21,3.15,7.31,7.12,14.53,14.34,21.7,21.61,2.97,3.01,3.09,6.37.59,8.93-2.52,2.58-5.96,2.55-8.94-.38-7.37-7.24-14.65-14.58-21.95-21.89-2.03-2.03-3.09-4.54-1.51-7.06,1.07-1.71,3.1-2.82,4.91-4.37Z"
          fill="#FE2A40"
        />
        <path
          d="m173.92,22.14c0,5.11.05,10.22-.02,15.33-.06,4.18-2.32,6.69-5.9,6.73-3.62.04-6.02-2.43-6.05-6.57-.07-10.35-.07-20.69,0-31.04.03-4.05,2.55-6.66,6.08-6.6,3.53.07,5.83,2.65,5.88,6.81.07,5.11.02,10.22.02,15.33Z"
          fill="#FE2A40"
        />
        <path
          d="m173.92,313.65c0,4.99.05,9.97-.02,14.96-.05,4.13-2.37,6.75-5.87,6.82-3.63.07-6.08-2.63-6.1-6.96-.05-10.1-.05-20.19,0-5.29.02-4.45,2.31-6.99,6.05-6.95,3.71.04,5.88,2.59,5.92,7.09.04,5.11.01,10.22,0,15.33Z"
          fill="#FE2A40"
        />
        <path
          d="m21.96,173.7c-4.99,0-9.98.04-14.97-.01-4.3-.05-7.07-2.53-6.98-6.11.08-3.45,2.77-5.82,6.87-5.84,10.11-.05,20.22-.05,5.32,0,4.35.02,7.02,2.45,6.94,6.1-.08,3.51-2.67,5.8-6.82,5.85-5.12.06-10.23.01-15.35.02Z"
          fill="#FE2A40"
        />
        <path
          d="m313.73,173.7c-5.12,0-10.23.06-15.35-.02-4.1-.06-6.68-2.46-6.66-6,.02-3.53,2.59-5.91,6.72-5.94,10.23-.06,20.47-.06,5.7,0,4.03.02,6.73,2.54,6.71,5.99-.02,3.45-2.71,5.89-6.77,5.95-5.12.07-10.23.02-15.35.02Z"
          fill="#FE2A40"
        />
        <path
          d="m193.75,167.05c5.64,5.32,11.19,10.5,16.67,15.74,6.17,5.9,7.74,13.16,4.43,20-3.99,8.23-11.38,12.84-19.6,11.23-3.47-.68-6.99-2.7-9.75-4.99-5.74-4.78-10.99-10.15-17.5-16.27-.95,1.46-1.65,2.97-2.72,4.13-4.48,4.87-8.96,9.75-13.66,14.41-7.15,7.07-16.74,6.5-25.02-1.26-6.66-6.24-7.42-16.39-1.44-23.3,4.57-5.28,9.53-10.23,14.31-15.33.83-.89,1.61-1.84,2.64-3.02-5.93-5.6-11.78-11-17.48-16.56-6.01-5.85-7.01-14.09-2.73-20.83,5.48-8.64,13.75-12.12,21.95-8.88,2.48.98,4.8,2.67,6.81,4.46,5.31,4.73,10.42,9.7,15.61,14.57.18.17.42.26,1.1.67,2.88-3.04,5.85-6.17,8.82-9.31,2.49-2.63,4.93-5.31,7.47-7.88,7.46-7.55,17.05-7.25,25.55.74,6.85,6.44,7.39,16.77,1.02,23.91-4.73,5.31-9.75,10.37-14.63,15.55-.58.62-1.1,1.29-1.88,2.21Zm-25.41,11.14c2.04,1.32,3.95,2.21,5.44,3.57,6.62,6.08,13.09,12.31,19.65,18.46,3.47,3.25,5.5,3.12,8.86-.42,3.12-3.29,3.07-5.28-.34-8.52-6.43-6.1-12.91-12.14-19.34-18.24-4.57-4.32-4.6-7.03-.2-11.68,5.92-6.26,11.85-12.5,17.77-18.76,3.55-3.76,3.52-5.7-.07-9.11-3.4-3.23-5.32-3.13-8.79.52-6.1,6.43-12.16,12.89-18.28,19.3-4.1,4.29-6.72,4.35-11.12.23-6.47-6.05-12.9-12.16-19.36-18.22-3.5-3.29-5.63-3.23-8.85.19-3.3,3.49-3.24,5.35.39,8.79,6.43,6.1,12.92,12.14,19.34,18.25,4.47,4.26,4.5,6.82.19,11.38-6.16,6.54-12.48,12.94-18.43,19.67-1.22,1.38-2.04,4.34-1.41,5.9,2.12,5.21,7.05,5.83,10.89,1.79,6.19-6.51,12.31-13.09,18.58-19.53,1.31-1.35,3.12-2.22,5.09-3.57Z"
          fill="#FE2A40"
        />
      </svg>
      <title>
        {i18n.message(
          "alt.dinamica.fatofake.icone.quaseLa",
          "ícone em vermelho com um x no centro e raios ao redores para indicar atenção",
        )}
      </title>
    </>
  );
};

export default QuaseLa;
