import React, { useState } from "react";
import DetalhesDinamicaPresencial from "../../comps/presencial/DetalhesDinamicaPresencial";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStore } from "react-redux";
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import Star from "../../comps/svg/star";
import AtividadePresencialService from "../../services/AtividadePresencialService";
import CampanhaService from "../../services/CampanhaService";
import { AvaliacaoDinamicaPresencialStyled } from "./style";
import StarCompleta from "../../comps/svg/starCompleta";
import WeexModal from "../../comps/weexModais/WeexModal";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";

export default function AvaliacaoDinamicaPresencial() {
  let location = useLocation();
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;
  let { codigoAtividade } = useParams();
  const dinamicaPresencial = location.state?.dinamicaPresencial;

  const [feedback, setFeedback] = useState({
    code: codigoAtividade,
    message: "",
    rating: 0,
  });

  const [feedbackEnviado, setFeedbackEnviado] = useState(
    dinamicaPresencial?.feedbackPresencial,
  );

  const [validate, setValidate] = useState(false);

  const atividadePresencialService = new AtividadePresencialService();

  const campanhaService = new CampanhaService();

  const evento = campanhaService.campanhaCorrente();

  const [feedbackRequestApi, setFeedbackRequestApi] = useState(false);

  const [mensagemQuantidadeEstrelas, setMensagemQuantidadeEstrelas] =
    useState("");

  const [erro, setErro] = useState(null);

  const setRatingRequest = (rating) => {
    setFeedback({ ...feedback, rating: rating });
  };

  const mensagemQuantidadeEstrelasSelecionadas = (mensagem) => {
    setMensagemQuantidadeEstrelas(mensagem);
  };

  const enviarFeedback = async () => {
    if (feedback.rating === 0) {
      setValidate(true);
      setFeedbackRequestApi(feedbackRequestApi === false ? null : false);
      return;
    }
    try {
      const result = await atividadePresencialService.feedbackSaveRequest(
        evento.code,
        feedback,
      );
      setFeedbackEnviado(true);

      navigate(`/missionFinished/FEEDBACK/FEEDBACK/${codigoAtividade}`, {
        state: { earnedPoints: result?.data?.score },
      });
    } catch (error) {
      setFeedbackEnviado(false);
      if (
        error?.response &&
        error?.response?.data &&
        error?.response?.data?.message
      ) {
        setErro({
          titulo: i18n.message(
            "dinamica.presencial.erro.realizar.feedback.titulo",
            "Erro ao realizar feedback",
          ),
          mensagem: error?.response?.data?.message,
          voltarAgenda: true,
        });
      } else {
        setErro({
          titulo: i18n.message(
            "dinamica.presencial.erro.realizar.feedback.titulo",
            "Erro ao realizar feedback",
          ),
          mensagem: i18n.message(
            "dinamica.presencial.erro.realizar.feedback.mensagem",
            "Entre em contato com o suporte ou tente novamente mais tarde.",
          ),
          voltarAgenda: true,
        });
      }
    } finally {
      setFeedbackRequestApi(feedbackRequestApi === false ? null : false);
    }
  };

  const voltar = () => {
    navigate("/home");
  };

  const callbackModal = () => {
    setErro(null);
    navigate("/atividades");
  };

  return (
    <AvaliacaoDinamicaPresencialStyled>
      {erro != null && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <DetalhesDinamicaPresencial
        page="feedback"
        titulo={i18n.message(
          "dinamica.presencial.feedback.titulo.sucesso",
          "Presença confirmada!",
        )}
        dinamicaPresencial={dinamicaPresencial}
        voltar={voltar}
      ></DetalhesDinamicaPresencial>

      {!feedbackEnviado && (
        <div className="mx-6">
          <p className="mt-4 has-text-centered has-text-weight-bold  weex-l-texto-customizado">
            {i18n.message(
              "dinamica.presencial.feedback",
              "Conte-nos o que achou dessa atividade:",
            )}
          </p>

          <StarRating
            setRatingRequest={setRatingRequest}
            mensagemQuantidadeEstrelasSelecionadas={
              mensagemQuantidadeEstrelasSelecionadas
            }
          />
          <span className="weex-g-visually-hidden" role="status">
            {mensagemQuantidadeEstrelas}
          </span>
          <small>
            {validate && (
              <p className="has-text-danger has-text-centered mt-2">
                {i18n.message(
                  "dinamica.presencial.feedback.avalidacao",
                  "Por favor, avalie a atividade",
                )}
              </p>
            )}
          </small>

          <label
            className="weex-g-visually-hidden"
            htmlFor="feedback-presencial"
          >
            {i18n.message(
              "arial.label.feedback.presencial",
              "Digite aqui seu comentário sobre a atividade: ",
            )}
          </label>

          <textarea
            id="feedback-presencial"
            onChange={(e) =>
              setFeedback({ ...feedback, message: e.target.value })
            }
            className="textarea  mt-4 mb-4"
            placeholder={i18n.message(
              "dinamica.presencial.feedback.placeholder",
              "Escreva aqui seu comentário",
            )}
          ></textarea>
          {!feedbackEnviado && (
            <div>
              <BotaoPrimario
                funcao={enviarFeedback}
                disabled={feedbackRequestApi}
                classeComplementar="mb-5"
                nome={i18n.message(
                  "dinamica.presencial.feedback.botao",
                  "enviar comentário",
                )}
              ></BotaoPrimario>
            </div>
          )}
        </div>
      )}
      {feedbackEnviado && (
        <p className="my-4 has-text-centered has-text-weight-bold">
          {i18n.message(
            "dinamica.presencial.feedback.agradece",
            "Agradecemos o seu feedback!",
          )}
        </p>
      )}
      <div className="mx-6">
        <BotaoSecundario
          funcao={voltar}
          classeComplementar="mb-5"
          nome={i18n.message(
            "dinamica.presencial.feedback.botao.voltar",
            "Voltar",
          )}
          ariaLabelMsg={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
        ></BotaoSecundario>
      </div>
    </AvaliacaoDinamicaPresencialStyled>
  );
}

const StarRating = ({
  setRatingRequest,
  mensagemQuantidadeEstrelasSelecionadas,
}) => {
  const i18n = useStore().getState().i18n;
  const [rating, setRating] = useState(0);

  const setRatingHandler = (rating) => {
    setRating(rating);
    setRatingRequest(rating);
    const mensagemSingular = i18n.message(
      "dinamica.presencial.feedback.sel.singular.ariaHiddenStatus",
      "estrela selecionada!",
    );
    const mensagemPlural = i18n.message(
      "dinamica.presencial.feedback.selecionado.plural.ariaHiddenStatus",
      "estrelas selecionadas!",
    );
    const mensagem = `${rating} ${
      rating === 1 ? mensagemSingular : mensagemPlural
    }`;
    mensagemQuantidadeEstrelasSelecionadas(mensagem);
  };

  return (
    <div className="star-rating is-flex is-justify-content-center">
      {[1, 2, 3, 4, 5].map((starNumber) => (
        <button
          key={starNumber}
          aria-label={`Estrela ${starNumber} de 5`}
          className="container-avaliacao botao-invisivel mt-4 mr-1 is-clickable"
          onClick={() => setRatingHandler(starNumber)}
        >
          <span className="icon-star-avaliacao">
            {starNumber <= rating ? (
              <StarCompleta></StarCompleta>
            ) : (
              <Star></Star>
            )}
          </span>
        </button>
      ))}
    </div>
  );
};
