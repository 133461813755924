import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AtividadeEntity from "../../comps/agenda/AtividadeEntity";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import RankingListaParticipantes from "../../comps/rankings/RankingListaParticipantes";
import LeadershipRanking from "../../comps/rankings/LeadershipRanking";
import PontuacaoUsuarioCorrente from "../../comps/rankings/PontuacaoUsuarioCorrente";
import RankingSelect from "../../comps/rankings/RankingSelect";
import CampanhaService from "../../services/CampanhaService";
import RankingGameService from "../../services/RankingGameService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import WeexModal from "../../comps/weexModais/WeexModal";
import { CampanhaRankingsStyled } from "../campanhaRanking/styled";

function GameRankings() {
  const navigate = useNavigate();
  const i18n = useSelector((state) => state.i18n);
  const gameUuid = useSelector((state) => state.gameUuid);
  const campanhaService = new CampanhaService();
  const rankingGameService = new RankingGameService();
  const usuarioService = new UsuarioService();
  const usuario = usuarioService?.usuarioCorrente();
  const [game, setGame] = useState({});
  // const [mensagemUsuario, setMensagemUsuario] = useState("");
  const [participantePesquisadoNoRanking, setParticipantePesquisadoNoRanking] =
    useState([]);
  const [rankings, setRankings] = useState([]);
  const [rankingTop3, setRankingTop3] = useState([]);
  const [rankingWithFormat, setRankingWithFormat] = useState([]);
  const [erro, setErro] = useState({
    titulo: "",
    mensagem: "",
    isWrong: false,
  });
  const [pontuacaoUsuario, setPontuacaoUsuario] = useState({
    name: "",
    rating: null,
    unit: "",
    uuidParticipante: "",
  });

  useEffect(() => {
    let temaService = new TemaService(campanhaService?.campanhaCorrente());
    temaService.aplicarTema("HOME");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const games = rankingGameService.getGamesAvailable();
    if (games.length > 0) {
      const selectedGame = games.find(
        (g) => new AtividadeEntity(g).uuid === gameUuid,
      );
      const gameToSearch = selectedGame || games[0];
      setGame(gameToSearch);
      searchRankingGame(gameToSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pontuacaoUsuario?.uuidParticipante !== "") {
      formaterNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pontuacaoUsuario?.uuidParticipante]);

  const verificarSeUsuarioEstaNoRanking = () => {
    let encontrei = false;
    let uuidParticipante = usuario?.participantCode;
    rankings.forEach((pontuacaoUsuario) => {
      if (pontuacaoUsuario?.uuidParticipante === uuidParticipante) {
        encontrei = true;
      }
    });
    return encontrei;
  };

  const pesquisaPorParticipanteRankingGame = async (search) => {
    try {
      if (search !== "") {
        const response = await rankingGameService.buscarRanking(
          game?.uuid,
          game?.scoreType,
          0,
          search,
        );
        if (response?.data?.topRanking.length > 0) {
          setParticipantePesquisadoNoRanking(response?.data?.topRanking);
          formaterNumber();
        } else {
          setParticipantePesquisadoNoRanking([]);
        }
      } else {
        setParticipantePesquisadoNoRanking([]);
      }
    } catch (error) {
      if (error?.error?.message) {
        return setErro({
          titulo: i18n.message(
            "ranking.erro.buscar.ranking.titulo",
            "Erro ao buscar ranking",
          ),
          mensagem: error.error.message,
          isWrong: true,
        });
      }
      setErro({
        titulo: i18n.message(
          "ranking.erro.buscar.ranking.titulo",
          "Erro ao buscar ranking",
        ),
        mensagem: i18n.message(
          "ranking.erro.buscar.ranking.mensagem",
          "Entre em contato com o suporte ou tente novamente mais tarde.",
        ),
        isWrong: true,
      });
    }
  };

  const searchRankingGame = (game) => {
    rankingGameService
      .buscarRanking(game?.uuid, game?.scoreType, 0)
      .then((ranking) => {
        setRankings(ranking?.data?.topRanking);
        setRankingTop3([...ranking?.data?.topRanking.slice(0, 3)]);
        formaterNumber();
        searchRankingUsuario(game, ranking?.data?.topRanking);
      })
      .catch((_error) => {
        setErro({
          titulo: "Erro de Ranking",
          mensagem: "Erro ao obter ranking, tente novamente mais tarde",
          isWrong: true,
        });
      });
  };

  const searchRankingUsuario = (game, ranking) => {
    rankingGameService
      .getPontuacaoAtividadeUsuario(game.uuid, game.scoreType)
      .then((pontuacao) => {
        if (pontuacao.data) {
          const { data: pontuacaoData } = pontuacao;
          const pontuacaoUsuario =
            ranking.find(
              ({ uuidParticipante }) =>
                uuidParticipante === pontuacaoData.uuidParticipante,
            ) || pontuacaoData;
          setPontuacaoUsuario(pontuacaoUsuario);
        } else {
          let unitName = campanhaService
            .campanhaCorrente()
            .client.units.find((unit) => unit.uuid === usuario?.unit)?.name;
          let rating = null;
          setPontuacaoUsuario({
            name: usuario?.name,
            rating: rating,
            unit: unitName,
            uuidParticipante: usuario?.participantCode,
          });
        }
        formaterNumber();
      })
      .catch((_error) => {
        setErro({
          titulo: "Erro de Ranking",
          mensagem:
            "Erro ao obter pontuação do participante, tente novamente mais tarde",
          isWrong: true,
        });
      });
  };

  const formaterNumber = () => {
    let ranking = [...rankings];
    let rankingWithFormat = ranking.map((ranking) => {
      let position = parseInt(ranking.position);
      if (position < 10) {
        ranking.position = "0" + position;
      }
      return ranking;
    });
    setRankingWithFormat(rankingWithFormat);
  };

  const callbackModal = () => {
    return setErro({
      titulo: "",
      mensagem: "",
      isWrong: false,
    });
  };

  return (
    <>
      {erro.isWrong === true && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <CampanhaRankingsStyled>
        <div className="dinamica-content-desktop">
          <span className="header-ranking">
            <span className="size-content">
              <BotaoAsALinkIconeEsquerda
                classeComplementar="header-como-funciona-desktop-botao"
                icon="fas fa-arrow-left "
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18n.message(
                  "aria.label.geral.mensagem.voltar.trofeus",
                  "Volta para a página de troféus.",
                )}
                funcao={() => navigate("/trophy")}
              />
            </span>
          </span>
          <div className="dinamica-container">
            <div className="ranking-page">
              <div className="ranking-top">
                <h2 className="label-select">
                  {i18n.message("selecione.game", "Selecione o game")}
                </h2>
                <RankingSelect
                  searchRankingGame={searchRankingGame}
                ></RankingSelect>

                <div className="pontuacao-usuario-corrente isMobile">
                  <div className="info-position">
                    <p className="info-pontuacao">
                      {i18n.message(
                        "info.pontuacao",
                        "Sua posição: fora do top 50 ",
                      )}
                    </p>
                  </div>
                  <PontuacaoUsuarioCorrente
                    pontuacaoUsuario={pontuacaoUsuario}
                    ranking={rankings}
                  />
                </div>
                <div>
                  <LeadershipRanking topRanking={rankingTop3} />
                </div>
              </div>
              <div className="ranking-list">
                <RankingListaParticipantes
                  pontuacaoUsuario={pontuacaoUsuario}
                  rankingFormat={rankingWithFormat}
                  usuarioEstaNoRanking={
                    verificarSeUsuarioEstaNoRanking() && rankings?.length > 0
                  }
                  search={pesquisaPorParticipanteRankingGame}
                  participantePesquisadoNoRanking={
                    participantePesquisadoNoRanking
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </CampanhaRankingsStyled>
    </>
  );
}

export default GameRankings;
