import styled from "styled-components";

export const MissaoCumpridaFeedbackModalStyle = styled.section`
  .missao-cumprida-container-confetes svg {
    width: 88% !important;
    position: absolute;
    top: 0px;
  }

  .titulo-container {
    h1 {
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      line-height: 15px;
    }
    h2 {
      line-height: 16px;
      font-size: 13px;
    }
    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 14px;
      text-transform: none;
    }
  }

  /*media query min-width 600px*/
  @media screen and (min-width: 600px) {
    .missao-cumprida-container-confetes svg {
      width: 75% !important;
      margin-left: calc(0vw - 208px);
      margin-right: calc(0vw - 208px);
      left: 17rem;
    }
  }

  .missao-cumprida__title {
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .missao-cumprida__subtitle {
    color: ${(props) => {
      return props.theme.cardLogadoTextoSegundo;
    }};
  }

  .missao-cumprida__subtitle__nameActivity {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  .pontuacao-container {
    margin-top: 3vh;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background: rgba(0, 226, 145, 0.1);
    border-radius: 8px;
  }

  .pontuacao {
    color: #00e291;
    text-align: center;
    line-height: 17px;
    font-weight: 700;
  }

  .pontuacao__destaque {
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media screen and (min-width: 700px) {
    .missao-cumprida__title {
      margin-top: 1em;
      margin-bottom: 2em;
    }
    .pontuacao-container {
      width: 80%;
      margin: auto;
    }
    .titulo-container {
      margin-bottom: 1em;
      h3 {
        margin-top: 0;
      }
    }
  }
`;
