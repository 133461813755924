import styled from "styled-components";
export const GameRankingsListStyled = styled.div`
  .participante {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .participante&:hover {
    border: 1px solid
      ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
    border-radius: 10px;
  }

  .participante-content,
  .participante {
    border: 1px solid transparent;
  }

  .input-search {
    margin-bottom: 1em;
  }

  .usuario-corrente {
    margin-right: 10px;
  }

  .participante-icon {
    margin-left: 2em;
    display: flex;
    align-items: center;
  }

  .participante-info {
    padding: 0.5em;
  }

  .participante-unidade {
    display: flex;
    font-size: 0.8em;
    min-height: 0.8em;
  }

  .participante-info-name {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .participante-info-name-usuario-corrente {
    font-weight: 700;
    font-size: 1.125rem;
  }
  .participante-info-participante-corrente-pontos {
    color: #67ce67;
  }
  .participante-info-posicao {
    width: 1rem;
    text-align: center;
    min-width: 1rem;
  }
  .participante-info-pts {
    font-size: 0.9em;
    color: #67ce67;
    font-weight: 700;
    width: 100%;
  }
  .participante-info-pts > span {
    width: 100%;
  }

  .participante-info-pts-msg {
    font-size: 0.9em;
    color: #67ce67;
    font-weight: 700;
  }
  .participante-info-pts-msg2 {
    font-size: 0.9em;
    font-weight: 500;
  }
  .content-one {
    display: flex;
    gap: 0.5em;
  }
  .participante-position {
    white-space: nowrap;
  }
  .participante-info-posicao {
    margin-right: 2em;
  }

  .participante-info-unidade {
    margin-left: 0;
  }
  .participante-info-unidade-isparticipante {
    margin-left: 0;
  }

  .isDesktop {
    display: none;
  }
  .info-pontuacao {
    margin-left: 0.6rem;
    font-size: 0.9em;
    font-weight: bold;
  }

  .hr-ranking-game {
    background-color: #f0f0f0;
    box-shadow: inset 0 0 0 0.5px #c9c9c9;
    margin: 0;
    margin-left: 0.5em;
    margin-right: 1em;
  }
  .participante-container {
    width: 100%;
  }

  .ranking-title {
    color: ${(props) => {
      return props.theme.fundoSecundario || `#533888`;
    }};
    margin-bottom: 1em;
    text-align: center;
  }

  .msg-sem-resultado {
    text-align: center;
  }

  @media screen and (min-width: 700px) {
    .content-one {
      gap: 1em;
    }
    .participante-position-usuario-corrente {
      display: inline-block;
      white-space: nowrap;
    }
    .scroll {
      padding-bottom: 8rem;
      overflow: auto;
      max-height: 65vh;
      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb {
        background: ${(props) => {
          return props.theme.fundoSecundario;
        }};
        border-radius: 10px;
      }

      ::-webkit-scrollbar-thumb&:hover {
        background: ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
      }
    }
    .isDesktop {
      display: block;
    }
    .isMobile {
      display: none;
    }
    .rankingList {
      margin-top: 1em;
    }
    .dinamica-title-desktop-margin {
      margin-top: 0.8em;
    }

    .margin-adjust {
      text-align: left;
      margin-left: 2em;
    }
    .hr-ranking-game {
      margin-left: 0.5em;
    }
    .participante-info-posicao {
      margin-right: 3em;
    }
    .ranking-title {
      text-align: start;
    }
  }
`;
