import styled from "styled-components";

export const AcerteTempoStyled = styled.div`
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  .weex-l-acerteTempo-marginAuto {
    margin: auto;
  }

  .weex-l-acerteTempo-container {
    display: flex;
    flex-direction: column;
  }

  .weex-l-acerteTempo-botaoRespiroSuperior {
    margin-top: 1.5rem;
  }

  .weex-l-acerteTempo-imagemRespiroSuperior {
    margin-top: 1.5rem;
  }

  .weex-l-acerteTempo-apresentacaoMargin {
    margin-top: 1.5rem;
  }

  .weex-l-acerteTempo-imagens {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .weex-l-acerteTempo-revisao-btnContainer {
    display: flex;
    gap: 2rem;
  }

  .weex-l-acerteTempo-revisao-btnTexto {
    display: flex;
    justify-content: space-evenly;
  }

  // setando tamanho dos textos que vem do backend
  .weex-l-acerteTempo-textos-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    p,
    ul {
      font-size: 0.875rem;
    }
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-acerteTempo-imagemRespiroSuperior {
      margin-top: 0;
    }

    .weex-l-acerteTempo-apresentacaoMargin {
      margin-top: 0px;
    }

    .weex-l-acerteTempo-container-row {
      margin-left: 5rem;
      margin-right: 5rem;
      flex-direction: row;
    }

    .weex-l-acerteTempo-container-rowReversed {
      margin-left: 5rem;
      margin-right: 5rem;
      flex-direction: row-reverse;
    }

    .weex-l-acerteTempo-container--flexFilhos {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      flex: 1;
    }

    .weex-l-acerteTempo-posicionamento-imagens {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .weex-l-acerteTempo-botaoRespiroSuperiorMenor {
      margin-top: 3rem;
    }

    .weex-l-acerteTempo-botaoRespiroSuperior {
      margin-top: 5rem;
    }

    .weex-l-acerteTempo-botoesContainer {
      margin-left: 20%;
      margin-right: 20%;
    }

    // setando tamanho dos textos que vem do backend
    .weex-l-acerteTempo-textos-container {
      padding: 0;
      p,
      ul {
        font-size: 1.2rem;
      }
    }
  }
  /* #endregion DESKTOP */
`;
