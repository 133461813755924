import React from "react";
import CardAnimado from "./CardAnimado";
import CardEstatico from "./CardEstatico";

const ContainerFlip = ({ digit, shuffle, unit, home }) => {
  // assign digit values
  let currentDigit = digit;
  let previousDigit = digit + 1;

  // to prevent a negative value
  if (unit === "horas") {
    previousDigit = previousDigit === 24 ? 0 : previousDigit;
  } else if (unit !== "dias") {
    previousDigit = previousDigit === 60 ? 0 : previousDigit;
  }

  // add zero
  if (currentDigit < 10) {
    currentDigit = `0${currentDigit}`;
  }
  if (previousDigit < 10) {
    previousDigit = `0${previousDigit}`;
  }

  // shuffle digits
  const digit1 = shuffle ? previousDigit : currentDigit;
  const digit2 = !shuffle ? previousDigit : currentDigit;

  // shuffle animations
  const animation1 = shuffle ? "fold" : "unfold";
  const animation2 = !shuffle ? "fold" : "unfold";

  return (
    <div className={"flipUnitContainer" + (home ? " flipUnitHome" : "")}>
      <CardEstatico
        position={"upperCard"}
        digit={currentDigit}
        hidden={false}
      />
      <CardEstatico
        position={"lowerCard"}
        digit={previousDigit}
        hidden={true}
      />
      <CardAnimado digit={digit1} animation={animation1} />
      <CardAnimado digit={digit2} animation={animation2} />
      {/* <div className='leganda-footer'> */}
      <span
        className={
          "contador-campanha-dias_horas_minutos foreground " +
          (home ? "contador-campanha-dias_horas_minutos-home" : "")
        }
      >
        {unit}
      </span>
      {/* </div> */}
    </div>
  );
};

export default ContainerFlip;
