import "bulma/css/bulma.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Weex } from "./styles/AppStyle";
import Footer from "./comps/footer/Footer";
import LogosCampanha from "./comps/LogosCampanha/LogosCampanha";
import Header from "./comps/header/Header";
import MissaoCumpridaModal from "./comps/weexModais/MissaoCumpridaModal";
import AcceptLegalTerm from "./pages/AcceptLegalTerm/AcceptLegalTerm";
import AtivarParticipante from "./pages/authInscricao/AtivarParticipante";
import Atividades from "./pages/Atividades/Atividades";
import CallbackGame from "./pages/game/CallbackGame";
import ChangePassword from "./pages/authInscricao/Password/ChangePassword";
import Clean from "./pages/cleanLocalStorageCache/Clean";
import ComoFunciona from "./pages/ComoFunciona/ComoFunciona";
import Extra from "./pages/extra/Extra";
import FatoFake from "./pages/fatoFake/FatoFake";
import Feedback from "./pages/Feedback/Feedback";
import GameRankings from "./pages/GameRankings/GameRankings";
import Home from "./pages/Home/Home";
import Interesses from "./pages/interesses/Interesses";
import LoginRegister from "./pages/authInscricao/login/LoginRegister";
import NovosHabitos from "./pages/NovosHabitos/NovosHabitos";
import RegisterSelector from "./pages/authInscricao/inscricao/RegisterSelector";
import ResetPassword from "./pages/authInscricao/Password/ResetPassword";
import SeletorCampanha from "./pages/SelecaoCampanha/SeletorCampanha";
import Trofeus from "./pages/Trofeus/Trofeus";
import Version from "./pages/version/Version";
import CampanhaRankings from "./pages/campanhaRanking/CampanhaRankings";
import Videos from "./pages/Videos/Videos";
import Game from "./pages/game/Game";
import Mural from "./pages/mural/Mural";
import SelecaoDeCampanhaAutomatica from "./pages/SelecaoCampanha/SelecaoDeCampanhaAutomatica";
import Presencial from "./pages/presencial/Presencial";
import AvaliacaoDinamicaPresencial from "./pages/presencial/AvaliacaoDinamicaPresencial";
import LeitorQrCodeDinamicaPresencial from "./pages/presencial/LeitorQrCodeDinamicaPresencial";
import MensagemVideo from "./pages/Videos/MensagemVideo";
import AcerteTempoContainer from "./pages/acerteTempo/AcerteTempoContainer";
import Convite from "./pages/convite/Convite";

function App() {
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const dispatch = useDispatch();
  const backgroundispresent = useSelector((state) => state.backgroundispresent);

  const containerRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const currentContainerRef = containerRef.current;

    const checkSize = (entries) => {
      if (currentContainerRef) {
        const width = currentContainerRef.offsetWidth;
        const newIsDesktop = width > 700;
        if (newIsDesktop !== isDesktop) {
          setIsDesktop(newIsDesktop);
          dispatch({ type: "isDesktopMode", payload: newIsDesktop });
        }
      }
    };

    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => checkSize(entries));
    });

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef);
      checkSize(); // Check size initially
    }

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef);
      }
    };
  }, [dispatch, isDesktop]);

  return (
    <Router>
      <Weex
        usuariologado={usuariologado}
        usuario={usuario.toString()}
        backgroundispresent={backgroundispresent}
        className="weex"
      >
        <Header />

        <LogosCampanha />
        <main
          ref={containerRef}
          className={
            usuariologado && usuario === true
              ? `weex-container weex-content weex-main-content-border
              weex-container-logado-grow`
              : `weex-container weex-container-deslogado-grow`
          }
        >
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/help" element={<ComoFunciona />} />
            <Route path="/clean" element={<Clean />} />
            <Route path="/select" element={<SeletorCampanha />} />
            <Route path="/version" element={<Version />} />
            <Route path="/accept" element={<AcceptLegalTerm />} />
            <Route
              path="/campaign/:code?"
              element={<SelecaoDeCampanhaAutomatica />}
            />
            <Route path="/invite/:codigo" element={<Convite />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/passwordReset" element={<ChangePassword />} />
            <Route
              path="/activateSubscription/:code"
              element={<AtivarParticipante />}
            />
            <Route path="/interests" element={<Interesses />} />
            <Route path="/atividades" element={<Atividades />} />
            <Route
              path="/fatoFake/:codigo/:codigoAtividade"
              element={<FatoFake />}
            />
            <Route
              path="/video/:codigo/:codigoAtividade"
              element={<Videos />}
            />
            <Route path="/mural/:codigo/:codigoAtividade" element={<Mural />} />
            <Route
              path="/video-message/:codMensagemDeVideo"
              element={<MensagemVideo />}
            />
            <Route path="/game/:codigo/:codigoAtividade" element={<Game />} />
            <Route path="/extra/:codigo/:codigoAtividade" element={<Extra />} />
            <Route
              path="/presencial/:codigo/:codigoAtividade"
              element={<Presencial />}
            />
            <Route
              path="/presencialQrcode/:codigo/:codigoAtividade"
              element={<LeitorQrCodeDinamicaPresencial />}
            />
            <Route
              path="/presencialAvaliar/:codigo/:codigoAtividade"
              element={<AvaliacaoDinamicaPresencial />}
            />
            <Route
              path="/newHabit/:codigo/:codigoAtividade"
              element={<NovosHabitos />}
            />
            <Route
              path="/acerteTempo/:codigo/:codigoAtividade"
              element={<AcerteTempoContainer />}
            />
            <Route path="/trophy" element={<Trofeus />} />
            <Route
              path="/missionFinished/:codigoAgenda/:atividade/:codigoAtividade"
              element={<MissaoCumpridaModal />}
            />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/callbackGame/:codigo" element={<CallbackGame />} />
            <Route path="/registerSelector" element={<RegisterSelector />} />
            <Route path="/gameRankings" element={<GameRankings />} />
            <Route path="/campanhaRanking" element={<CampanhaRankings />} />
            <Route path="/" element={<LoginRegister />} />
          </Routes>
        </main>
        <Footer />
      </Weex>
    </Router>
  );
}

export default App;
