import React from "react";
import Vimeo from "@u-wave/react-vimeo";

export const VimeoVideo = ({ video, onReady, onTimeUpdate, sizeScreen }) => {
  const proporcao = sizeScreen > 700 ? 0.5 : 0.8;

  const onError = (e) => {
    console.error("Error: ", e);
  };

  return (
    <div aria-label={video.title}>
      <Vimeo
        video={video.url}
        controls={true}
        onReady={onReady}
        width={sizeScreen * proporcao}
        height={((sizeScreen * proporcao) / 16) * 9}
        onTimeUpdate={onTimeUpdate}
        className="animations-popIn"
        onError={onError}
        title={video.title}
      />
    </div>
  );
};
