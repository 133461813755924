import React from "react";

/**
 * @param {Object} props
 * @param {number} props.indiceAtual
 * @param {number} props.totalDeItems
 */
export default function MarcadorProgresso({ indiceAtual, totalDeItems }) {
  const bolinhas = [];

  for (let indiceQuestao = 0; indiceQuestao < totalDeItems; indiceQuestao++) {
    bolinhas.push(
      <MarcadorBolinha
        isColorida={indiceQuestao <= indiceAtual}
        key={indiceQuestao}
      />,
    );
  }

  return <div className="marcador-progresso">{bolinhas}</div>;
}

/**
 * @param {Object} props
 * @param {boolean} props.isColorida
 */
function MarcadorBolinha({ isColorida }) {
  return (
    <div
      className={`
        marcador
        ${isColorida && "marcador-concluido"}
      `}
      style={{ width: "1vw", height: "1vw" }}
    />
  );
}
