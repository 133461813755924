import React, { useState } from "react";
import WeexInput from "../../WeexInputs/WeexInput";
import { useDispatch, useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import BotaoSecundario from "../../botaoSecundario/BotaoSecundario";
import CampanhaService from "../../../services/CampanhaService";
import UsuarioService from "../../../services/UsuarioService";
import Auth2FATokenService from "../../../services/2FAToken";
import WeexModal from "../../weexModais/WeexModal";
import { LoginCampaingStyle } from "../../../pages/authInscricao/style";
import CustomWeexInput from "../input/CustomWeexInput";
import TemaService from "../../../services/TemaService";

function Login2FA() {
  const usuarioService = new UsuarioService();
  const campanhaService = new CampanhaService();
  const eventoCorrente = campanhaService.campanhaCorrente();
  const tipoInscricao = eventoCorrente.tipoInscricao;
  const elegibilidade = eventoCorrente.restrictEligibility;
  const nomeMatricula = eventoCorrente.nomeMatricula;
  const obrigatoriedadeDoTermoCampanha = eventoCorrente.legalTerm;

  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showInputsCodigoValidacao, setShowInputsCodigoValidacao] =
    useState(false);
  const [username, setUsername] = useState(""); // poder ser e-mail ou matrícula
  const [codigo, setCodigo] = useState("");
  const [validateCodigo, setValidateCodigo] = useState(false);

  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [update, setUpdate] = useState(0);
  const [auth2FABloqueado, setAuth2FABloqueado] = useState(false);
  const auth2FATokenService = new Auth2FATokenService();

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleCodigo = (e) => {
    setCodigo(e.target.value);
    setValidateCodigo(false);
  };

  const submit = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }
    setProcessando(true);

    if (codigo === "" && showInputsCodigoValidacao) {
      setValidateCodigo(true);
      setUpdate((update) => update + 1);
      return;
    }

    let credenciais = {
      username: username,
      campaign: eventoCorrente.code,
      password: null,
      extraInfo: [{ code: "EMAIL_2FA", value: codigo }],
    };
    usuarioService.auth(credenciais, (erro, sucesso) => {
      if (erro) {
        verificaErrosSubmit(erro);
        return;
      }
      if (sucesso) {
        removerTema();
        setProcessando(false);
        if (sucesso?.code === "202") {
          setShowInputsCodigoValidacao(true);
          return;
        }
        if (obrigatoriedadeDoTermoCampanha && !sucesso.legalTerm) {
          navigate("/accept");
          return;
        }
        contadorCampanha();
        dispatch({ type: "usuario", payload: true });
        dispatch({ type: "usuariologado", payload: sucesso });
      }
    });
  };

  const removerTema = () => {
    const temaService = new TemaService();
    temaService.removerTema();
  };

  const contadorCampanha = () => {
    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message(
            "atividades.erro.dias.habilitados",
            "Erro obter lista de dias habilitados",
          ),
        });
        return;
      }
      if (sucesso) {
        if (sucesso !== null) {
          if (sucesso.length > 0) {
            dispatch({ type: "campanhaComecou", payload: true });
            navigate("/atividades");
          } else {
            dispatch({ type: "campanhaComecou", payload: false });
            navigate("/home");
          }
        }
      }
    });
  };

  const verificaErrosSubmit = (erro) => {
    if (erro?.response?.data?.message) {
      const message = erro.response.data.message;
      if (message === "Código inválido") {
        setErro({
          titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
          mensagem: i18n.message("login.erro.denied.code", "Código inválido"),
        });
        setProcessando(false);
        return;
      }
      setErro({
        titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
        mensagem: i18n.message(
          "login.erro.denied.mensagem",
          "Participante, informe todas as credenciais, elas são obrigatórias.",
        ),
      });
      setProcessando(false);
      return;
    }

    setErro({
      titulo: i18n.message("login.erro.denied.titulo", "Acesso negado"),
      mensagem: i18n.message(
        "login.erro.denied.mensagem",
        "Verifique sua matrícula ou e-mail e tente novamente.",
      ),
    });
    setProcessando(false);
  };

  const request2FAToken = (event) => {
    event.preventDefault();
    if (processando) {
      return;
    }

    if (auth2FABloqueado) {
      return;
    }

    setAuth2FABloqueado(true);
    setProcessando(true);

    if (!username) {
      setProcessando(false);
      setUpdate((update) => update + 1);
      return;
    }

    const request = {
      principal: username,
      campaign: eventoCorrente?.code,
      type: "EMAIL_2FA",
    };

    auth2FATokenService.fAToken(request, (erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message(
            "login.erro.2fa.token.titulo",
            "Error ao gerar código",
          ),
          mensagem: i18n.message(
            "login.erro.2fa.token.mensagem",
            "Não foi possível gerar o código, tente novamente mais tarde",
          ),
        });
        setProcessando(false);
        return;
      }
      //criar time de 5 segundos
      const timer = setTimeout(() => {
        setAuth2FABloqueado(false);
        setProcessando(false);
        clearTimeout(timer);
      }, 5000);
    });
  };

  return (
    <LoginCampaingStyle>
      {erro != null ? (
        <WeexModal
          fecharModalCallback={() => setErro(null)}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      <div>
        <form style={{ width: "100%" }} onSubmit={submit}>
          <div className="login">
            <CustomWeexInput
              tipoInscricao={tipoInscricao}
              elegibilidade={elegibilidade}
              nomeMatricula={nomeMatricula}
              onChange={onChangeUsername}
            />
            {showInputsCodigoValidacao && (
              <p className="weex-l-color has-text-centered">
                {i18n.message(
                  "2fa.mesagem.codigo.enviado",
                  "Um código foi enviado ao seu email, favor digitá-lo no campo abaixo",
                )}
              </p>
            )}
            {showInputsCodigoValidacao && (
              <>
                <div className="field-cadastro sem-label mt-4 is-flex is-flex-direction-column is-justify-content-flex-end">
                  <WeexInput
                    id="enrollment"
                    type="text"
                    name="enrollment"
                    placeholder={i18n.message(
                      "2fa.campo.codigo.placeholder",
                      "Código",
                    )}
                    onChange={handleCodigo}
                    required={true}
                    ariaLabel={i18n.message(
                      "aria.label.campo.codigo2fa",
                      "Insira ó código",
                    )}
                  />
                  <div className="mb-6">
                    <button
                      type="button"
                      onClick={request2FAToken}
                      className="button-link weex-l-button-reenviar has-text-centered weex-l-color"
                      disabled={auth2FABloqueado}
                    >
                      {i18n.message("2fa.renviar", "Reenviar código")}
                    </button>
                    {auth2FABloqueado && (
                      <p className=" weex-l-color has-text-centered">
                        {i18n.message(
                          "2fa.mensagem.reenvio.aviso",
                          "Foi reenviado o código para o seu e-mail. Caso não o encontre na caixa de entrada, verifique no spam.",
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {validateCodigo && (
                  <p className="has-text-danger has-text-centered mt-2">
                    {i18n.message(
                      "2fa.campo.codigo.validacao",
                      "Por favor, preechar o código",
                    )}
                  </p>
                )}
              </>
            )}
            <div key={update} className="espacamento-botao-login">
              <BotaoSecundario
                disabled={processando}
                isForm={true}
                nome={
                  processando
                    ? i18n.message("geral.aguarde", "AGUARDE...")
                    : i18n.message("login.entrar", "Entrar")
                }
              />
            </div>
          </div>
        </form>
      </div>
    </LoginCampaingStyle>
  );
}

export default Login2FA;
