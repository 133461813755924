import styled from "styled-components";

export const ButtonSecundarioStyle = styled.button`
  text-transform: uppercase;
  width: 100%;
  padding: 1.6em;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  background-color: ${(props) => {
    return props.theme.fundoTres;
  }};
  color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  cursor: pointer;

  &&:hover {
    filter: brightness(1.1);
  }

  &&:active {
    filter: brightness(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    filter: contrast(0.9);
  }

  .disabled {
    cursor: not-allowed;
    filter: contrast(0.9);
  }

  @media screen and (max-width: 600px) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;
