import React, { useState } from "react";
import { useStore } from "react-redux";

export default function ImagemQuestao({ questao }) {
  const i18n = useStore().getState().i18n;
  const [loaded, setLoaded] = useState(false);

  // Função chamada quando a imagem termina de carregar
  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <figure className="weex-l-dinamica-FatoFake-card__imagem-container">
      <img
        className={`${loaded ? "animations-HideUntilLoaded" : ""}`}
        src={questao.picture}
        alt={i18n.message(
          "alt.dinamica.imagem.fatofake",
          "Imagem ilustrativa das questões de fato ou fake",
        )}
        onLoad={handleImageLoaded}
        // A cada vez que a 'questao.picture' muda, o componente img é recriado, garantindo que o estado 'loaded' seja resetado.
        key={questao.picture}
      />
    </figure>
  );
}
