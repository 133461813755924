import styled from "styled-components";

function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export const Weex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${(props) => {
    if (
      (props.usuariologado !== null && props.usuario === "true") ||
      (props.usuariologado !== undefined && props.usuario === "true")
    ) {
      return props.theme.fundoSecundario;
    } else {
      return props.theme.fundoPrimario || `#F3F5F9`;
    }
  }};

  .weex-container {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: ${(props) => {
      if (
        (props.usuariologado !== null && props.usuario === "true") ||
        (props.usuariologado !== undefined && props.usuario === "true")
      ) {
        return `0em`;
      } else {
        return `0px`;
      }
    }};
  }

  /*
  Acessibilidade: esconde visualmente,
  mas ainda mantém disponível para leitores de tela
*/
  .weex-g-visually-hidden {
    clip: rect(0.063rem 0.063rem 0.063rem 0.063rem);
    padding: 0 !important;
    border: 0 !important;
    height: 0.063rem !important;
    width: 0.063rem !important;
    overflow: hidden;
    display: block;
  }

  .weex-content {
    background: ${(props) => {
      return props.theme.cardLogadoFundo;
    }};
  }

  .close-btn {
    border: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;

    :active {
      filter: brightness(0.5);
    }
  }

  .img-close {
    position: absolute;
    right: 1em;
  }

  .div-close {
    align-items: center;
    display: flex;
    padding: 2em;
    position: relative;
  }

  .div-version {
    margin-top: 2em;
    text-align: center;
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  .link-sublinhado {
    text-decoration: underline;
  }

  .version-label {
    font-weight: 800;
  }

  .weex-container-logado-grow {
    flex-grow: 1;
    margin-top: 2em;
  }

  .weex-main-content-border {
    border-radius: 30px 30px 0px 0px;
  }

  .weex-cabecalho {
    padding-bottom: 1.5em;

    .campanha_logosTopo_name,
    .campanha_logosTopo_subtitle {
      color: ${(props) => {
        return props.theme.textosCorFundoSecundario;
      }};
    }
  }

  .weex-header-menu {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
  }

  .botao-flutuante {
    position: "sticky";
    bottom: 0;
    width: "100%";
  }

  .textos-cor-secundaria {
    background: ${(props) => {
      if (
        ((props.usuariologado !== null && props.usuario === "true") ||
          (props.usuariologado !== undefined && props.usuario === "true")) &&
        props.theme.fundoSecundario
      ) {
        var rgb = props.theme.fundoSecundario;
        return hexToRGB(rgb, 0.05);
      } else {
        return props.theme.fundoPrimario || `#F3F5F9`;
      }
    }};
  }

  .weex-header-menu-content {
    margin-top: 6em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;

    ul {
      text-align: center;

      li {
        margin-bottom: 20px;

        a,
        .button-link {
          text-transform: uppercase;
          font-size: 16px;
          line-height: 14px;
          font-weight: 700;
          color: ${(props) => {
            return props.theme.textosCorFundoSecundario;
          }};

          :active {
            filter: opacity(0.5);
          }
        }

        .button-link:last-child {
          text-transform: capitalize;
        }

        p {
          color: ${(props) => {
            return props.theme.textosCorFundoSecundario;
          }};
          font-weight: bold;
        }
      }
    }
  }

  .button-link {
    background: none;
    border: none;
    cursor: pointer;
  }

  .weex_select_campaign_container {
    border-radius: 8px;
    border: none;
    margin-top: 2em;
    padding: 1em;
    margin-bottom: 1em;
  }

  .menu {
    max-width: 20px;
    position: relative;
    z-index: 102;
  }

  .sala-de-trofeus {
    max-width: 1.875rem;
    min-width: 1.875rem;

    :active {
      filter: opacity(0.5);
    }
  }

  .sala-de-trofeus__disabled {
    filter: opacity(0.5);
    cursor: not-allowed;
  }

  a,
  .weex-link,
  .button-link {
    color: #201d1d;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  label {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    color: #424a57;
  }

  .input::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    color: #847d92;
  }

  .input-size {
    padding-top: 1.8em;
    padding-bottom: 1.8em;
    padding-left: 1em;
  }

  .input-strong-password {
    margin-bottom: 1.5rem;
    position: relative;
  }

  .input-container-strong-password {
    display: flex;
    align-items: center;
  }

  .input-container-strong-password > input {
    margin-bottom: -5px;
  }

  .password-strength-bar {
    font-size: 10px;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
    width: 100%;
    height: 5px;
    background-color: #e0e0de;
    position: absolute;
    top: 100%;
    border-radius: 0px 0px 3px 3px;
  }

  .password-strength-bar > p > strong {
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
  }

  .strength-bar-fill {
    width: 100%;
    height: 100%;
    color: #000;
    border-radius: 0px 0px 3px 3px;
  }

  .password-toggle-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: none;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .password-toggle-button:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }

  .select-input,
  select {
    height: 4em;
  }

  .input,
  select,
  textarea {
    border-left: 5px solid
      ${(props) => {
        if (props?.backgroundispresent) {
          return props.theme.fundoTres;
        } else {
          return props.theme.fundoSecundario || `#533888`;
        }
      }};
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    background: #ffffff;
  }

  .input:focus,
  select:focus,
  textarea:focus {
    border-color: ${(props) => {
      if (props?.backgroundispresent) {
        return props.theme.fundoTres;
      } else {
        return props.theme.fundoSecundario || `#533888`;
      }
    }};
  }

  .input:hover,
  select:hover,
  textarea:hover {
    border-left: 5px solid
      ${(props) => {
        if (props.backgroundispresent) {
          return props.theme.fundoTres;
        } else {
          return props.theme.fundoSecundario || `#533888`;
        }
      }};
  }

  .interesse-content {
    h3 {
      color: ${(props) => props.theme.cardLogadoTextoPrimeiro};
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    h4 {
      font-weight: bold;
      color: ${(props) => props.theme.cardLogadoTextoSegundo};
      font-size: 1.2rem;
    }
  }

  .retirar-padding {
    padding-top: 0;
  }

  .versao {
    font-size: 0.7rem;
    text-transform: lowercase;
  }

  /**
      Cabeçalho e subcabeçalho
  */

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
  }

  h2 {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  /**
      Titulo e subtitulos dentro dos cards
  */

  h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h4 {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .botao-invisivel + .botao-invisivel {
    border-left: solid 2px #ffffff;
  }

  .botao-invisivel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .botao-invisivel {
    background: transparent;
    border: none;
    padding-right: 0.5em;
    margin: 0px;
  }

  .card-atividades {
    display: flex;
    justify-content: center;
    border-radius: 8px;
  }

  .icone_atividades {
    width: 34px;
    height: 35px;
  }

  .trofeu__sala img {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    max-height: 70px;
  }

  .card-atividades__imagem,
  card-atividades-trofeu__imagem {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .label-atividades {
    font-family: Poppins;
    font-style: normal;
    font-weight: lighter;
    font-size: 10px;
    line-height: 14px;
    color: black;
  }

  .text-area-feedback-video {
    max-height: 3rem;
    margin-top: 1rem;
  }

  .text-area-feedback-video::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 12px;
    color: #847d92;
  }

  .accep-legal-term {
    h3 {
      color: ${(props) => {
        return props.theme.cardLogadoTextoPrimeiro;
      }};
    }

    h4 {
      color: ${(props) => {
        return props.theme.cardLogadoTextoSegundo;
      }};
    }
  }

  .home-btn-padding-como-funciona {
    margin-bottom: 1rem;
  }

  .home-btn-padding-interesses {
    margin-bottom: 3.5rem;
  }

  .campanha_logosTopo__btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .botao-video-desabilitado {
    background-color: #dfdde3;
    color: #ffffff;
    cursor: not-allowed;
  }

  .botao-desabilitado {
    background-color: #dfdde3;
    color: #ffffff;
    cursor: not-allowed;
  }

  /**
      DINAMICAS WEEX 2.0
  */

  .dinamica-container {
    overflow: hidden;
  }

  .weex-card-container-imagem {
    display: flex;
    justify-content: center;
    margin-top: 3vh;
  }

  .card,
  .card-content {
    background: transparent;
    box-shadow: none;
  }

  .weex-card-interno__content {
    min-height: 30vh;
    margin-bottom: 3vh;
  }

  .weex-card-interno__content__fato-fake__novos-habitos {
    min-height: 5vh;
    padding-bottom: 3vh;

    .markup__fato-fake {
      p {
        text-align: center;
        color: ${(props) => {
          return props.theme.cardLogadoTextoPrimeiro;
        }};
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .video-quiz-feedback-markup {
    p {
      text-align: center;
      color: ${(props) => {
        return props.theme.cardLogadoTextoPrimeiro;
      }};
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .quiz-markup-answers {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: center;

      li {
        label {
          color: ${(props) => {
            return props.theme.cardLogadoTextoSegundo;
          }};
          font-weight: 400;
          font-size: 14px;
          line-height: 15px;
        }
      }
    }
  }

  .quiz-question {
    margin-bottom: 20px;
  }

  .marcador-progresso {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .marcador {
    border-radius: 100%;
    background: #dfdde3;
    float: left;
  }

  .marcador-concluido {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  .weex-button__ff {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 45%;
    font-weight: 700;
    letter-spacing: 0.2rem;
    color: #777777;
  }

  .weex-card-ff-certo {
    background: rgba(0, 226, 145, 0.1);
    border: 2px solid #00e291;
    border-radius: 14px;
    position: relative;

    .tag-acertou-container {
      position: relative;
      margin-top: -15px;

      span {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 8px;

        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        background: #00e291;
        box-shadow: 0px 20px 40px -10px rgba(119, 119, 119, 0.23);
      }
    }

    .tag-resposta {
      padding: 30px;

      p {
        text-align: center;
        color: ${(props) => {
          return props.theme.cardLogadoTextoPrimeiro;
        }};
      }
    }
  }

  .weex-card-ff-errado {
    background: #fff7f7;
    border: 2px solid #fe001a;
    border-radius: 14px;
    position: relative;

    .tag-errou-container {
      position: relative;
      margin-top: -15px;

      span {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 8px;

        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        background: #fe001a;
        box-shadow: 0px 20px 40px -10px rgba(119, 119, 119, 0.23);
      }
    }

    .tag-resposta {
      padding: 30px;

      div {
        p {
          text-align: center;
          color: ${(props) => {
            return props.theme.cardLogadoTextoPrimeiro;
          }};
        }
      }
    }
  }

  .weex-card-header {
    text-align: center;
    display: block;
    font-size: 2rem;
    font-weight: 600;
    padding: 0.75rem 1rem 0.75rem 1rem;
  }

  .weex-como-funciona {
    margin-top: 40px;
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};

    button {
      margin-top: 50vh;
    }

    ul {
      list-style: unset;
      padding-left: 40px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .redireciona-campanha-field {
    margin-top: 0.75rem;
  }

  .select-margin-top-control {
    margin-top: 0.5em;
  }

  .redireciona-campanha-container-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .legalTerm-field {
    margin-top: 0.75rem;
  }

  .legalTerm-Title {
    font-size: 1.2rem;
    text-align: center;
  }

  .legalTerm-msg {
    text-align: center;
  }

  .legalTerm-btn {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .card-atividades__img {
    max-height: 100%;
  }

  .card-atividades__disabled {
    background: transparent;
  }

  .card-atividades__primeiro {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
  }

  .card-atividades__ultimo {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .weex-botao-confirmacao__texto-comprido__missao-cumprida {
    z-index: 100;
    position: relative;
  }

  .videoPlayerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0.45rem 1rem 0.25rem rgba(255, 255, 255, 0.5);
  }

  .trofeu {
    height: 20vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }

  .weex-select {
    width: 100%;
  }

  .weex-select select {
    width: 100%;
  }

  .legenda-input {
    font-size: 0.75rem;
  }

  .inputSearch {
    margin-right: 0.5em;
  }

  .search {
    height: 3.7em;
  }

  .button-fa-times {
    cursor: pointer;
    pointer-events: all !important;
    margin-top: 0.5em;
  }

  .button-fa-times > svg {
    width: 3em;
    height: 1.5em;
  }

  .aguarde {
    width: 80%;
    margin: 0px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    padding-top: calc(50vh - (1rem + 1em + 2rem));
    position: absolute;
    z-index: 50;
    left: 10%;
  }

  .como-funcionar-titulo {
    display: none;
  }

  @media screen and (max-width: 600px) {
    .card-atividades {
      padding: 10px;
    }
  }

  .input,
  .input:focus,
  .input:active {
    border-left: 5px solid
      ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
  }

  .card-atividades-trofeu__imagem {
    background-size: cover;
  }

  .games-apresentacao {
    margin: 4% 4%;
  }

  .games-apresentacao > div {
    margin-top: 1rem;
  }

  .modal-button-missaocomprida {
    position: relative;
    z-index: 100;
  }

  .campanha_logosTopo {
    margin: 0 1rem;
  }

  .espacamento-botao-login {
    margin-top: 1em;
  }

  .weex_footer {
    margin: 0 1rem;
  }

  /* Animações */
  @keyframes popIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes HideUntilLoaded {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* Aplique a animação onde necessário */
  .animations-popIn {
    animation: popIn 0.3s ease forwards;
  }
  .animations-HideUntilLoaded {
    animation: HideUntilLoaded 1s ease forwards;
  }
  .botao-inscrevase {
    padding: 1rem;
    font-size: 0.8rem;
  }
  .flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .justify-center {
    justify-content: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-g-theme-corpo-texto {
      margin: 1rem 2rem 2.5rem 2rem;

      p {
        font-weight: 25rem; //400px
      }
    }

    .weex-l-desktop-mode {
      display: block;
    }

    .weex-l-mobile-mode {
      display: none;
    }

    .weex-g-container {
      margin-left: 2em;
      margin-right: 2em;
    }

    .weex-g-container--flex {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .weex-g-container-logado-grow {
      margin-top: 0em;
    }

    .weex-g-main-content-border {
      border-radius: 0.5rem 0.5rem 0px 0px;
    }

    .weex-g-area-de-interacao-desktop-container-margins {
      margin: 1vw 5vw;
    }
    .w-100 {
      width: 100%;
    }
  }
  /* #endregion DESKTOP */

  /* #region LEGADO_DESKTOP */
  /*media query min-width 700px*/
  @media screen and (min-width: 700px) {
    .weex-container {
      margin-left: 2em;
      margin-right: 2em;
      display: block;
    }

    .weex-main-content-border {
      border-radius: 8px 8px 0px 0px;
    }

    .campanha_logosTopo__centro img {
      height: auto;
      max-width: 12vw !important;
      max-height: 10vh !important;
      margin-right: 4em;
    }

    .campanha_logosTopo__centro__notLogged {
      width: 100%;
    }

    .sala-de-trofeus {
      :hover {
        filter: opacity(0.5);
      }
    }

    .weex_select_campaign_container_content {
      margin: 0 30vw 0 30vw;
    }

    .login {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
    }

    .login-opcoes {
      margin-top: 1em;
    }

    .cadastro-desktop {
      display: block;
    }

    .login-opcoes > p > a,
    .button-link {
      font-size: 1em;
    }

    .box-custom {
      background: transparent;
      border: 1px solid #ccc;
      border-radius: 14px;
      position: relative;
    }

    .input,
    .input:focus,
    .input:hover,
    .input:active,
    select,
    select:focus,
    select:active,
    select:hover {
      border-left: 5px solid
        ${(props) => {
          return props.theme.fundoSecundario || `#533888`;
        }};
    }

    .icon-custom,
    .icon-custom-right {
      color: ${(props) => {
        if (
          (props.usuariologado !== null && props.usuario === "true") ||
          (props.usuariologado !== undefined && props.usuario === "true")
        ) {
          return props.theme.fundoSecundario;
        } else {
          return props.theme.fundoPrimario || `#F3F5F9`;
        }
      }};
    }

    .marcador-progresso {
      padding-top: 1em;
      justify-content: center;
    }

    .marcador {
      margin: 0.3em;
    }

    .dinamica-content-desktop {
      margin: 1vw 5vw;
    }

    .full-width-desktop {
      width: 100%;
    }

    .modal-button-missaocomprida {
      width: 80%;
    }

    .campanha_logosTopo {
      min-height: 10vh;
      margin: auto;
      width: 30%;
      text-align: center;
    }

    .foreground_not_logged {
    }

    .foreground {
      color: ${(props) => {
        return props.theme.textosCorFundoSecundario;
      }};
    }

    .foreground_not_logged {
      color: ${(props) => {
        return props.theme.textosCorFundoPrimario;
      }};
    }

    .card-atividades__imagem {
      background-size: 60%;
    }

    .card-atividades-trofeu__imagem {
      background-size: cover;
    }

    .icone_atividades {
      width: 4vw;
      height: 4vw;
    }

    .card-atividades {
      display: flex;
      justify-content: center;
      border-radius: 8px;
    }

    .campanha_logosTopo__centro__footer {
      margin: 0;
    }

    .weex_footer {
      margin: 0 35vw;
    }

    .weex_footer img {
      max-width: 100%;
    }

    .weex-container-logado-grow {
      margin-top: 0em;
    }

    .games-apresentacao > :not(div:first-child) {
      margin-top: 1.5rem;
    }

    .inputSearch {
      margin-right: 2em;
      margin-left: 0;
    }

    .button-fa-times {
      margin-top: 0.3em;
    }

    .button-fa-times > svg {
      width: 3em;
      height: 1.2em;
    }
    .botao-ja-possuo-cadastro {
      padding: 0.8rem 0.5rem;
      margin-top: 2em;
    }
  }
  /* #endregion DESKTOP-ANTIGO */

  /* #endregion LEGADO_DESKTOP */
`;
