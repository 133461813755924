import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

function GlobalTheme({ children }) {
  let { template } = useSelector((state) => {
    return {
      template: state.template,
    };
  });
  return (
    <ThemeProvider theme={template.finalTemplate}>{children}</ThemeProvider>
  );
}

export default GlobalTheme;
