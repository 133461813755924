import styled from "styled-components";

export const PagesHeaderStyle = styled.header`
  margin-top: 1.25rem;

  .weex-l-page-header-title {
    margin-bottom: 0.625rem;
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    line-height: 0.938rem;
  }

  .weex-l-page-header-subtitle {
    color: ${(props) => {
      return props.theme.cardLogadoTextoSegundo;
    }};
    text-align: center;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1rem;
  }
`;
