import React from "react";

const RankingGamer = (props) => {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 27 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.54417 7.06465H7.43087V5.9514C7.43087 5.52709 7.08684 5.18311 6.66248 5.18311C6.23812 5.18311 5.89408 5.52709 5.89408 5.9514V7.06465H4.78083C4.35647 7.06465 4.01244 7.40863 4.01244 7.83294C4.01244 8.25725 4.35647 8.60123 4.78083 8.60123H5.89413V9.71443C5.89413 10.1387 6.23817 10.4827 6.66253 10.4827C7.08689 10.4827 7.43092 10.1387 7.43092 9.71443V8.60128H8.54423C8.96859 8.60128 9.31262 8.2573 9.31262 7.83299C9.31257 7.40863 8.96853 7.06465 8.54417 7.06465Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9234 7.83298C16.9234 6.2236 18.2328 4.91431 19.8423 4.91431C21.4517 4.91431 22.7611 6.22355 22.7612 7.83298C22.7612 9.4423 21.4518 10.7516 19.8423 10.7516C18.2328 10.7516 16.9234 9.4423 16.9234 7.83298ZM18.4602 7.83293C18.4602 8.59497 19.0802 9.21491 19.8423 9.21491C20.6044 9.21491 21.2244 8.59497 21.2244 7.83293C21.2244 7.07088 20.6044 6.45089 19.8423 6.45089C19.0802 6.45089 18.4602 7.07088 18.4602 7.83293Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1512 11.9689L24.6743 3.9479C24.3246 2.04904 22.6728 0.670898 20.7465 0.670898H18.0339C17.8493 0.670898 17.6708 0.73739 17.5312 0.85813L15.9707 2.20799H10.2571L8.69658 0.85813C8.55693 0.737339 8.37851 0.670898 8.19389 0.670898H5.48131C3.55499 0.670898 1.90315 2.04904 1.55353 3.94784L0.0766239 11.9689C-0.37427 14.4174 1.2098 16.7751 3.64407 17.2756C5.35119 17.6266 7.07839 16.9898 8.15173 15.6136L9.83882 13.4506H16.3891L18.0762 15.6136C19.143 16.9814 20.8662 17.6287 22.5838 17.2756C25.0124 16.7763 26.603 14.4226 26.1512 11.9689ZM22.2743 15.7705C21.1438 16.003 19.9993 15.5807 19.2879 14.6686L17.3701 12.2098C17.2245 12.0231 17.001 11.9139 16.7642 11.9139H9.46359C9.22682 11.9139 9.00327 12.0231 8.85768 12.2098L6.93987 14.6686C6.22844 15.5807 5.0841 16.003 3.95353 15.7705C2.34062 15.4389 1.28833 13.8744 1.58795 12.2472L3.06486 4.22605C3.28021 3.0564 4.29644 2.20748 5.48125 2.20748H7.90759L9.46815 3.55735C9.60779 3.67814 9.78621 3.74458 9.97083 3.74458H16.2568C16.4414 3.74458 16.6199 3.67809 16.7595 3.55735L18.3201 2.20748H20.7464C21.9312 2.20748 22.9474 3.0564 23.1628 4.22605L24.6398 12.2471C24.9392 13.8729 23.8886 15.4386 22.2743 15.7705Z"
      />
    </svg>
  );
};

export default RankingGamer;
