import React, { useEffect, useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { useStore } from "react-redux";
import { StyledTreeDropdow } from "./style";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";

/**
 * @typedef {Object} i18n - objeto de internacionalização
 * @property {function(string, string): string} i18n.message - função que retorna a mensagem de acordo com a chave
 */

/**
 * @typedef {Object} TransformedUnit
 * @property {string} label - O rótulo da unidade.
 * @property {string} value - O valor da unidade.
 * @property {boolean} expanded - Se a unidade está expandida.
 * @property {boolean} isDefaultValue - Se a unidade é a padrão.
 * @property {boolean} checked - Se a unidade está selecionada.
 * @property {TransformedUnit[]} children - Os filhos da unidade.
 */

/**
 *
 * @param {Object} props
 * @param {import('../../services/CampanhaService').Unit[]} props.unidades - Dados de entrada
 * @param {string[]} props.dataInputDefault - Dados de entrada
 * @param {string[]} props.expandeds - Dados de entrada
 * @param {boolean} props.selectAll - Dados de entrada
 * @param {boolean} props.ischeckedSuaUnidade - Dados de entrada se a unidade foi selecionada
 * @param {function(import('../../services/CampanhaService').Unit[]): void} props.onChangeSelect - Função que retorna os dados selecionados
 * @param {TransformedUnit[]} props.arvore - Dados de entrada, arvore de unidades salvas
 */

export default function SelectTreeDropdow({ unidades, onChangeSelect }) {
  const i18n = useStore().getState().i18n;

  const [data, setData] = useState([
    {
      label: "",
      value: "",
      children: [],
      isDefaultValue: false,
      expanded: false,
    },
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [actionSelect, setActionSelect] = useState("none");
  const [tabPressedMessage, setTabPressedMessage] = useState("");

  const selectedNodes = [];

  const text = {
    placeholder: i18n.message(
      "rankingCampanha.selecione.filtroRanking",
      "Filtrar/pesquisar ranking",
    ),
    label: i18n.message(
      "rankingCampanha.selecione.filtroRanking",
      "Filtrar ranking",
    ),
    inlineSearchPlaceholder: i18n.message(
      "rankingCampanha.selecione.filtroRanking",
      "Filtrar ranking",
    ),
    noMatches: i18n.message(
      "rankingCampanha.selecione.filtroRanking.noMatches",
      "Nenhum resultado encontrado",
    ),
  };

  useEffect(() => {
    if (unidades) {
      const dados = convertUnit(unidades);
      /**
       * @type {TransformedUnit}
       */
      const data = [
        {
          label: i18n.message(
            "rankingCompeticao.select.suaUnidade",
            "Sua unidade",
          ),
          value: "SUA_UNIDADE",
          children: [],
        },
        {
          label: i18n.message("rankingCompeticao.select.geral", "Todos"),
          value: "GERAL",
          children: dados,
          expanded: true,
        },
      ];
      setData(data);
    }

    const div = document.getElementsByClassName("dropdown-content");

    if (div.length > 0) {
      setDropdownHeight(div[0].offsetHeight);
    }

    const handleResize = () => {
      const currentZoomLevel = window.devicePixelRatio;
      setZoomLevel(currentZoomLevel);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen, zoomLevel]);

  /*
    Acessibilidade
  */
  useEffect(() => {
    const handleTabKey = (event) => {
      if (event.key === "Tab" && dropdownOpen) {
        setTabPressedMessage(
          i18n.message(
            "aria.status.ranking.selecionado",
            "Ranking selecionado!",
          ),
        );
        onSubmitUnidades();
      }
    };
    document.addEventListener("keydown", handleTabKey);
    return () => {
      document.removeEventListener("keydown", handleTabKey);
    };
  });

  /**
   *
   * @param {import('../../services/CampanhaService').Unit[]} data
   * @returns {TransformedUnit[]}
   */
  const convertUnit = (data) => {
    if (data) {
      return data.map((unit) => {
        return {
          label: unit.name,
          value: unit.uuid,
          children: unit.subUnits ? convertUnit(unit.subUnits) : [],
          className: "weex-l-dropdown-tree-node",
        };
      });
    }
  };

  /**
   *
   * @param {TransformedUnit[]} selectedNodesEvent
   * @param {TransformedUnit} currentNode
   *
   */
  const onChange = (currentNode, selectedNodesEvent) => {
    const suaUnidade = selectedNodesEvent.filter(
      (node) => node.value === "SUA_UNIDADE",
    );
    const geral = selectedNodesEvent.filter((node) => node.value === "GERAL");

    if (selectedNodesEvent.length === 0) {
      searchInTreeAtualizarFilhos(data[1], false);
      setActionSelect("none");
      return;
    }

    if ((suaUnidade || geral) && selectedNodesEvent.length > 0) {
      if (
        suaUnidade &&
        currentNode.value === "SUA_UNIDADE" &&
        currentNode.checked
      ) {
        setActionSelect("SUA_UNIDADE");
        data[0].checked = true;
        data[1] = { ...data[1], checked: false };
        searchInTreeAtualizarFilhos(data[1], false);
        return;
      } else if (
        geral &&
        currentNode.value === "GERAL" &&
        currentNode.checked
      ) {
        data[0] = { ...data[0], checked: false };
        searchInTreeAtualizarFilhos(data[1], currentNode.checked);
        onNodeToggle(data[1]);
        setActionSelect("GERAL");
        return;
      } else {
        data[1] = { ...data[1], checked: false };
        data[0] = { ...data[0], checked: false };
      }

      let nodeEscolhido = searchInTreeTransformedUnit(
        data[1],
        currentNode.value,
      );

      if (nodeEscolhido) {
        searchInTreeAtualizarFilhos(nodeEscolhido, currentNode.checked);
        onNodeToggle(nodeEscolhido);
        nodeEscolhido.expanded = false;
      }

      /**
       * @type {TransformedUnit[]}
       */
      let ancestrais = [];
      searchInTreeTransformedUnitAncestrais(
        data[1],
        currentNode.value,
        ancestrais,
      );
      ancestrais.splice(-1, 1);
      ancestrais.forEach((node) => {
        node.expanded = true;
      });
      selectedNodes.splice(0, selectedNodes.length - 1);
      selectedNodesEvent.forEach((node) => {
        selectedNodes.push(node);
      });
    }
  };

  /**
   *
   * @param {TransformedUnit} currentNode
   *
   */
  const onNodeToggle = (currentNode) => {
    data.forEach((node) => {
      searchInTreeAndUpdate(node, currentNode);
    });
  };

  const onSubmitUnidades = () => {
    setDropdownOpen(false);
    if (selectedNodes.length === 0) {
      if (actionSelect === "SUA_UNIDADE") {
        onChangeSelect([], "SUA_UNIDADE");
        return;
      } else if (actionSelect === "GERAL") {
        onChangeSelect([], "ALL");
        return;
      }
      onChangeSelect([], "none");
      return;
    }

    const unidadesRaizes = buscarRaizesSelecionadas(selectedNodes);
    const buscarSubniveis = buscarSubniveisApartirDasRaizes(unidadesRaizes);
    if (buscarSubniveis.length <= 0 && unidadesRaizes.length > 0) {
      onChangeSelect([...unidadesRaizes]);
    } else {
      onChangeSelect([...buscarSubniveis]);
    }
  };

  const buscarSubniveisApartirDasRaizes = (unidadesRaizes) => {
    const buscarSubniveis = [];
    unidadesRaizes.forEach((unidade) => {
      if (unidade.subUnits && unidade.subUnits.length > 0) {
        encontrarSubniveis(unidade, buscarSubniveis);
      }
    });
    return buscarSubniveis;
  };

  /**
   *
   * @param {import('../../services/CampanhaService').Unit} node
   * @param {import('../../services/CampanhaService').Unit[]} result
   * @returns {import('../../services/CampanhaService').Unit[]} retorna os filhos de um nó
   */
  const encontrarSubniveis = (node, result) => {
    if (node.subUnits && node.subUnits.length === 0) {
      result.push({ ...node, selecionadoTree: true });
      return;
    }

    // Percorre os nós filhos
    if (node.subUnits && node.subUnits.length > 0) {
      for (const element of node.subUnits) {
        encontrarSubniveis(element, result); // Chama recursivamente para cada nó filho
      }
    }
  };

  /**
   *
   * @param {TransformedUnit[]} selectedNodes
   * @returns {import('../../services/CampanhaService').Unit[]} retorna os nós raizes selecionados
   */

  const buscarRaizesSelecionadas = (selectedNodes) => {
    const selectUnidades = [];
    if (selectedNodes.length > 0) {
      selectedNodes.forEach((target) => {
        unidades.forEach((node) => {
          const result = searchInTree(node, target.value);
          if (result) {
            selectUnidades.push({ ...result, selecionadoTree: true });
          }
        });
      });
    }
    return selectUnidades;
  };

  /**
   *
   * @param {TransformedUnit} node
   * @param {TransformedUnit} target
   *
   */
  const searchInTreeAndUpdate = (node, target) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    if (node.value === target.value) {
      node.expanded = target.expanded;
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.children && node.children.length > 0) {
      for (let element of node.children) {
        // Realiza a busca recursivamente em cada nó filho
        let result = searchInTreeAndUpdate(element, target);
        if (result) {
          return result;
        }
      }
    }
  };

  const searchInTree = (node, target) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    if (node.uuid === target) {
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.subUnits && node.subUnits.length > 0) {
      for (const element of node.subUnits) {
        // Realiza a busca recursivamente em cada nó filho
        const result = searchInTree(element, target);
        if (result) {
          return result; // Retorna o resultado se encontrado em algum nó filho
        }
      }
    }

    return null; // Retorna null se o elemento não for encontrado
  };

  /**
   *
   * @param {TransformedUnit} node
   * @param {TransformedUnit} target
   *
   */

  const searchInTreeTransformedUnit = (node, target) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    if (node.value === target) {
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.children && node.children.length > 0) {
      for (let element of node.children) {
        // Realiza a busca recursivamente em cada nó filho
        let result = searchInTreeTransformedUnit(element, target);
        if (result) {
          return result; // Retorna o resultado se encontrado em algum nó filho
        }
      }
    }

    return null; // Retorna null se o elemento não for encontrado
  };

  /**
   *
   * @param {TransformedUnit} node
   * @param {TransformedUnit} target
   * @param {TransformedUnit[]} ancestrais
   * @returns {TransformedUnit}
   */
  const searchInTreeTransformedUnitAncestrais = (node, target, ancestrais) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    ancestrais.push(node);
    if (node.value === target) {
      return node;
    }

    // Percorre os nós filhos (se houver)
    if (node.children && node.children.length > 0) {
      for (let element of node.children) {
        // Realiza a busca recursivamente em cada nó filho
        let result = searchInTreeTransformedUnitAncestrais(
          element,
          target,
          ancestrais,
        );
        if (result) {
          return result; // Retorna o resultado se encontrado em algum nó filho
        }
      }
    }

    ancestrais.splice(-1, 1);
    return null; // Retorna null se o elemento não for encontrado
  };

  /**
   *
   * @param {TransformedUnit} node
   * @param {boolean} checked
   *
   */
  const searchInTreeAtualizarFilhos = (node, checked) => {
    // Verifica se o nó atual corresponde ao elemento desejado
    node.checked = checked;

    // Percorre os nós filhos (se houver)
    if (node.children && node.children.length > 0) {
      for (let element of node.children) {
        // Realiza a busca recursivamente em cada nó filho
        searchInTreeAtualizarFilhos(element, checked);
      }
    }
  };

  const style = {
    marginTop: `${dropdownHeight}px`,
  };

  return (
    <StyledTreeDropdow>
      <form>
        <DropdownTreeSelect
          texts={text}
          data={data}
          className="weex-l-dropdown-tree-select-custom field"
          keepTreeOnSearch={true}
          onChange={onChange}
          onNodeToggle={onNodeToggle}
          onFocus={() => {
            setDropdownOpen(true);
          }}
          onBlur={() => {
            setDropdownOpen(false);
          }}
        />
        {dropdownOpen && (
          <div style={style} className="weex-l-dropdown-tree-confirmar">
            <BotaoPrimario
              nome={i18n.message(
                "rankingCampanha.selecione.filtroRanking.botaoConfirmar",
                "confirmar",
              )}
              classeComplementar="weex-l-dropdown-tree-confirmar__button button"
              funcao={onSubmitUnidades}
            />
          </div>
        )}
      </form>
      <span className="weex-g-visually-hidden" role="alert" aria-live="polite">
        {tabPressedMessage}
      </span>
    </StyledTreeDropdow>
  );
}
