import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { ExplanationStyle } from "./ExplanacaoATStyle";
import React from "react";

function ExplanacaoAT() {
  const { obtemPontuacaoQuestaoHC, indiceAcerteTempo, obtemPlacarFinal } =
    useAcerteTempo();

  let alternativaEscolhida;
  if (obtemPlacarFinal !== null) {
    alternativaEscolhida = obtemPlacarFinal?.scores[indiceAcerteTempo];
  } else if (obtemPontuacaoQuestaoHC !== null) {
    alternativaEscolhida = obtemPontuacaoQuestaoHC;
  }

  const explanation = (alternativaEscolhida) => {
    if (alternativaEscolhida && alternativaEscolhida.explanation) {
      const src = alternativaEscolhida.correct
        ? "/AT_Certo.svg"
        : "/AT_Errado.svg";
      const classNameContainerExplanacao = alternativaEscolhida.correct
        ? "weex-l-acerteTempo-explicacao-acerto"
        : "weex-l-acerteTempo-explicacao-error";

      const alt = alternativaEscolhida.correct ? "Acertou" : "Errou";
      return (
        <div
          className={`
          is-flex is-align-content-center mt-6 
          ${classNameContainerExplanacao}`}
        >
          <img alt={alt} className="mr-6" width="30" src={src}></img>
          <span
            className=""
            dangerouslySetInnerHTML={{
              __html: alternativaEscolhida?.explanation,
            }}
          ></span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <ExplanationStyle>{explanation(alternativaEscolhida)}</ExplanationStyle>
  );
}

export default ExplanacaoAT;
