import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import WeexProgressBar from "../../WeexFlatProgressBar/WeexProgressBar";
import { AgendaHeaderStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";

function AgendaHeader({
  completionPercentage,
  nome,
  nomeInteresse,
  dayOfWeekComplete,
  isAgendaProlongada,
}) {
  const [completed, setCompleted] = useState(0);
  const i18n = useStore().getState().i18n;
  let completedColor = completed === "100" ? "#00E291" : "#847D92";

  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";

  useEffect(() => {
    setTimeout(() => setCompleted(completionPercentage), 100);
  }, [completionPercentage]);

  return (
    <AgendaHeaderStyle $completedColor={completedColor}>
      <h1 className="weex-l-titulo-da-agenda-corrente">
        {nome ? nome : nomeInteresse}
      </h1>
      <div>
        <div className="weex-l-agenda-cabecalho">
          <div className="weex-l-barra-de-progresso-agenda">
            <div className="weex-l-informacoes-complementares">
              {!isAgendaProlongada && shouldShowScheduleDate && (
                <p className="weex-l-informacoes-complementares__textos weex-l-dia-da-semana">
                  {dayOfWeekComplete}
                </p>
              )}
              <p className="weex-l-informacoes-complementares__textos">
                {`${completed}% ${i18n.message(
                  "geral.porcentagem.concluido",
                  "Concluído",
                )}`}
              </p>
            </div>
            <WeexProgressBar
              bgColor={completed === "100" ? "#00E291" : "#847D92"}
              completed={completed}
              isAgendaProlongada={isAgendaProlongada}
            />
          </div>
        </div>
      </div>
    </AgendaHeaderStyle>
  );
}

export default AgendaHeader;
