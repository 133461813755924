/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import InputMask from "react-input-mask";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordService from "../../services/PasswordService";
const WeexInput = (props) => {
  const [state, setState] = useState({
    passwordType: "password",
    password: "",
    passwordStrength: 0,
    label: "Muito curta",
    color: "#E0E0DF",
    percentage: 0,
  });

  const inputNumber = () => {
    return (
      <input
        step="1"
        id={props.id}
        className="input input-size is-medium"
        min="14"
        max="100"
        type="number"
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        required={props.required}
      />
    );
  };

  const inputDate = () => {
    return (
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <InputMask
          mask="99/99/9999"
          id={props.id}
          className="input input-size is-medium"
          type="text"
          name={props.name}
          placeholder="DD/MM/AAAA"
          onChange={props.onChange}
          required={props.required}
        />
      </div>
    );
  };

  const inputPadrao = () => {
    return (
      <input
        id={props.id}
        pattern={props.pattern}
        className="input input-size is-medium"
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        required={props.required}
      />
    );
  };

  const inputPadraoWithIcone = (password) => {
    return (
      <div className="control has-icons-left has-icons-right">
        <input
          id={props.id}
          className="input input-size is-medium"
          type={password}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          required={props.required}
        />
        <span className="icon is-small is-right">
          <i className={props.icon}></i>
        </span>
      </div>
    );
  };

  const inputListaNumero = () => {
    let min = props.min;
    let max = props.max;
    let itens = [];
    for (var i = min; i <= max; i++) {
      itens.push(
        <option
          key={"value_option_" + i}
          value={i}
          selected={props.value && props.value === i}
        >
          {i}
        </option>,
      );
    }
    return (
      <select
        className="select-input input is-medium"
        required={props.required}
        name={props.name}
        onChange={props.onChange}
      >
        {itens}
      </select>
    );
  };

  const handlePasswordChange = (e) => {
    props.onChange(e);
    const newPassword = e.target.value;
    setState({ password: newPassword });
    calculatePasswordStrength(newPassword);
  };

  const calculatePasswordStrength = (password) => {
    const passowordService = new PasswordService();
    const passwordStrength =
      passowordService.calculatePasswordStrength(password);
    const { label, color, percentage } = getPasswordStrengthBarInfo(
      password,
      passwordStrength,
    );
    setState({ passwordStrength, label, color, percentage });
  };

  const getPasswordStrengthBarInfo = (password, passwordStrength) => {
    if (password.length < 8) {
      return {
        label: "Muito curta",
        color: "#E0E0DF",
        percentage: 0,
      };
    }

    let label = "Fraca";
    let color = "#8B2230";
    let percentage = 25;

    if (passwordStrength >= 50) {
      label = "Moderada";
      color = "#F1C74F";
      percentage = 50;
    }
    if (passwordStrength >= 75) {
      label = "Boa";
      color = "#799DBF";
      percentage = 75;
    }
    if (passwordStrength === 100) {
      label = "Forte";
      color = "#137512";
      percentage = 100;
    }

    return {
      label,
      color,
      percentage,
    };
  };

  const handleTogglePasswordType = () => {
    setState((prevState) => ({
      passwordType: prevState.passwordType === "password" ? "text" : "password",
    }));
  };

  const inputSenhaWithToogle = () => {
    const showPassword = state.passwordType === "text";

    return (
      <div className="input-strong-password">
        <div className="input-container-strong-password">
          <input
            autoComplete={props.autoComplete}
            type={state.passwordType}
            id={props.id}
            className="input input-size is-medium"
            onChange={props.onChange}
            placeholder={props.placeholder}
            required={props.required}
            name={props.name}
          />
        </div>
        <i
          onClick={handleTogglePasswordType}
          className="password-toggle-button"
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </i>
      </div>
    );
  };

  const inputSenhaForte = () => {
    const showPassword = state.passwordType === "text";

    return (
      <div className="input-strong-password">
        <div className="input-container-strong-password">
          <input
            autoComplete="new-password"
            type={state.passwordType}
            id={props.id}
            className="input input-size is-medium"
            onChange={handlePasswordChange}
            placeholder={props.placeholder}
            required={props.required}
            name={props.name}
          />
          <div className="password-strength-bar">
            <div
              className="strength-bar-fill"
              style={{
                width: `${state.percentage}%`,
                backgroundColor: state.color,
              }}
            />
            <p>
              Força da senha:{" "}
              <strong>{state.password.length > 0 ? state.label : ""}</strong>
            </p>
          </div>
        </div>
        <i
          onClick={handleTogglePasswordType}
          className="password-toggle-button"
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </i>
      </div>
    );
  };

  let input = null;
  switch (props.type.toLowerCase()) {
    case "listaNumero":
      input = inputListaNumero();
      break;
    case "number":
      if (props.max && props.min) {
        input = inputNumber();
      } else {
        input = inputPadrao();
      }
      break;
    case "textIcon":
      input = inputPadraoWithIcone();
      break;
    case "passwordIcon":
      input = inputPadraoWithIcone("password");
      break;
    case "emailIcon":
      input = inputPadraoWithIcone("email");
      break;
    case "strongPassword":
      input = inputSenhaForte();
      break;
    case "passwordWithToogle":
      input = inputSenhaWithToogle();
      break;
    case "date":
      input = inputDate();
      break;
    default:
      input = inputPadrao();
  }

  return (
    <div className="field" style={{ marginBottom: "1rem" }}>
      {props.label ? (
        <label
          htmlFor={props.id}
          className="label"
          aria-label={props.ariaLabel}
        >
          {props.label}
        </label>
      ) : (
        <label
          style={{ opacity: 0, padding: "1px" }}
          htmlFor={props.id}
          className="label"
          aria-label={props.ariaLabel}
        >
          Label
        </label>
      )}
      <div className="control">
        {props.mascara ? (
          <div>
            <InputMask
              placeholder={props.placeholder}
              className={props.classImputMask}
              mask={props.mascara}
              maskChar={null}
              name={props.name}
              onChange={props.onChange}
              required={props.required}
              aria-describedby={props.ariaDescribedby}
            />
            {props.legenda ? (
              <div>
                <span className="legenda-input">{props.legenda}</span>
              </div>
            ) : null}
          </div>
        ) : (
          input
        )}
      </div>
    </div>
  );
};

export default WeexInput;
