import React from "react";

function Burguer({ toggle, toggleMenu, altOpen, altClose }) {
  return (
    <>
      <button className="button-link" type="button" onClick={toggle}>
        {toggleMenu ? (
          <>
            <img src="/menu_aberto.png" className="menu" alt={altOpen} />
          </>
        ) : (
          <img src="/menu_fechado.png" className="menu" alt={altClose} />
        )}
      </button>
    </>
  );
}

export default Burguer;
