import React from "react";
import { GameRankingsListStyled } from "../../comps/rankings/styled";
import PontuacaoUsuario from "./PontuacaoUsuario";

function PontuacaoUsuarioCorrente({ pontuacaoUsuario, foraDoRanking }) {
  return (
    <GameRankingsListStyled>
      <div className="participante-content usuario-corrente">
        <div className="participante">
          <PontuacaoUsuario
            pontuacaoUsuario={pontuacaoUsuario}
            isParticipante={true}
            foraDoRanking={foraDoRanking}
          />
        </div>
      </div>
    </GameRankingsListStyled>
  );
}

export default PontuacaoUsuarioCorrente;
