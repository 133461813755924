import React from "react";
import { useStore } from "react-redux";
import BotaoSecundario from "../../botaoSecundario/BotaoSecundario";
import BotaoPrimario from "../../botaoPrimario/BotaoPrimario";
import BotaoAsALink from "../../botaoAsALink/BotaoAsALink";
import WeexDesktopMode from "../../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../../WeexMode/WeexMobileMode";
/**
 * @param {Object} props
 * @param {number} props.indiceQuestao
 * @param {number} props.totalQuestoes
 * @param {() => void} props.onAvancar
 * @param {() => void} props.onVoltar
 * @param {() => void} props.onConcluir
 */
export default function FatoFakeRevisaoBotoes({
  indiceQuestao,
  totalQuestoes,
  onAvancar,
  onVoltar,
  onConcluir,
}) {
  const i18n = useStore().getState().i18n;
  const indicePrimeiraQuestao = 0;
  const indiceUltimaQuestao = totalQuestoes - 1;
  const ultimaQuestao = indiceQuestao === indiceUltimaQuestao;
  const primeiraQuestao = indiceQuestao <= indicePrimeiraQuestao;

  return (
    <div className="weex-l-FatoFakeRevisao-BotoesColumn">
      <div className="weex-l-FatoFakeRevisao-BotoesRow">
        {primeiraQuestao && (
          <>
            <div className="weex-g-CardFatoFakeRevisao-BotaoContainer">
              <BotaoSecundario
                nome={i18n.message(
                  "dinamica.fatofakeRevisao.avancar",
                  "AVANÇAR",
                )}
                funcao={onAvancar}
              />
            </div>
          </>
        )}
        {indiceQuestao > indicePrimeiraQuestao &&
          indiceQuestao < indiceUltimaQuestao && (
            <>
              <div className="weex-g-CardFatoFakeRevisao-BotaoContainer">
                <BotaoSecundario
                  nome={
                    <span className="weex-g-FatoFakeRevisao-botaoSecundarioSpanContainer">
                      <i className="fas fa-chevron-left" />
                      {i18n.message(
                        "dinamica.fatofakeRevisao.voltar",
                        "VOLTAR",
                      )}
                    </span>
                  }
                  disabledOnClick={false}
                  funcao={onVoltar}
                />
              </div>
              <div className="weex-g-CardFatoFakeRevisao-BotaoContainer">
                <BotaoSecundario
                  nome={
                    <span className="weex-g-FatoFakeRevisao-botaoSecundarioSpanContainer">
                      {i18n.message(
                        "dinamica.fatofakeRevisao.avancar",
                        "AVANÇAR",
                      )}
                      <i className="fas fa-chevron-right" />
                    </span>
                  }
                  disabledOnClick={false}
                  funcao={onAvancar}
                />
              </div>
            </>
          )}
        {ultimaQuestao && (
          <>
            <WeexDesktopMode
              className={"weex-g-CardFatoFakeRevisao-BotaoContainer"}
              style={{ width: "100%" }}
            >
              <BotaoSecundario
                nome={
                  <span className="weex-g-FatoFakeRevisao-botaoSecundarioSpanContainer">
                    <i className="fas fa-chevron-left" />
                    {i18n.message("dinamica.fatofakeRevisao.voltar", "VOLTAR")}
                  </span>
                }
                disabledOnClick={false}
                funcao={onVoltar}
              />
            </WeexDesktopMode>
            <div className="weex-g-CardFatoFakeRevisao-BotaoContainer">
              <BotaoPrimario
                nome={i18n.message(
                  "dinamica.fatofakeRevisao.concluir",
                  "CONCLUIR",
                )}
                ariaDisabled={false}
                ariaHidden={false}
                ariaLabelMsg={i18n.message(
                  "dinamica.fatofakeRevisao.concluir",
                  "CONCLUIR",
                )}
                ariaStatusMessage={i18n.message(
                  "dinamica.fatofakeRevisao.concluir.ariaLabel",
                  "botão CONCLUIR clicado",
                )}
                disabled={false}
                funcao={onConcluir}
              />
            </div>
          </>
        )}
      </div>

      <WeexMobileMode className={"weex-l-FatoFakeRevisao-respiroBotaoVoltar"}>
        <BotaoAsALink
          nome={
            ultimaQuestao ? (
              <span className="weex-g-FatoFakeRevisao-botaoSecundarioSpanContainer">
                <i className="fas fa-chevron-left" />
                {i18n.message("dinamica.fatofakeRevisao.voltar", "VOLTAR")}
              </span>
            ) : (
              i18n.message(
                "dinamica.fatofakeRevisao.voltarAtividades",
                "FECHAR",
              )
            )
          }
          funcao={ultimaQuestao ? onVoltar : onConcluir}
        />
      </WeexMobileMode>
    </div>
  );
}
