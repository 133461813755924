import styled from "styled-components";

export const AtividadesDoDiaContainerStyle = styled.div`
  width: 82%;
  display: inline;
  text-align: center;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 0.5em;
    overflow: auto;
    max-height: 20rem;
    padding-bottom: 2.5em;
  }
  /* #endregion DESKTOP */
`;

export const AtividadeComSubtitulos = styled.div`
  margin-bottom: 1em;

  .weex-l-atividade-subtitulo {
    color: ${(props) => {
      return props.theme.cardLogadoTextoSegundo;
    }};
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1.25rem;
    text-align: left;
    margin-left: 9%;
  }

  .weex-l-atividade-subtitulo:first-child {
    margin-top: 1.5%;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-top: 0.9em;
    margin-bottom: 0.5em;

    .weex-l-atividade-subtitulo {
      margin: 0 2vw;
    }
  }
  /* #endregion DESKTOP */
`;

export const AtividadeBotaoContainerStyle = styled.div`
  width: ${(props) => {
    return props.tamanho;
  }};
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0 auto 0.625rem;
  margin-left: 4%;
  margin-right: 4%;
  flex-wrap: wrap;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    justify-content: flex-start;
  }
  /* #endregion DESKTOP */
`;
