import instance from "./Api";

export default class AtividadePresencialService {
  host() {
    return process.env.REACT_APP_HOST_API;
  }

  feedbackSaveRequest(code, request) {
    return instance.post(
      this.host() + `/api/v1/campaign/${code}/presencialDynamic/feedback`,
      request,
    );
  }
}
