import CampanhaService from "./CampanhaService";

const campanhaService = new CampanhaService();

export default class TemplateService {
  constructor() {
    this.template = campanhaService.templateCorrente();
    this.finalTemplate = {};
  }

  load() {
    this.init(this.template);
  }

  init(template) {
    let finalTemplateObj = {};
    if (template && template.colors && template.shadows) {
      for (let c of template.colors) {
        finalTemplateObj[c.code] = c.color;
      }

      for (let s of template.shadows) {
        finalTemplateObj[s.code] = s.boxShadow;
      }
    }
    this.finalTemplate = finalTemplateObj;
  }
}
