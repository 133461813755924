import styled from "styled-components";

export const ChangePasswordStyle = styled.div`
  .titulo {
    font-family: Poppins;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
  }

  .button-submit {
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    text-transform: uppercase;
    width: 100%;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    line-height: 0.9rem;
    letter-spacing: 0.2rem;
    font-weight: 700;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
    box-shadow: ${(props) => {
      return props.theme.sombraPrimaria;
    }};
    cursor: pointer;
  }
`;
