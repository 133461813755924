import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RankingGameService from "../../services/RankingGameService";
import AtividadeEntity from "../agenda/AtividadeEntity";

function GameRankings({ searchRankingGame }) {
  const rankingService = new RankingGameService();
  const gameUuid = useSelector((state) => state.gameUuid);
  const [games, setGames] = useState([]);

  useEffect(() => {
    setGames(rankingService.getGamesAvailable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gameItems = () => {
    return games.map((g) => {
      const game = new AtividadeEntity(g);
      const dia = game.day.split("-")[2];
      return (
        <option selected={isSelected(game)} key={game.uuid} value={game.uuid}>
          {`GAME DIA ${dia}  ${game.title ? game.title : game.name}`}
        </option>
      );
    });
  };

  const isSelected = (g) => {
    const game = new AtividadeEntity(g);
    return gameUuid === game.uuid;
  };

  const gameSelected = (event) => {
    const selectedGame = games.find((game) => game.uuid === event.target.value);
    if (selectedGame) {
      searchRankingGame(selectedGame);
    }
  };

  return (
    <form>
      <div className="field">
        <div className="control">
          <select
            defaultValue={gameUuid}
            className="select-input input is-medium"
            onChange={gameSelected}
            onBlur={gameSelected}
            style={{ height: "3.238rem" }}
          >
            {gameItems()}
          </select>
        </div>
      </div>
    </form>
  );
}

export default GameRankings;
