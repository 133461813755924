import React from "react";
import styled from "styled-components";

const CorrenteStyle = styled.svg`
  fill: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  width: ${(props) => {
    return props.w || `1.5rem`;
  }};
  height: ${(props) => {
    return props.h || `1.5rem`;
  }};
  stroke-width: 0px;
`;

const CorrenteSVG = ({ height }) => {
  return (
    <CorrenteStyle
      //w={width}
      h={height}
      width="23"
      height="24"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 204.08 204.21"
    >
      <path
        class="cls-1"
        d="m87.14,56.22c14.13.19,26.14,4.13,36.74,12.17,2.84,2.16,5.57,4.46,8.27,6.8,3.64,3.16,4.08,7.78,1.06,11.53-3.23,4.01-6.53,7.96-9.9,11.85-3.3,3.82-7.75,4.16-11.7,1-2.33-1.87-4.55-3.88-6.94-5.67-10.58-7.91-25.5-7.57-35.09,1.46-10.57,9.96-20.89,20.21-30.81,30.81-9.96,10.66-9.11,27.48,1.23,37.88,10.23,10.28,26.77,11.01,37.75,1.57,2.45-2.11,4.66-4.49,6.98-6.75,3.71-3.61,8.28-3.69,11.93-.13,3.68,3.6,7.33,7.23,10.91,10.92,3.02,3.11,3.41,7.54.68,10.61-8.56,9.62-18.37,17.41-30.96,21.29C41.42,212.63,3.71,187.61.29,150.32c-1.69-18.42,3.87-34.43,16.74-47.7,9.88-10.19,19.91-20.25,30.14-30.09,11.33-10.91,25.16-16,39.97-16.31Z"
      />
      <path
        class="cls-1"
        d="m116.46,147.95c-14.29-.25-26.68-4.52-37.52-13.16-2.4-1.91-4.78-3.87-7.09-5.89-3.54-3.1-4-7.69-1.07-11.34,3.33-4.14,6.74-8.22,10.22-12.23,3.03-3.48,7.5-3.82,11.15-.97,1.9,1.48,3.7,3.08,5.56,4.61,11.8,9.69,27.5,9.13,38.4-1.54,9.26-9.06,18.41-18.24,27.5-27.47,7.73-7.84,10.54-17.27,7.44-27.89-3.02-10.38-10.11-17.03-20.7-19.44-9.9-2.25-18.53.58-25.85,7.52-1.81,1.71-3.51,3.54-5.3,5.27-3.62,3.48-8.19,3.52-11.75.04-3.68-3.6-7.33-7.23-10.92-10.93-3.12-3.21-3.44-7.65-.59-10.8,7.83-8.63,16.58-15.93,27.75-20.06,34.9-12.93,73.84,9.61,79.54,46.26,3.12,20.05-2.48,37.47-16.58,52.02-9.54,9.84-19.27,19.49-29.07,29.06-11.53,11.26-25.55,16.63-41.13,16.93Z"
      />
    </CorrenteStyle>
  );
};

export default CorrenteSVG;
