import instance from "./Api";

export default class ConviteService {
  criaConvite(body, callback) {
    instance
      .post(this.host() + "/api/v1/campaign/call", body)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  listarConvite(parametros, callback) {
    let params = "";
    if (parametros.pageSize) {
      params = params + `&pageSize=${parametros.pageSize}`;
    }
    if (parametros.page || typeof parametros.page === "number") {
      params = params + `&page=${parametros.page}`;
    }
    // eslint-disable-next-line max-len
    const path = `/api/v1/campaign/call?campaign=${parametros.codigo}&enrollment=${parametros.matricula}${params}`;
    instance
      .get(this.host() + path)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
