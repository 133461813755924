import styled from "styled-components";

export const ResetarSenhaStyle = styled.div`
  .margin-top {
    margin-top: 2rem !important;
  }

  .titulo {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
  }
  @media screen and (min-width: 700px) {
    .reset-desktop {
      margin: 0 30vw 0 30vw;
    }
  }
`;
