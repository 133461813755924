import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { useStore, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import TrofeuService from "../../services/TrofeuService";
import { TrofeusStyle } from "./style";
import TrofeuCampanha from "../../comps/trofeu/campanha/TrofeuCampanha";
import Card from "../../comps/trofeu/card/Card";
import WeexModal from "../../comps/weexModais/WeexModal";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";

export default function Trofeus() {
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;
  const campanhaComecou = useSelector((state) => state.campanhaComecou);
  const [processando, setProcessando] = useState(true);
  const [trofeus, setTrofeus] = useState();
  const [erro, setErro] = useState(null);

  const campanhaService = new CampanhaService();

  const trofeuService = new TrofeuService();

  if (!campanhaComecou) {
    navigate("/");
  }
  useEffect(() => {
    const sincronizarCampanha = async () => {
      try {
        await campanhaService.useSincronizarCampanha();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha();
    let temaService = new TemaService(campanhaService.campanhaCorrente());
    temaService.aplicarTema("HOME");

    trofeuService.obterTrofeus((erro, sucesso) => {
      if (erro) {
        setErro({
          titulo: i18n.message("trofeus.erro", "Erro"),
          mensagem: i18n.message("trofeus.erro.obter", "Erro ao obter troféus"),
        });
      } else {
        setTrofeus(sucesso);
      }
      setProcessando(false);
    });

    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbackModal = () => {
    setErro(null);
  };

  const trofeusCardList = () => {
    return (
      <div className="weex-l-trofeu-card-container animations-popIn">
        {trofeus.schedule.map((est, index) => {
          return <Card key={index} dia={est} />;
        })}
      </div>
    );
  };

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      {processando === true ? (
        <WeexSpinner aguarde={i18n.message("geral.aguarde", "Aguarde...")} />
      ) : (
        <TrofeusStyle>
          <section className="weex-g-area-de-interacao-desktop-container-margins">
            <PagesHeader
              title={i18n.message("trofeus.titulo", "TROFÉUS")}
              subtitle={i18n.message(
                "trofeus.subtitulo",
                "Aqui você encontra todas as suas conquistas!",
              )}
            />
            <div className="weex-l-trofeu-agenda-container">
              <TrofeuCampanha url={trofeus.prize} trofeus={trofeus} />
              {trofeusCardList()}
            </div>
          </section>
        </TrofeusStyle>
      )}
    </>
  );
}
