import instance from "./Api";

export default class Auth2FATokenService {
  fAToken(request, callback) {
    instance
      .post(`${this.host()}/api/v1/2faToken`, request)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
