import instance from "./Api";

export default class ParticipanteService {
  host() {
    return process.env.REACT_APP_HOST_API;
  }

  ativarParticipante(codigo, callback) {
    instance
      .get(this.host() + "/api/v1/activateSubscription/" + codigo)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }
}
