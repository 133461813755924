import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { useStore } from "react-redux";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import styled from "styled-components";

const ComoFuncionaStyle = styled.div`
  ul {
    list-style: inside;
  }
`;

function ComoFuncionaAT() {
  const { TipoComponenteEnum, mudarComponentePagina, acerteTempo } =
    useAcerteTempo();

  const i18n = useStore().getState().i18n;

  const onClick = () => {
    mudarComponentePagina(TipoComponenteEnum.apresentacao);
  };

  const textoBtn = i18n.message(
    "dinamica.acerteTempo.btn.apresentacao",
    "Apresentação",
  );

  const botaoIniciar = () => {
    return (
      <>
        <BotaoPrimario
          nome={textoBtn}
          disabled={""}
          ariaDisabled={""}
          ariaHidden={""}
          tabIndex={""}
          ariaLabelMsg={""}
          classeComplementar={""}
          funcao={onClick}
          ariaStatusMessage={""}
        />
      </>
    );
  };

  return (
    <>
      <section className="weex-l-acerteTempo-container weex-l-acerteTempo-container-rowReversed">
        <div className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-container--flexFilhos">
          <ComoFuncionaStyle
            dangerouslySetInnerHTML={{
              __html: acerteTempo?.howItWorks?.helpMessage,
            }}
          ></ComoFuncionaStyle>
          <WeexDesktopMode
            className={
              "weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperior"
            }
          >
            {botaoIniciar()}
          </WeexDesktopMode>
        </div>
        {acerteTempo?.image ? (
          <>
            <WeexDesktopMode
              className={
                "weex-l-acerteTempo-container--flexFilhos weex-l-acerteTempo-posicionamento-imagens"
              }
            >
              <img
                className="image is-2by1 retirar-padding"
                src={acerteTempo?.image}
                alt={i18n.message(
                  "alt.dinamica.acerteTempo.imagemApresentacao",
                  "Imagem de apresentação da dinâmica Hábito Certo",
                )}
              />
            </WeexDesktopMode>
          </>
        ) : (
          ""
        )}
      </section>
      <WeexMobileMode className={"weex-l-acerteTempo-botaoRespiroSuperior"}>
        {botaoIniciar()}
      </WeexMobileMode>
    </>
  );
}

export default ComoFuncionaAT;
