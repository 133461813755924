import React from "react";

const CardAnimado = ({ animation, digit }) => {
  return (
    <div className={`flipCard ${animation}`} aria-hidden="true">
      <span>{digit}</span>
    </div>
  );
};

export default CardAnimado;
