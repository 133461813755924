import styled from "styled-components";

export const CampanhaLogosStyle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }

  strong {
    color: inherit !important;
  }

  .campanha_logosTopo_name__title {
    p {
      color: inherit;
      text-align: inherit;
      font-size: 1.125rem;
      line-height: 1.125rem;
    }

    p .ql-size-large {
      font-size: 1.375rem;
      line-height: 1.875rem;
    }
    p .ql-size-huge {
      font-size: 2rem;
      line-height: 2.5rem;
    }

    span,
    em,
    strong,
    u,
    s {
      color: inherit;
    }
  }

  .campanha_logosTopo_name__subtitle {
    p {
      color: inherit;
      text-align: inherit;
      font-size: 0.75rem;
      line-height: 1.125rem;
    }

    p .ql-size-large {
      font-size: 1rem;
      line-height: 1.375rem;
    }
    p .ql-size-huge {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }
    span,
    em,
    strong,
    u,
    s {
      color: inherit;
    }
  }

  .foreground {
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }} !important;
  }

  .foreground_not_logged {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }} !important;
    text-align: center;
  }

  .campanha.logosTopo {
    width: 100vw;
    min-width: 100vw;
  }

  .campanha_logosTopo {
    text-align: center;
  }

  .campanha_logosTopo_left {
    max-width: 25vw;
    float: left;
  }

  .campanha_logosTopo_right {
    max-width: 25vw;
    float: right;
  }

  .campanha_logosTopo__centro {
    width: 100%;
  }

  .campanha_logosTopo_name {
    margin-top: 0.5em;
    /* text-align: center; */
    /* text-transform: uppercase; */
    /* font-size: 1.728em; */
    /* font-weight: 700; */
  }

  .campanha_logosTopo_subtitle {
    /* text-align: center; */
    /* font-size: 1.382em; */
    /* font-weight: 400;   */
  }

  .campanha_logosTopo_client {
    /* text-align: center; */
    /* font-size: 1.44em; */
    /* text-transform: uppercase; */
  }
`;
