import React, { useState } from "react";
import { useSelector, useStore } from "react-redux";
import Menu from "../menu/Menu";
import SuspendedMenuMobile from "../menu/SuspendedMenuMobile";
import Burguer from "./Burguer";
import NavbarElement from "./NavbarElement";
import CampanhaService from "../../../services/CampanhaService";
import WeexMobileMode from "../../WeexMode/WeexMobileMode";
import WeexDesktopMode from "../../WeexMode/WeexDesktopMode";

function Navbar({
  style,
  styleElement,
  toggle,
  toggleMenu,
  now,
  sair,
  versao,
}) {
  const i18n = useStore().getState().i18n;
  const usuariologado = useSelector((state) => state.usuariologado);
  const campanhaComecou = useSelector((state) => state.campanhaComecou);
  const [isSuspendeMenu, setIsSuspendeMenu] = useState(false);
  const toggleSuspendedMenuMobileTrophy = () => {
    setIsSuspendeMenu(!isSuspendeMenu);
  };

  const campanhaService = new CampanhaService();
  let campanhaCorrente = campanhaService.campanhaCorrente();

  let logoCentro = "";
  let logoCentroAreaLogada = "";
  let logoCentroDesktop = "";
  let logoCentroAreaLogadaDesktop = "";
  let notLogoCentroAreaLogada = "";
  let notLogoCentroAreaLogadaDesktop = "";
  let enableInvite;
  let campaignCode;

  if (campanhaCorrente) {
    campaignCode = campanhaCorrente.code;
    notLogoCentroAreaLogada = !logoCentroAreaLogada;
    notLogoCentroAreaLogadaDesktop = !logoCentroAreaLogadaDesktop;
    logoCentro = campanhaCorrente.logoLeft;
    logoCentroAreaLogada = campanhaCorrente.presentationImage;
    // #region DESKTOP
    logoCentroDesktop = campanhaCorrente.topLeftDesktop;
    logoCentroAreaLogadaDesktop = campanhaCorrente.presentationDesktop;
    enableInvite = campanhaCorrente?.enableInvite;
    // #endregion DESKTOP
  }

  return (
    <>
      <WeexMobileMode asHtmlTag="span" style={{ width: "100%" }}>
        <nav className="weex-l-navbar weex-l-navbar-com-trofeu">
          {!isSuspendeMenu ? (
            <Burguer
              toggle={toggle}
              toggleMenu={toggleMenu}
              altOpen={i18n.message(
                "alt.menu.burguer.mobile.open",
                "Menu suspenso, aberto.",
              )}
              altClose={i18n.message(
                "alt.menu.burguer.mobile.close",
                "Menu suspenso, fechado.",
              )}
            />
          ) : (
            <Burguer
              toggle={toggleSuspendedMenuMobileTrophy}
              toggleMenu={isSuspendeMenu}
              altOpen={i18n.message(
                "alt.menu.burguer.mobile.open",
                "Menu suspenso, aberto.",
              )}
              altClose={i18n.message(
                "alt.menu.burguer.mobile.close",
                "Menu suspenso, fechado.",
              )}
            />
          )}

          {logoCentroAreaLogada ? (
            <NavbarElement
              to={usuariologado ? "/home" : "/"}
              className="campanha_logosTopo__centro"
              src={logoCentroAreaLogada}
              alt={i18n.message(
                "alt.logo.principal.mobile",
                "Logo principal da empresa",
              )}
              style={styleElement}
              srcDesktop={logoCentroAreaLogadaDesktop}
              altDesktop={i18n.message(
                "alt.logo.principal.desktop",
                "Logo principal da empresa",
              )}
            />
          ) : (
            <>
              {logoCentro && notLogoCentroAreaLogada ? (
                <NavbarElement
                  to={usuariologado ? "/home" : "/"}
                  className="campanha_logosTopo__centro"
                  src={logoCentro}
                  alt={i18n.message(
                    "alt.logo.principal.mobile",
                    "Logo principal da empresa",
                  )}
                  srcDesktop={logoCentroDesktop}
                  altDesktop={i18n.message(
                    "alt.logo.principal.desktop",
                    "Logo principal da empresa",
                  )}
                />
              ) : (
                ""
              )}
            </>
          )}
          {campanhaComecou ? (
            <div className="alinhamento">
              <NavbarElement
                to="/trophy"
                className="sala-de-trofeus"
                src="/trofeu_weex3.png"
                alt={i18n.message(
                  "alt.menu.sala.de.trofeus",
                  "Ícone com acesso para a sala de troféus",
                )}
              />
              {enableInvite ? (
                <NavbarElement
                  to={`/invite/${campaignCode}`}
                  className="sala-de-trofeus"
                  src={"/adicionar_participante.png"}
                  srcDesktop={"/adicionar_participante.png"}
                  alt={i18n.message(
                    "alt.convite.afiliados.icone",
                    "Ícone silhueta de usuário com simbola de +",
                  )}
                  altDesktop={i18n.message(
                    "alt.convite.afiliados.icone",
                    "Ícone silhueta de usuário com simbola de +",
                  )}
                  style={styleElement}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="alinhamento">
              <NavbarElement
                to="/home"
                className="sala-de-trofeus sala-de-trofeus__disabled"
                src="/trofeu_weex3.png"
                alt={i18n.message(
                  "alt.menu.sala.de.trofeus",
                  "Ícone com acesso para a sala de troféus",
                )}
              />
              {enableInvite ? (
                <NavbarElement
                  to={`/invite/${campaignCode}`}
                  className="sala-de-trofeus"
                  src={"/adicionar_participante.png"}
                  srcDesktop={"/adicionar_participante.png"}
                  alt={i18n.message(
                    "alt.convite.afiliados.icone",
                    "Ícone silhueta de usuário com simbola de +",
                  )}
                  altDesktop={i18n.message(
                    "alt.convite.afiliados.icone",
                    "Ícone silhueta de usuário com simbola de +",
                  )}
                  style={styleElement}
                />
              ) : (
                ""
              )}
            </div>
          )}

          {toggleMenu === true ? (
            <SuspendedMenuMobile
              now={now}
              toggle={toggle}
              sair={sair}
              versao={versao}
            />
          ) : (
            ""
          )}
        </nav>
      </WeexMobileMode>

      <WeexDesktopMode asHtmlTag="span" style={{ width: "100%" }}>
        <nav className="weex-l-navbar weex-l-navbar-com-trofeu">
          {logoCentroAreaLogadaDesktop ? (
            <NavbarElement
              to={usuariologado ? "/home" : "/"}
              className="campanha_logosTopo__centro"
              src={logoCentroAreaLogada}
              alt={i18n.message(
                "alt.logo.principal.mobile",
                "Logo principal da empresa",
              )}
              style={styleElement}
              srcDesktop={logoCentroAreaLogadaDesktop}
              altDesktop={i18n.message(
                "alt.logo.principal.desktop",
                "Logo principal da empresa",
              )}
            />
          ) : (
            <>
              {logoCentroDesktop && notLogoCentroAreaLogadaDesktop ? (
                <NavbarElement
                  to={usuariologado ? "/home" : "/"}
                  className="campanha_logosTopo__centro"
                  src={logoCentro}
                  alt={i18n.message(
                    "alt.logo.principal.mobile",
                    "Logo principal da empresa",
                  )}
                  srcDesktop={logoCentroDesktop}
                  altDesktop={i18n.message(
                    "alt.logo.principal.desktop",
                    "Logo principal da empresa",
                  )}
                />
              ) : (
                ""
              )}
            </>
          )}
          <Menu now={now} toggle={toggle} sair={sair} versao={versao} />
          {enableInvite ? (
            <NavbarElement
              to={`/invite/${campaignCode}`}
              className="sala-de-trofeus"
              src={"/adicionar_participante.png"}
              srcDesktop={"/adicionar_participante.png"}
              alt={i18n.message(
                "alt.convite.afiliados.icone",
                "Ícone silhueta de usuário com simbola de +",
              )}
              altDesktop={i18n.message(
                "alt.convite.afiliados.icone",
                "Ícone silhueta de usuário com simbola de +",
              )}
              style={styleElement}
            />
          ) : (
            ""
          )}

          {campanhaComecou ? (
            <NavbarElement
              to="/trophy"
              className="sala-de-trofeus"
              src="/trofeu_weex3.png"
              alt={i18n.message(
                "alt.menu.sala.de.trofeus",
                "Ícone com acesso para a sala de troféus",
              )}
            />
          ) : (
            <NavbarElement
              to="/home"
              className="sala-de-trofeus sala-de-trofeus__disabled"
              src="/trofeu_weex3.png"
              alt={i18n.message(
                "alt.menu.sala.de.trofeus",
                "Ícone com acesso para a sala de troféus",
              )}
            />
          )}
        </nav>
      </WeexDesktopMode>
    </>
  );
}

export default Navbar;
