import React from "react";
import styled from "styled-components";

const AfiliadoStyle = styled.svg`
  fill: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  width: 2rem;
  height: 2rem;
  stroke-width: 0px;
`;

const ConvidarAfiliadosSVG = () => {
  return (
    <AfiliadoStyle
      width="23"
      height="24"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 279.5 322.95"
    >
      <path
        class="cls-1"
        d="m92.84,159.19c-5.42-4.85-11.23-9.13-15.92-14.4-22.55-25.35-29.31-54.66-18.23-86.71C69.77,26.06,93.07,6.65,126.56,1.16c48.27-7.91,92.35,25.39,99.06,73.88,6.28,45.37-26.06,89.14-71.23,96.03-8.26,1.26-16.75.96-25.06,1.96-49.48,5.93-83.16,32.35-101.13,78.72-4.6,11.88-6.45,24.4-6.67,37.13-.14,8.33,4.09,12.53,12.56,12.53,34.48,0,68.96,0,103.45.01,1.45,0,2.94-.05,4.35.23,5.28,1.06,8.95,5.95,8.59,11.25-.36,5.28-4.68,9.58-10.05,9.98-.33.03-.67.02-1.01.02-35.94,0-71.88.12-107.81-.07-17.58-.1-31.44-14.5-31.61-32.18-.53-56.7,36.53-110.54,89.67-130.28,1.13-.42,2.25-.85,3.16-1.19Zm46.87-8.26c35.52-.08,64.53-28.9,64.53-64.11,0-36.21-28.8-64.9-65.12-64.86-35.12.04-63.89,29.22-63.87,64.77.03,35.24,29.18,64.28,64.45,64.2Z"
      />
      <path
        class="cls-1"
        d="m209.64,253.05c-5.7,0-11.04,0-16.39,0-7.05,0-14.1.07-21.15-.03-6.22-.09-10.91-4.92-10.82-10.9.09-5.86,4.71-10.5,10.82-10.54,11.19-.09,22.38-.03,33.57-.03,1.2,0,2.41,0,3.97,0,0-2.6,0-4.91,0-7.23,0-9.85-.03-19.69,0-29.54.02-6.81,4.55-11.62,10.8-11.59,6.21.04,10.66,4.88,10.68,11.73.04,11.86.03,23.72.05,35.58,0,.21.11.41.3,1.05,1.06,0,2.25,0,3.43,0,10.97,0,21.94-.02,32.9,0,6.82.02,11.67,4.51,11.68,10.73,0,6.22-4.84,10.74-11.65,10.75-12.08.03-24.15,0-36.71,0,0,1.35,0,2.52,0,3.7,0,10.85.01,21.71,0,32.56-.01,7.16-4.33,12.04-10.66,12.09-6.45.05-10.83-4.88-10.84-12.25-.01-11.86,0-23.71,0-36.1Z"
      />
    </AfiliadoStyle>
  );
};

export default ConvidarAfiliadosSVG;
