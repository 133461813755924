import React from "react";
import styled from "styled-components";

const HabitoStyled = styled.svg`
  fill: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  width: 3rem;
  height: 3rem;
`;

const HabitoSVG = () => {
  return (
    <HabitoStyled
      width="23"
      height="24"
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 429.31"
    >
      <path
        className="cls-1"
        d="m448,207.71c-2.92,4.68-7.23,5.93-12.58,5.9-26.97-.16-53.95-.07-80.92-.07h-4.97c1.47,11.43,2.87,22.32,4.31,33.48,12.29,0,24.52-.03,36.75.01,6.24.02,10.27,3.65,10.13,8.94-.13,5.14-4.04,8.48-10.12,8.5-10.94.04-21.87.01-32.81.01-1.54,0-3.07,0-4.24,0-1.86,11.51-3.6,22.33-5.41,33.57h4.98c15.89,0,31.79-.01,47.68.02,1.89,0,3.82.03,5.65.42,3.85.84,6.5,4.34,6.53,8.24.03,4.07-2.79,7.7-6.83,8.44-1.85.34-3.77.33-5.66.33-17.93.02-35.87.13-53.8-.08-3.69-.04-5.23,1.22-6.59,4.55-41.06,100.56-164.22,140.3-256.53,82.86-6.76-4.21-8.74-8.88-5.81-13.74,3.07-5.1,8.03-5.39,15.2-1.04,35.56,21.57,73.92,28.53,114.64,20.49,102.46-20.23,157.3-129.92,113.01-223.5-46.98-99.26-177.1-122.19-255.54-45.17-36.41,35.75-51.16,80.06-46.42,130.65,2.29,24.4,10.56,46.96,23.68,67.72,3.36,5.31,2.41,10.56-2.11,13.3-4.42,2.68-9.34,1.18-12.61-3.96-11.04-17.34-18.94-36.01-23.2-56.11-1.83-8.63-2.96-17.41-4.41-26.12v-24.45c.52-3.58,1.01-7.16,1.57-10.73,10.29-65.61,45.63-112.31,106.33-139.55,9.61-4.31,19.93-7.03,30.15-10.57,0-6.88,0-14.11,0-21.3-.71-.28-1.25-.5-1.79-.71-14.73-5.62-22.72-18.8-20.12-33.17C118.83,9.96,130.74.12,146.49.05c20.27-.09,40.53-.03,60.8-.02,7.38,0,14.09,2.04,19.85,6.78,17.67,14.56,13.29,42.05-7.98,50.71-1.44.58-3.42,2.17-3.49,3.38-.34,6.51-.16,13.06-.16,19.34.55.42.75.67,1.01.75.83.28,1.66.55,2.51.75,38.37,9.46,69.92,29.68,94.96,60.09,1.86,2.25,3.68,3.14,6.57,3.12,18.81-.12,37.62-.07,56.42-.06,7.1,0,11.29,3.21,11.39,8.65.09,5.48-4.08,8.8-11.13,8.81-9.62.01-19.25,0-28.87,0-6.35,0-12.69,0-19.61,0,.86,1.74,1.44,2.85,1.97,4,3.96,8.56,8.12,17.05,11.74,25.75,1.28,3.08,2.71,4.05,5.99,4.03,29.45-.13,58.9,0,88.35-.15,5.27-.03,9.04,1.59,11.2,6.48,0,1.75,0,3.49,0,5.24ZM207.16,41.92c7.97-.06,13.24-4.8,13.38-11.86.14-7.22-5.22-12.5-13.23-12.54-20.24-.1-40.48-.1-60.72,0-8.01.04-13.58,5.33-13.47,12.4.1,7.08,5.63,11.96,13.76,12,10.05.06,20.09.01,30.14.01,10.05,0,20.09.05,30.14-.02Zm-9.41,17.75c-14,0-27.76,0-41.77,0,0,5.91,0,11.66,0,17.45,13.78,0,27.53,0,41.77,0v-17.45Z"
      />
      <path
        className="cls-1"
        d="m312.43,253.01c-.04,74.52-60.78,135.23-135.47,135.4-74.75.17-135.84-60.7-135.89-135.41-.05-74.82,60.72-135.51,135.68-135.51,74.95,0,135.71,60.69,135.67,135.52Zm-253.86-.22c-.09,65.02,52.93,118.08,118.08,118.15,65.09.08,118.25-52.94,118.28-117.95.03-65.16-52.76-117.93-118.08-118.03-65.24-.1-118.19,52.65-118.28,117.83Z"
      />
      <path
        className="cls-1"
        d="m187.9,277.51c-10.57,4.8-20.68,3.15-29.26-4.56-8.59-7.72-11.13-17.56-7.53-28.57.93-2.84.46-4.36-1.55-6.34-12.67-12.46-25.2-25.06-37.77-37.62-1.23-1.23-2.52-2.45-3.56-3.84-2.38-3.2-2.25-7.54.23-10.51,2.64-3.17,6.09-4.06,9.83-2.62,1.81.7,3.4,2.21,4.82,3.62,12.93,12.82,25.83,25.67,38.66,38.59,1.89,1.9,3.34,2.82,6.31,1.67,5.86-2.28,11.95-1.85,18.01,0,1.38.42,3.69-.06,4.73-1.03,5.64-5.27,10.96-10.87,16.49-16.26,4.25-4.15,9.59-4.4,13.19-.78,3.7,3.72,3.43,8.75-.85,13.12-5.1,5.2-10.22,10.38-15.49,15.41-2.08,1.99-3.19,3.63-1.75,6.68,1.14,2.41,1.23,5.33,1.62,7.25-.5,12.33-5.59,21-16.13,25.79Zm-20.78-24.62c0,5.31,4.45,9.74,9.72,9.69,5.12-.06,9.41-4.29,9.54-9.43.13-5.39-4.08-9.73-9.49-9.79-5.43-.06-9.76,4.16-9.76,9.53Z"
      />
      <path
        className="cls-1"
        d="m167.96,163.46c0,1.45-.07,2.9.02,4.34.3,4.83,3.66,8.25,8.23,8.47,4.7.22,8.67-3.14,9.11-8.05.27-3.02.25-6.09.02-9.11-.38-4.98-4.16-8.33-8.92-8.23-4.8.1-8.19,3.56-8.46,8.67-.07,1.3-.01,2.6,0,3.91Z"
      />
      <path
        className="cls-1"
        d="m87.15,261.96c2.15-.2,4.38-.13,6.45-.66,4.3-1.1,6.65-4.73,6.28-9.16-.35-4.22-3.55-7.44-7.97-7.74-2.74-.19-5.51-.13-8.26-.05-5.3.14-9.07,3.65-9.2,8.44-.13,4.75,3.58,8.57,8.77,8.96,1.3.1,2.61.01,3.91.01,0,.06.01.13.02.19Z"
      />
      <path
        className="cls-1"
        d="m167.81,342.26c.29,2.28.28,4.64.93,6.81,1.2,4,5.11,6.33,9.09,5.83,4.07-.52,7.29-3.61,7.62-7.7.24-3.02.19-6.08.06-9.11-.21-4.96-3.87-8.55-8.56-8.61-4.51-.05-8.23,3.25-8.79,7.98-.19,1.57-.03,3.18-.03,4.77-.11.01-.22.02-.32.03Z"
      />
      <path
        className="cls-1"
        d="m265.88,244.04c-1.6.07-3.22.04-4.8.24-4.24.53-7.19,3.67-7.47,7.8-.3,4.44,2.18,8.31,6.52,8.98,3.7.57,7.57.58,11.3.22,4.59-.44,7.7-4.33,7.62-8.65-.09-4.55-3.49-8.03-8.36-8.43-1.59-.13-3.2-.02-4.81-.02,0-.05,0-.1,0-.14Z"
      />
    </HabitoStyled>
  );
};

export default HabitoSVG;
