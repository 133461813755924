import activitiesConfig from "../comps/config/activities.json";
export default class PrepararAgenda {
  /** Adiciona todas as informações necessárias para que o participante visualize a agenda */
  prepareSchedule(
    agenda,
    checkStartDaCampanha,
    flow,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    godModeEnabled,
    navegar,
  ) {
    for (let i = 0; i < agenda.schedule.length; i++) {
      const agendaDoDia = agenda.schedule[i];
      agendaDoDia.activitiesCompletedTotal = 0;
      agendaDoDia.activityTotal = 0;
      let activitiesWithoutGames = [];
      let activitiesWithSubtitlesNoGames = [];
      let games = [];
      const subtitles = [...agendaDoDia?.subtitles];
      const hasSubtitles = agendaDoDia?.subtitles?.length > 0;
      let diaEnabled = checkStartDaCampanha?.some(
        (diaHabilitado) =>
          diaHabilitado === agendaDoDia?.day ||
          (agendaDoDia.extendedSchedule === true &&
            diaHabilitado === "AGENDA_PROLONGADA"),
      );
      agendaDoDia.hasSubtitles = hasSubtitles;
      agendaDoDia.enabled = godModeEnabled || diaEnabled;

      let atvGames = agendaDoDia.activities.filter(
        (act) => act.type === "GAME",
      );
      this.prepareGames(
        atvGames,
        diaEnabled,
        godModeEnabled,
        possuiIconeCustomizado,
        iconeCustomizado,
        i18n,
        agendaDoDia,
        navegar,
        games,
      );

      if (hasSubtitles) {
        this.prepareSubtitles(
          subtitles,
          diaEnabled,
          godModeEnabled,
          flow,
          possuiIconeCustomizado,
          iconeCustomizado,
          i18n,
          agendaDoDia,
          navegar,
          activitiesWithoutGames,
          activitiesWithSubtitlesNoGames,
          games,
        );
      } else {
        let atividadesSemGames = agendaDoDia.activities.filter(
          (act) => act.type !== "GAME",
        );
        this.activitiesNoGames(
          atividadesSemGames,
          diaEnabled,
          godModeEnabled,
          hasSubtitles,
          flow,
          possuiIconeCustomizado,
          iconeCustomizado,
          i18n,
          agendaDoDia,
          navegar,
          activitiesWithoutGames,
        );
      }

      const completionPercentage =
        (agendaDoDia.activitiesCompletedTotal * 100) /
        agendaDoDia.activityTotal;

      const dayCompleted =
        agendaDoDia.activitiesCompletedTotal ===
          activitiesWithoutGames.length &&
        agendaDoDia.activitiesCompletedTotal !== 0 &&
        activitiesWithoutGames.length !== 0
          ? true
          : false;

      agendaDoDia.activitiesWithoutGames = activitiesWithoutGames;
      agendaDoDia.activitiesWithSubtitlesNoGames =
        activitiesWithSubtitlesNoGames;
      agendaDoDia.games = games;
      agendaDoDia.completionPercentage = completionPercentage;
      agendaDoDia.dayCompleted = dayCompleted;

      // Preparo da agenda quando for linear
      if (diaEnabled && flow === "LINEAR" && !godModeEnabled) {
        const isFirstSchedule = i === 0;
        const isPreviousScheduleExecuted = agenda.schedule[i - 1]?.dayCompleted;
        agendaDoDia.enabled = isFirstSchedule || isPreviousScheduleExecuted;
        if (!isFirstSchedule && !isPreviousScheduleExecuted) {
          // eslint-disable-next-line array-callback-return
          agendaDoDia.activities.map((atv) => {
            atv.enabled = false;
          });
        }
      }
    }
  }

  /** Monta uma array de subtitulos com as atividades correspondentes */
  prepareSubtitles(
    subtitles,
    diaEnabled,
    godModeEnabled,
    flow,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    agendaDoDia,
    navegar,
    activitiesWithoutGames,
    activitiesWithSubtitlesNoGames,
    games,
  ) {
    let allPreviousSubtitlesCompleted = true;

    for (let k = 0; k < subtitles.length; k++) {
      const subtitle = subtitles[k];
      let subtitleActivities = agendaDoDia.activities.filter(
        (activity) => activity.subtitle.uuid === subtitle.uuid,
      );

      let atividadesSemGames = subtitleActivities.filter(
        (act) => act.type !== "GAME",
      );

      this.prepareActivitiesBySubtitle(
        atividadesSemGames,
        diaEnabled,
        godModeEnabled,
        flow,
        possuiIconeCustomizado,
        iconeCustomizado,
        i18n,
        agendaDoDia,
        navegar,
        activitiesWithoutGames,
        allPreviousSubtitlesCompleted,
      );

      let subtitleCompleted = atividadesSemGames.every(
        (activity) => activity.executed,
      );

      allPreviousSubtitlesCompleted =
        allPreviousSubtitlesCompleted && subtitleCompleted;

      activitiesWithSubtitlesNoGames.push({
        subtitle: subtitle,
        activities: atividadesSemGames,
      });
    }
  }

  /** Prepara as atividades que possuem subtitulos */
  prepareActivitiesBySubtitle(
    atividades,
    diaEnabled,
    godModeEnabled,
    flow,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    agendaDoDia,
    navegar,
    activitiesWithoutGames,
    allPreviousSubtitlesCompleted,
  ) {
    for (let j = 0; j < atividades.length; j++) {
      const atividade = atividades[j];
      atividade.enabled = godModeEnabled || diaEnabled;

      // Preparo da atividade quando for linear
      if (diaEnabled && flow === "LINEAR" && !godModeEnabled) {
        atividade.enabled = diaEnabled && allPreviousSubtitlesCompleted;
        const isFirstActivity = j === 0;
        const isPreviousActivityExecuted = atividades[j - 1]?.executed;
        atividade.enabled = isFirstActivity
          ? allPreviousSubtitlesCompleted
          : isPreviousActivityExecuted;
      }

      this.prepareActivity(
        atividade,
        possuiIconeCustomizado,
        iconeCustomizado,
        i18n,
        agendaDoDia,
        navegar,
      );
      agendaDoDia.activityTotal++;
      if (atividade.executed === true) agendaDoDia.activitiesCompletedTotal++;
      activitiesWithoutGames.push(atividade);
    }
  }

  /** Prepara as atividades sem games e sem subtitulos */
  activitiesNoGames(
    atividades,
    diaEnabled,
    godModeEnabled,
    hasSubtitles,
    flow,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    agendaDoDia,
    navegar,
    activitiesWithoutGames,
  ) {
    for (let j = 0; j < atividades.length; j++) {
      const atividade = atividades[j];
      atividade.enabled = godModeEnabled || diaEnabled;

      // preparo da atividade quando for linear
      if (diaEnabled && flow === "LINEAR" && !hasSubtitles && !godModeEnabled) {
        const isFirstActivity = j === 0;
        const isPreviousActivityExecuted = atividades[j - 1]?.executed;
        atividade.enabled = isFirstActivity || isPreviousActivityExecuted;
      }

      this.prepareActivity(
        atividade,
        possuiIconeCustomizado,
        iconeCustomizado,
        i18n,
        agendaDoDia,
        navegar,
      );
      agendaDoDia.activityTotal++;
      if (atividade.executed === true) agendaDoDia.activitiesCompletedTotal++;
      activitiesWithoutGames.push(atividade);
    }
  }

  /** Adiciona informações comuns a todos os tipos de atividades na agenda */
  prepareActivity(
    atividade,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    agendaDoDia,
    navegar,
  ) {
    let atvWithIcon = possuiIconeCustomizado
      ? iconeCustomizado.find((a) => a.type === atividade.type)
      : activitiesConfig.find((a) => a.type === atividade.type);

    if (!atvWithIcon) return;

    atividade.key = `${atividade.type}_${atividade.uuid}`;
    atividade.route = atvWithIcon.route;
    atividade.disabledIcon = atvWithIcon.disabledIcon;
    atividade.enabledIcon = atvWithIcon.enabledIcon;
    atividade.imageAlt = atvWithIcon.imageAlt;
    atividade.size = { width: 100 / agendaDoDia.activities.length + "%" };
    atividade.nome = i18n.getNomeDasAtividades(
      atividade?.type,
      atividade?.name,
    );

    atividade.navegar = () => {
      navegar(
        atividade.route,
        agendaDoDia?.scheduleCode,
        atividade.uuid,
        atividade.trophyEnabled,
        atividade,
        agendaDoDia?.nome,
        atividade.showPresentation,
      );
    };
  }

  /** Prepara as informações relacionadas aos games */
  prepareGames(
    atvGames,
    diaEnabled,
    godModeEnabled,
    possuiIconeCustomizado,
    iconeCustomizado,
    i18n,
    agendaDoDia,
    navegar,
    games,
  ) {
    for (let j = 0; j < atvGames.length; j++) {
      const atividade = atvGames[j];
      atividade.enabled = godModeEnabled || diaEnabled;

      this.prepareActivity(
        atividade,
        possuiIconeCustomizado,
        iconeCustomizado,
        i18n,
        agendaDoDia,
        navegar,
      );

      if (atividade.type === "GAME") {
        games.push(atividade);
      }
    }
  }
}
