import React from "react";
import { useStore, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";
import InfosTextuais from "../../LogosCampanha/InfosTextuais";
import { Section, Div, Paragraph } from "./menuStyle";

/**
 * @param {Object} props
 * @param {string} props.versao
 * @param {function(): void} props.toggle
 * @param {function(): void} props.sair
 * @param {Date} props.now
 */
function Menu({ now, toggle, sair, versao }) {
  const i18n = useStore().getState().i18n;

  const usuariologado = useSelector((state) => state.usuariologado);

  let location = useLocation();

  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const acceptLegalTerm = !!usuariologado?.legalTerm;
  const inicio = usuariologado ? "/home" : "/";
  const locationPathname = location?.pathname;
  const naoMostrarInfoParticipante =
    campanhaService.naoMostrarInfoParticipante();
  const infoParticipante = campanhaService.getInformacaoParticipante();
  const beginDate = campanhaService.getBeginDate();
  let tipoInscricao;
  let legalTerm;
  let requiredInterests;
  let comoFunciona;
  if (campanhaCorrente) {
    tipoInscricao = campanhaCorrente.tipoInscricao;
    legalTerm = campanhaCorrente.legalTerm;
    requiredInterests = campanhaCorrente.requiredInterests;
    comoFunciona = campanhaCorrente.comoFunciona;
  }

  return (
    <>
      <Div>
        <InfosTextuais />
      </Div>
      {naoMostrarInfoParticipante ? (
        ""
      ) : (
        <Paragraph style={{ pointerEvents: "none", fontSize: "0.75em" }}>
          {`${i18n.message(
            "geral.menu.usuario",
            "Participante",
          )} : ${infoParticipante}`}
        </Paragraph>
      )}
      {(tipoInscricao === "ELEGIBILIDADE" && legalTerm && !acceptLegalTerm) ||
      (requiredInterests === true &&
        locationPathname === "/interests" &&
        beginDate <= now) ? (
        ""
      ) : (
        <Section>
          <Link to={inicio} onClick={toggle}>
            {i18n.message("geral.menu.inicio", "Início")}
          </Link>
        </Section>
      )}
      {comoFunciona && comoFunciona.helpMessage ? (
        <Section>
          <Link to="/help" onClick={toggle}>
            {i18n.message("geral.menu.comoFunciona", "Como funciona")}
          </Link>
        </Section>
      ) : (
        ""
      )}
      <Section>
        <button className="out button-link" type="button" onClick={sair}>
          {i18n.message("geral.menu.sair", "Sair")}
        </button>
      </Section>
      <Paragraph className="versao">{`v. ${versao}`}</Paragraph>
    </>
  );
}

export default Menu;
