import React from "react";
import { useStore } from "react-redux";
import { WeexCheckExecutadoStyle } from "./style";

/**
 * @param {Object} props
 * @param {string} props.executado
 * @param {string} props.top
 * @param {string} props.left
 * @param {string} props.right
 * @param {string} props.bottom
 * @param {function(React.ChangeEventHandler<HTMLTextAreaElement): void} props.onChange
 */
function WeexCheckExecutado({ executado, top, left, right, bottom }) {
  const i18n = useStore().getState().i18n;

  const altImageAtividade = executado
    ? i18n.message("alt.atividade.completou", "completou")
    : i18n.message("alt.atividade.nao.completou", "Não completou");
  return (
    <WeexCheckExecutadoStyle
      $executado={executado}
      top={top || 0}
      left={left || 0}
      right={right || 0}
      bottom={bottom || 0}
    >
      <img src="/positivo_tres.svg" alt={altImageAtividade}></img>
    </WeexCheckExecutadoStyle>
  );
}

export default WeexCheckExecutado;
