import styled from "styled-components";

export const ConvitesContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    max-height: 61vh;
    overflow: auto;
    padding-right: 1rem;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => {
        return props.theme.fundoSecundario;
      }};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb&:hover {
      background: ${(props) => {
        return props.theme.fundoSecundario || `#533888`;
      }};
    }
  }
  /* #endregion DESKTOP */
`;

export const CardConviteStyle = styled.div`
  background-color: #ffff;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 20px 40px -10px rgb(0 0 0 / 8%);

  .weex-l-cardConvite__matricula {
    font-size: 0.8rem;
  }

  .weex-l-cardConvite__matricula--respiroLateral {
    margin-right: 1rem;
  }

  .weex-l-cardConvite__confirmado {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-top: 0.2rem;
    padding-left: 1rem;
    padding-bottom: 0.2rem;
    padding-right: 1rem;
    border-radius: 2rem;
    background-color: #00e291;
    color: #4a4a4a;
    font-weight: 700;
  }

  .weex-l-cardConvite__confirmado--icone {
    color: #ffffff;
  }

  .weex-l-cardConvite__confirmado--texto {
    font-size: 0.7rem;
    letter-spacing: 0.01rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 48%;
    box-sizing: border-box;
    padding: 1.5rem;

    .weex-l-cardConvite__matricula {
      font-size: 0.9rem;
    }

    .weex-l-cardConvite__confirmado--texto {
      text-align: center;
      font-size: 0.8rem;
    }
  }
  /* #endregion DESKTOP */
`;

export const PaginacaoContainerStyle = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .weex-l-convite__paginacao--paginas {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
