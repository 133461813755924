import React from "react";
import { useStore } from "react-redux";
import AgendaHeader from "../AgendaHeader/AgendaHeader";
import DataAgenda from "../CardzinhoDataDaAgenda/DataAgenda";
import AtividadesDoDia from "../AtividadesDoDia/AtividadesDoDia";
import { CardDiaSemanaAgendaStyle } from "./style";
import CampanhaService from "../../../services/CampanhaService";

function DiaSemanaAgenda({ agendaDoDia }) {
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";

  const cssMarginLeftWithoutDate =
    !shouldShowScheduleDate && !agendaDoDia?.extendedSchedule
      ? "weex-l-card-header-container weex-l-card-header-container--noDate"
      : "weex-l-card-header-container";
  const i18n = useStore().getState().i18n;

  const atividades = agendaDoDia?.activities;
  const numeroItensPorLinha = 4;
  const nomeInteresse =
    agendaDoDia?.interest != null ? agendaDoDia?.interest?.name : "";

  let dataTextual = agendaDoDia?.day?.replace("00:00:00", "").replace(" ", "");
  const componentesData = dataTextual?.split("-");
  dataTextual = componentesData[2];
  const dayOfWeek = agendaDoDia?.dayOfWeek;
  const dayOfWeekComplete = i18n.getNomeDiaSemana(dayOfWeek);
  const mesTextualVisivel = i18n.getNomeMesAbreviado(componentesData[1]);
  const mesTextualLeitorTela = i18n.getNomeMesInteiro(componentesData[1]);

  const minSpanSize = () => {
    let length = atividades.length;
    if (!length || length < 4) {
      let estilos = { display: "flex", flexDirection: "column" };
      if (length === 0) {
        estilos.marginBottom = "1vh";
      }
      return estilos;
    } else {
      return {};
    }
  };

  return (
    <CardDiaSemanaAgendaStyle
      style={minSpanSize()}
      $habilitado={agendaDoDia.enabled}
    >
      <div className={cssMarginLeftWithoutDate}>
        <DataAgenda
          habilitado={agendaDoDia.enabled ? true : false}
          dayCompleted={agendaDoDia.dayCompleted ? true : false}
          diaTextual={dataTextual}
          mesTextualVisivel={mesTextualVisivel}
          mesTextualLeitorTela={mesTextualLeitorTela}
          isAgendaProlongada={agendaDoDia?.extendedSchedule}
        />
        <AgendaHeader
          habilitado={agendaDoDia.enabled}
          nome={agendaDoDia?.nome}
          nomeInteresse={nomeInteresse}
          dayOfWeekComplete={dayOfWeekComplete}
          isAgendaProlongada={agendaDoDia?.extendedSchedule}
          completionPercentage={
            isNaN(agendaDoDia.completionPercentage.toFixed(0))
              ? 0
              : agendaDoDia.completionPercentage.toFixed(0)
          }
        />
      </div>
      <AtividadesDoDia
        numeroItensPorLinha={numeroItensPorLinha}
        hasSubtitles={agendaDoDia?.hasSubtitles}
        activitiesWithSubtitlesNoGames={
          agendaDoDia?.activitiesWithSubtitlesNoGames
        }
        atividadesSemGameFinal={agendaDoDia.activitiesWithoutGames}
        gamesFinal={agendaDoDia.games}
      />
    </CardDiaSemanaAgendaStyle>
  );
}

export default DiaSemanaAgenda;
