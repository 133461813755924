import React from "react";
import { useStore } from "react-redux";

/**
 * @deprecated na branch zoom ele será deletado
 */
export default function WeexMessage(props) {
  let valorDefault = props.default;
  if (!valorDefault) {
    valorDefault = "";
  }

  const store = useStore();
  let service = store.getState().i18n;

  let code = props.code;
  let message = service.message(code);
  if (!message) {
    message = valorDefault;
  }
  return <span className="i18n">{message}</span>;
}
