import styled from "styled-components";

export const GameStyled = styled.div`
  .info {
    width: 100%;
    text-align: center;
  }
  .info-game {
    margin-bottom: 2em;
  }
`;
