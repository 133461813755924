import React, { useEffect, useState } from "react";
import WeexSelect from "../../WeexInputs/WeexSelect";

/**
 *
 * @param {Object} props
 * @param {import('../../../services/CampanhaService').Event} props.campanha
 * @param {i18n} props.i18n
 * @param {Function} props.onChange
 * @returns
 */

function GerarSelectUnidadesTree({ campanha, onChange, i18n }) {
  const [selectsUnidades, setSelectsUnidades] = useState([]);
  const [unidadeMae, setUnidadeMae] = useState(null);
  const [subareas, setSubareas] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);

  useEffect(() => {
    const inputUnidades = () => {
      if (campanha?.client?.units) {
        let unidades = campanha.client.units;
        if (unidades && unidades.length > 0) {
          return unidades;
        }
        return null;
      }
    };
    let unidades = inputUnidades();

    if (unidades) {
      setSelectsUnidades(unidades);
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputUnidade = () => {
    if (selectsUnidades && selectsUnidades.length > 0) {
      return (
        <WeexSelect
          id="selectUnit"
          value={unidadeMae}
          name="unit"
          onChange={(e) => mudarUnidade(e, 0)}
          label={i18n.message("registro.campo.area", "Selecione sua área")}
          required={false}
        >
          <option value="">
            {i18n.message("select.unidade", "Selecione uma unidade")}
          </option>
          {selectsUnidades.map((opcao) => {
            return (
              <option value={opcao.uuid} key={opcao.uuid}>
                {opcao.name}
              </option>
            );
          })}
        </WeexSelect>
      );
    }
    return null;
  };

  const inputSubUnidade = () => {
    const unidade = selectsUnidades.find(
      (unidade) => unidade.uuid === unidadeMae,
    );
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }

    return (
      <WeexSelect
        id="selectSubUnit"
        value={subareas}
        name="subUnit"
        onChange={(e) => mudarUnidade(e, 1)}
        label={i18n.message("registro.campo.subarea", "Selecione sua subárea")}
      >
        <option value="">
          {i18n.message("select.subárea", "Selecione uma subárea")}
        </option>
        {unidade.subUnits.map((opcao) => {
          return (
            <option value={opcao.uuid} key={opcao.uuid}>
              {opcao.name}
            </option>
          );
        })}
      </WeexSelect>
    );
  };

  const inputLevel3 = () => {
    const unidade = selectsUnidades.find(
      (unidade) => unidade.uuid === unidadeMae,
    );
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    const subUnidade = unidade.subUnits.find(
      (subUnidade) => subUnidade.uuid === subareas,
    );
    if (
      !subUnidade ||
      !subUnidade.subUnits ||
      subUnidade.subUnits.length === 0
    ) {
      return null;
    }

    return (
      <WeexSelect
        id="nivel3"
        value={subSubUnit}
        name="level3"
        onChange={(e) => mudarUnidade(e, 2)}
        label={i18n.message(
          "registro.campo.nivel3",
          "Selecione sua sub-subárea",
        )}
      >
        <option value="">
          {i18n.message("select.subsubunidade", "Selecione uma sub-subunidade")}
        </option>
        {subUnidade.subUnits.map((opcao) => {
          return (
            <option value={opcao.uuid} key={opcao.uuid}>
              {opcao.name}
            </option>
          );
        })}
      </WeexSelect>
    );
  };

  const mudarUnidade = (event, nivel) => {
    event.preventDefault();
    const value = event.target.value;
    switch (nivel) {
      case 0:
        setUnidadeMae(value);
        setSubareas(null);
        setSubSubUnit(null);
        onChange(event);
        break;
      case 1:
        setSubareas(value);
        setSubSubUnit(null);
        onChange(event);
        break;
      case 2:
        setSubSubUnit(value);
        onChange(event);
        break;
      default:
        console.error("Nível desconhecido");
        break;
    }
  };

  return (
    <>
      <fieldset className="registerForm__fieldset">
        {inputUnidade()}
        {inputSubUnidade()}
      </fieldset>
      {inputLevel3()}
    </>
  );
}

export default GerarSelectUnidadesTree;
