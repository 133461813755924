import React from "react";
import { useStore } from "react-redux";
import { ContadorRegressivoStyle } from "./contadorRegressivoStyle";

function ContadorRegressivoRevisaoAT({
  tempoEmSegundos,
  tempoParticipanteRespondeu,
}) {
  const tempoInicial = tempoEmSegundos;
  const contador = tempoParticipanteRespondeu
    ? tempoInicial - tempoParticipanteRespondeu
    : 0;
  const i18n = useStore().getState().i18n;
  const barraEstilo = {
    width: `${(contador / tempoInicial) * 75}%`,
  };

  return (
    <>
      <span className="weex-g-visually-hidden">
        {`${contador} ${i18n.message(
          "alt.dinamica.acerteTempo.contadorSegundos",
          "segundos",
        )}`}
      </span>
      <ContadorRegressivoStyle>
        <div className="weex-l-barra-regresso-container">
          <div className="weex-l-barra-regresso-circle weex-l-barra-regresso-circle--segundos">
            <p className="weex-l-barra-regresso-circle--contagem">{contador}</p>
            <p className="weex-l-barra-regresso-circle--textoSegundos">
              {i18n.message("dinamica.acerteTempo.contadorSegundos", "seg")}
            </p>
          </div>

          <div className="weex-l-barra-regresso" style={barraEstilo}></div>

          <div className="weex-l-barra-regresso-circle">
            <img
              src="/cronometro.svg"
              alt={i18n.message(
                "alt.dinamica.acerteTempo.cronometro",
                "Ícone de um cronometro",
              )}
              className="weex-l-barra-regresso-circle--img"
            />
          </div>
        </div>
      </ContadorRegressivoStyle>
    </>
  );
}

export default ContadorRegressivoRevisaoAT;
