import React, { useState } from "react";
import PasswordService from "../../services/PasswordService";
import { WeexInput, PasswordStrengthBar } from "./style";

/**
 * Creates a password input with strong options.
 * @param {string} name - name of the input
 * @param {string} id - unique id of the input
 * @param {string} placeholder - placeholder text for the input
 * @param {string} label - label text for the input
 * @param {boolean} required - whether the input is required or not. default is true.
 * @param {boolean} disabled - whether the input is disabled or not. default is false.
 * @param {function} onChange - function to be called when the input value changes.
 * @param {string} iconposition - position of the icon. can be "left" or "right". default is "right".
 * @param {boolean} strong - whether to behave as a strong password input. if true, will show the password strength indicator. default is true.
 * @returns {JSX.Element} - a WeexInput component with a password strength bar.
 */
function WeexPassword({
  key,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  iconposition = "right",
  strong = true,
  ariaLabel,
}) {
  const className =
    iconposition === "right"
      ? "weexInput__icon weexInput__icon--right passwordStrengthBar__icon"
      : "weexInput__icon passwordStrengthBar__icon";

  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [passwordLabel, setPasswordLabel] = useState("Muito curta");
  const [color, setColor] = useState("#E0E0DF");
  const [percentage, setPercentage] = useState(0);

  const handlePasswordChange = (e) => {
    onChange(e);
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculatePasswordStrength(newPassword);
  };

  const calculatePasswordStrength = (password) => {
    const passwordService = new PasswordService();
    const passwordStrength =
      passwordService.calculatePasswordStrength(password);
    const { passwordLabel, color, percentage } = getPasswordStrengthBarInfo(
      password,
      passwordStrength,
    );
    setPasswordLabel(passwordLabel);
    setColor(color);
    setPercentage(percentage);
  };

  const getPasswordStrengthBarInfo = (password, passwordStrength) => {
    if (password.length < 8) {
      return {
        passwordLabel: "Muito curta",
        color: "#E0E0DF",
        percentage: 0,
      };
    }

    let passwordLabel = "Fraca";
    let color = "#8B2230";
    let percentage = 25;

    if (passwordStrength >= 50) {
      passwordLabel = "Moderada";
      color = "#F1C74F";
      percentage = 50;
    }
    if (passwordStrength >= 75) {
      passwordLabel = "Boa";
      color = "#799DBF";
      percentage = 75;
    }
    if (passwordStrength === 100) {
      passwordLabel = "Forte";
      color = "#137512";
      percentage = 100;
    }

    return {
      passwordLabel,
      color,
      percentage,
    };
  };

  const handleTogglePasswordType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <>
      <WeexInput
        className="control"
        $strong={strong}
        $isright={iconposition === "right" ? "true" : "false"}
        key={key}
      >
        <label className="label" htmlFor={id} aria-label={ariaLabel}>
          {label ? label : <span style={{ opacity: 0 }}>label</span>}
          <input
            className="input input-size is-medium"
            id={id}
            type={passwordType}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onChange={strong ? handlePasswordChange : onChange}
            $isright={iconposition === "right" ? "true" : "false"}
          />
          {/* eslint-disable-next-line max-len */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div className={`${className}`} onClick={handleTogglePasswordType}>
            {passwordType === "text" ? (
              // <span class="material-symbols-outlined">visibility_off</span>
              <span class="material-symbols-outlined"></span>
            ) : (
              // <span class="material-symbols-outlined">visibility</span>
              <span class="material-symbols-outlined"></span>
            )}
          </div>
          {strong && (
            <PasswordStrengthBar
              isright={iconposition === "right" ? "true" : "false"}
            >
              <div
                className="passwordStrengthBar__fill"
                style={{
                  width: `${percentage}%`,
                  backgroundColor: color,
                }}
              />
              <span className="strongSpan">
                Força da senha:{" "}
                <strong>{password.length > 0 ? passwordLabel : ""}</strong>
              </span>
            </PasswordStrengthBar>
          )}
        </label>
      </WeexInput>
    </>
  );
}

export default WeexPassword;
