import React from "react";

const RankingCampanha = (props) => {
  return (
    <svg
      width="28"
      height="23"
      id="Camada_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 457.07 375.01"
    >
      <path d="m0,363.31c2.62-4.85,6.64-6.79,12.14-6.31,3.51.31,7.07.06,11.07.06,0-1.97,0-3.56,0-5.15,0-36,0-72.01,0-108.01,0-8.89,2.9-11.82,11.7-11.82,37.79,0,75.58,0,113.37,0h5.94c0-1.87,0-3.45,0-5.03,0-12.65-.04-25.29.02-37.94.03-7.44,3.19-10.59,10.62-10.59,42.4-.02,84.8-.02,127.21,0,7.68,0,10.78,3.1,10.79,10.87.03,30.2.01,60.4.01,90.61,0,1.74,0,3.48,0,5.65h5.77c37.34,0,74.69,0,112.03,0,10.94,0,13.22,2.32,13.22,13.44,0,17.56,0,35.11,0,52.67,0,1.6,0,3.21,0,5.32,4.46,0,8.58-.03,12.7,0,6.44.06,10.53,3.59,10.48,9-.06,5.4-4.12,8.84-10.64,8.84-49.84.02-99.68,0-149.52,0-94.92,0-189.84-.04-284.76.09-5.48,0-9.51-1.5-12.14-6.34v-5.36Zm172.28-6.49h112.46v-160.14h-112.46v160.14Zm-18.39.11v-106.73H41.37v106.73h112.52Zm261.98-53.17h-112.66v53.05h112.66v-53.05Z" />
      <path d="m303.54,57c-1.45,2.3-2.57,4.91-4.4,6.84-8,8.4-16.12,16.69-24.41,24.8-2.31,2.26-2.9,4.43-2.4,7.47,1.76,10.7,3.33,21.43,4.97,32.16.18,1.17.36,2.35.44,3.54.55,8.25-5.95,12.78-13.48,9.15-10.43-5.04-20.83-10.17-31.04-15.63-3.46-1.85-6.07-1.6-9.38.14-9.73,5.1-19.67,9.81-29.45,14.81-3.8,1.94-7.51,3.01-11.34.42-4.1-2.77-4.1-6.92-3.43-11.21,1.66-10.72,3.2-21.46,5.04-32.14.66-3.82.06-6.58-2.93-9.44-8.16-7.81-15.87-16.09-23.89-24.06-3.03-3.01-4.45-6.36-3.05-10.45,1.36-3.98,4.67-5.47,8.6-6.09,11.45-1.8,22.86-3.78,34.32-5.51,3.11-.47,4.86-1.87,6.26-4.71,5.08-10.24,10.51-20.32,15.7-30.51C221.53,2.91,223.93.08,228.37,0c4.65-.08,7.17,2.82,9.12,6.65,5.12,10.06,10.51,19.99,15.46,30.13,1.57,3.21,3.68,4.52,7.01,5.03,11.31,1.73,22.59,3.64,33.87,5.52,6,1,9.05,4.17,9.71,9.67Zm-121.14,5.38c-.31.47-.61.95-.92,1.42,5.75,5.36,11.38,10.84,17.28,16.03,4.41,3.88,5.51,8.34,4.42,14-1.46,7.55-2.34,15.21-3.62,23.81,6-2.97,11.48-5,16.21-8.17,8.69-5.82,16.75-6,25.49-.09,4.72,3.19,10.23,5.21,16.28,8.2-1.04-6.87-1.48-12.95-2.95-18.78-2.3-9.14-.67-16.39,7.08-22.46,4.9-3.84,8.88-8.86,13.78-13.87-9.25-1.56-17.57-3.1-25.94-4.34-4.25-.63-7-2.75-8.88-6.52-3.32-6.65-6.8-13.21-10.25-19.79-.49-.94-1.2-1.77-2.07-3.03-4.13,7.95-8.04,15.28-11.76,22.71-1.97,3.93-4.86,6.04-9.27,6.68-8.32,1.2-16.59,2.78-24.88,4.19Z" />
      <path d="m219.54,303.15v-52.43c-.24-.27-.38-.58-.61-.65-6.1-1.98-8.93-5.62-8.12-10.48.82-4.89,4.6-7.48,10.99-7.51,4.46-.02,8.93-.02,13.39,0,6.43.02,10.18,2.55,11.06,7.43.89,4.95-2,8.61-8.61,10.74v52.56c.15.17.29.48.51.55,6.1,1.97,8.94,5.62,8.11,10.47-.84,4.89-4.61,7.49-10.99,7.51-4.46.02-8.93.02-13.39,0-6.42-.02-10.18-2.56-11.05-7.44-.89-4.95,2.03-8.63,8.71-10.76Z" />
    </svg>
  );
};

export default RankingCampanha;
