import React from "react";
import { ButtonInteressesEscolhaStyle } from "./style";

function BotaoInteressesEscolha(props) {
  return (
    <ButtonInteressesEscolhaStyle
      onClick={() => props.funcao(props.interesse, "remove")}
    >
      <div>
        <span>{props.nome}</span>
        <span className="icon is-small weex-remover-interesse-content">
          <span className="weex-remove-interesse-icon">
            <svg
              width="8"
              height="8"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 13M1 1L13 13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </span>
      </div>
    </ButtonInteressesEscolhaStyle>
  );
}

export default BotaoInteressesEscolha;
