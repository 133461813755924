import React from "react";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import { MedalhaStyle } from "./style";
import WeexCheckExecutado from "../../WeexCheckExecutado/WeexCheckExecutado";
/**
 * Medalha (também conhecido como: trofeu da atividade)
 * @param {Object} props
 * @param {String} props.url
 * @param {String} props.type
 * @param {Boolean} props.activityAchieved
 * @param {Object} props.size
 */
export default function Medalha({ url, type, activityAchieved, size }) {
  const i18n = useStore().getState().i18n;

  let nomeAtividade = i18n.getNomeDasAtividades(type);

  if (url === null) {
    return <div></div>;
  }

  return (
    <>
      <MedalhaStyle $activityAchieved={activityAchieved} style={size} url={url}>
        <Link to="/atividades">
          <WeexCheckExecutado executado={activityAchieved} top={"0.875rem;"} />
          <div className="weex-l-trofeu-dia-link-as-a-button">
            <div className="weex-l-trofeu-dia-link-as-a-button__icone"></div>
          </div>
          <p className="weex-l-trofeu-texto">{nomeAtividade}</p>
        </Link>
      </MedalhaStyle>
    </>
  );
}
