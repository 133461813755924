import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import UsuarioService from "../../services/UsuarioService";
import { CampanhaLogosStyle } from "./style";
import CampanhaService from "../../services/CampanhaService";
const usuarioService = new UsuarioService();

function InfosTextuais() {
  const usuario = useSelector((state) => state.usuario);
  let usuariologado =
    usuarioService.usuarioCorrente() &&
    usuarioService.usuarioCorrente().token != null
      ? true
      : false;
  let location = useLocation();

  const campanhaService = new CampanhaService();

  let campanhaCorrente = campanhaService.campanhaCorrente();
  let title = "";
  let campanhaName = "";

  if (campanhaCorrente) {
    // #region DESKTOP
    title = campanhaCorrente.title;
    campanhaName = campanhaCorrente.subtitle || campanhaCorrente.name;
    // #endregion DESKTOP
  }

  return (
    <>
      {(location?.pathname === "/atividades" ||
        location?.pathname === "/" ||
        location?.pathname === "/trophy" ||
        location?.pathname === "/interests" ||
        location?.pathname === "/home" ||
        location?.pathname === "/resetPassword" ||
        location?.pathname === "/passwordReset") &&
      usuariologado &&
      usuario === true ? (
        <div className="weex-container">
          <CampanhaLogosStyle>
            <div className="campanha_logosTopo">
              {campanhaName ? (
                <div className="campanha_logosTopo_name foreground">
                  <div
                    className="campanha_logosTopo_name__title"
                    dangerouslySetInnerHTML={{
                      __html: campanhaName,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}
              {title ? (
                <div className="foreground" style={{ marginTop: "10px" }}>
                  <h2>
                    <div
                      className="campanha_logosTopo_name__subtitle"
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    ></div>
                  </h2>
                </div>
              ) : (
                ""
              )}
            </div>
          </CampanhaLogosStyle>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default InfosTextuais;
