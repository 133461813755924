import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSelector } from "react-redux";
import InputSearch from "../WeexInputs/InputSearch";
import Spinner from "../publicacao/Spinner";
import WeexModal from "../weexModais/WeexModal";
import PontuacaoUsuario from "./PontuacaoUsuario";
import PontuacaoUsuarioCorrente from "./PontuacaoUsuarioCorrente";
import { GameRankingsListStyled } from "./styled";

/**
 * Componente responsável por mostrar o ranking de participantes na campanha.
 * É usado tanto para o ranking dos games como para o da competição individual.
 */
function RankingListaParticipantes({
  pontuacaoUsuario,
  rankingFormat,
  usuarioEstaNoRanking,
  search,
  participantePesquisadoNoRanking,
}) {
  const i18n = useSelector((state) => state.i18n);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  let ranking = rankingFormat;

  const [page, setPage] = useState(1);
  const [loading] = useState(false);
  const [error, setError] = useState({
    titulo: "",
    mensagem: "",
    isWrong: false,
  });

  const gerarPaginas = () => {
    if (ranking.length <= 0) {
      return (
        <div className="participante-content msg-sem-resultado">
          <p>
            {i18n.message(
              "rankingCampanha.ranking.no.participants",
              "Não há participantes",
            )}
          </p>
        </div>
      );
    }
    if (
      participantePesquisadoNoRanking !== null &&
      participantePesquisadoNoRanking?.length > 0
    ) {
      return participantePesquisadoNoRanking.map((pontuacaoUsuario, index) => {
        if (index <= page * 10) {
          return (
            <div className="participante-content" key={index}>
              <div className="outros-participante participante">
                <PontuacaoUsuario
                  pontuacaoUsuario={pontuacaoUsuario}
                  isParticipante={false}
                />
              </div>
            </div>
          );
        }
        return null;
      });
    } else {
      return ranking.map((pontuacaoUsuario, index) => {
        if (index <= page * 10) {
          return (
            <div className="participante-content" key={index}>
              <div className="outros-participante participante">
                <PontuacaoUsuario
                  pontuacaoUsuario={pontuacaoUsuario}
                  isParticipante={false}
                />
              </div>
            </div>
          );
        }
        return null;
      });
    }
    // return (
    //   ranking.map((pontuacaoUsuario, index) => {
    //     if (index <= page * 10) {
    //       return (
    //         <div className="participante-content" key={index}>
    //           <div className="outros-participante participante">
    //             <PontuacaoUsuario
    //               pontuacaoUsuario={pontuacaoUsuario}
    //               isParticipante={false}
    //             />
    //           </div>
    //         </div>

    //       )
    //     }
    //     return null;
    //   })
    // )
  };

  const loadMore = () => {
    if (page <= 5) {
      setPage(page + 1);
    }
  };

  const infiniteScroll = () => {
    return (
      <div className="scroll">
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={true}
          loader={
            <div>
              <Spinner isVisivel={loading} />
            </div>
          }
          useWindow={false}
        >
          {loading ? <Spinner isVisivel={loading} /> : gerarPaginas()}
        </InfiniteScroll>
      </div>
    );
  };

  const callbackModal = () => {
    return setError({
      titulo: "",
      mensagem: "",
      isWrong: false,
    });
  };

  return (
    <GameRankingsListStyled>
      {error.isWrong === true && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={error.titulo}
          conteudo={error.mensagem}
        />
      )}
      <h3 className="ranking-title">
        {i18n.message("rankingsCampanha.ranking.title", "RANKING")}
      </h3>
      <div className="input-search">
        <InputSearch
          placeholder={i18n.message(
            "rankingsCampanha.ranking.search",
            "Buscar por nome",
          )}
          ariaLabel={i18n.message(
            "rankingsCampanha.aria.search",
            "campo para buscar participantes do ranking pelo nome",
          )}
          onChange={search}
        ></InputSearch>
      </div>
      {/* Caso participante NÃO ESTEJA PRESENTE no top 50 */}
      {/* ele deve aparecer no início da página */}
      {!usuarioEstaNoRanking && isDesktopMode ? (
        <>
          <div className="info-position">
            <p className="info-pontuacao">
              {i18n.message("info.pontuacao", "Sua posição: fora do top 50 ")}
            </p>
          </div>
          <div className="isDesktop">
            <PontuacaoUsuarioCorrente
              pontuacaoUsuario={pontuacaoUsuario}
              foraDoRanking={true}
            />
          </div>
        </>
      ) : (
        ""
      )}
      <div>{infiniteScroll()}</div>
    </GameRankingsListStyled>
  );
}

export default RankingListaParticipantes;
