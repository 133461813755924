import instance from "./Api";

export default class ExtraService {
  /**
   * Obtém o conteúdo extra para um participante e atividade específicos.
   *
   * @param {string} participant - O UUID do participante.
   * @param {string} atividade - O UUID da atividade.
   * @returns {Promise} Uma Promise contendo o resultado da requisição HTTP.
   */
  getConteudoExtra(participant, atividade) {
    const url = `/api/v1/campaign/schedule/${participant}/extra/${atividade}`;
    return instance.get(`${this.host()}${url}`, { responseType: "blob" });
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
