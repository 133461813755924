import React from "react";
import { DataCardAgendaStyle } from "./style";
import { useStore } from "react-redux";
import CheckExecutado from "../../svg/CheckExecutado";
import CampanhaService from "../../../services/CampanhaService";

/**
 * Componente que exibe a data da agenda.
 * @param {Object} props As propriedades do componente.
 * @param {boolean} props.habilitado Indica se a data está habilitada.
 * @param {boolean} props.dayCompleted Indica se a data foi completada.
 * @param {string} props.diaTextual O dia textual.
 * @param {string} props.mesTextualVisivel O mês textual visível.
 * @param {string} props.mesTextualLeitorTela O mês textual para leitor de tela.
 * @param {boolean} props.isAgendaProlongada Indica se a agenda é prolongada.
 */

function DataAgenda({
  habilitado,
  dayCompleted,
  diaTextual,
  mesTextualVisivel,
  mesTextualLeitorTela,
  isAgendaProlongada,
}) {
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  const shouldShowScheduleDate =
    campanha?.personalizationEvent?.SHOW_DATE_SCHEDULE === "true";
  const i18n = useStore().getState().i18n;
  const mostrarCompletado = habilitado === true && dayCompleted === true;
  return (
    <>
      {!shouldShowScheduleDate && !isAgendaProlongada ? (
        ""
      ) : (
        <>
          <DataCardAgendaStyle
            $habilitado={habilitado}
            $dayCompleted={dayCompleted}
          >
            {mostrarCompletado ? (
              <div className="weex-l-icone-check-executado-canto-direito">
                <CheckExecutado
                  label={`${i18n.message(
                    "alt.icone.atividades.todasAtividadesExecutadas.titulo",
                    "Atividades executadas.",
                  )} ${i18n.message(
                    "alt.icone.atividades.todasAtividadesExecutadas.descricao",
                    "Ícone de check verde indicando que todas as atividades do dia foram concluídas.",
                  )}`}
                  width={20}
                  height={20}
                />
              </div>
            ) : (
              ""
            )}
            {isAgendaProlongada ? (
              <div className="weex-l-card-data-container">
                <img
                  src="/agendaprolongada-b.png"
                  alt={i18n.message(
                    "alt.icone.agenda.prolongada",
                    "Ícone do infinito da agenda prolongada",
                  )}
                />
              </div>
            ) : (
              <div className="weex-l-card-data-container weex-l-card-data-container--position-textual">
                <div className="weex-l-card-data">
                  <p aria-hidden="true" className="weex-l-card-data__dia">
                    {diaTextual}
                  </p>{" "}
                  <p aria-hidden="true" className="weex-l-card-data__mes">
                    {mesTextualVisivel}
                  </p>
                  <p className="weex-g-visually-hidden">{`${diaTextual} ${mesTextualLeitorTela}`}</p>
                </div>
              </div>
            )}
          </DataCardAgendaStyle>
        </>
      )}
    </>
  );
}

export default DataAgenda;
