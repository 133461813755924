import React from "react";
import Interesse from "./InteresseMobile";

export default function InteressesMobile(props) {
  return (
    <>
      <div className="weex-container  weex-main-content-border weex-container-logado-grow">
        <div className="interesse-content">
          <h3>{props.titleMessage}</h3>
          <h4>{props.selectMessage}</h4>
          <hr />
          <Interesse
            campanha={props.campanha.interests}
            beginDate={props.beginDate}
            now={props.now}
            daysOfEvent={props.daysOfEvent}
            canSelectInterets={props.canSelectInterets}
            requiredInterests={props.requiredInterests}
          />
        </div>
      </div>
    </>
  );
}
