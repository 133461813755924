import Vimeo from "@u-wave/react-vimeo";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WeexModal from "../weexModais/WeexModal";
import { VideoIframeStyled } from "./VideoIframeStyle";
function VideoIframe(props) {
  const [erro, setErro] = useState(null);
  const navigate = useNavigate();
  const [urlParseada, setUrlParseada] = useState(null);

  useEffect(() => {
    try {
      setUrlParseada(parsearUrlvideo(props.urlVideo));
    } catch (e) {
      console.log(e);
      setErro({
        titulo: "Ops!",
        mensagem:
          "O link informado não é um link válido. Por favor, verifique o link e tente novamente.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function videoHandler(url) {
    if (url) {
      if (url.indexOf("youtube") !== -1 || url.indexOf("youtu.be") !== -1) {
        return (
          <iframe
            title={props.title}
            className="video-mural youtube"
            width="100%"
            height="100%"
            webkitallowfullscreen="webkitallowfullscreen"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            src={url}
          />
        );
      } else {
        if (url.indexOf("vimeo") !== -1) {
          return (
            <Vimeo
              className="video-mural animations-popIn"
              video={url}
              controls={props.controls}
              showTitle={props.showTitle}
            ></Vimeo>
          );
        }
      }
    }
  }
  const callbackModal = () => {
    setErro(null);
    navigate(`/mural/${props.mural}`);
  };
  const parsearUrlvideo = useCallback(
    (url) => {
      if (url) {
        if (
          url.indexOf("youtube") !== -1 ||
          url.indexOf("youtu.be") !== -1 ||
          url.indexOf("shorts") !== -1
        ) {
          let regex =
            /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
          let resultadoRegex = url.match(regex);
          if (resultadoRegex && resultadoRegex[1]) {
            let urlParseada =
              "https://www.youtube.com/embed/" +
              resultadoRegex[1] +
              "?autoplay=0&mute=0&controls=2&origin=" +
              window.location.origin +
              "&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" +
              window.location.origin;

            if (props.showControls) {
              urlParseada += "&controls=1";
            }

            return urlParseada;
          }
        }
        return url;
      }
    },
    [props.showControls],
  );

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <VideoIframeStyled>{videoHandler(urlParseada)}</VideoIframeStyled>
    </>
  );
}

export default VideoIframe;
