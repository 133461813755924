import React from "react";
import styled from "styled-components";
const Localizacao = (props) => {
  return (
    <LocalizacaoStyled
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 329.38 468.42"
      fill="#671cc9"
    >
      <path d="m161.04,468.42c-3.52-3.41-7.7-6.37-10.47-10.31-27.64-39.42-55.49-78.71-82.38-118.64-22.24-33.02-41.65-67.71-55-105.44C4.85,210.46-.38,186.32.02,161.14,1.16,90.4,49.62,26.77,117.63,6.84c90.09-26.4,182.74,26.01,206.55,116.82,9.07,34.61,5.58,68.58-5.21,102.15-11.79,36.67-29.55,70.53-50.68,102.47-24.53,37.1-50.35,73.35-75.61,109.97-4.58,6.65-8.9,13.51-13.85,19.87-2.98,3.83-6.95,6.9-10.48,10.31h-7.32Zm3.46-38.27c.86-.98,1.29-1.38,1.63-1.86,25.12-36.18,50.73-72.02,75.18-108.64,21.01-31.47,39.17-64.6,51.07-100.78,8.95-27.21,12.9-54.91,6.44-83.27C279.11,49.02,187.94,3.7,107,40.14,52.38,64.72,19.65,125.74,28.93,185.01c3.98,25.41,12.63,49.18,23.63,72.21,15.67,32.82,35.16,63.39,55.86,93.2,18.42,26.53,37.15,52.84,56.08,79.72Z" />
      <path d="m164.84,82.28c45.36.06,82.26,37.09,82.2,82.49-.06,45.35-37.1,82.26-82.49,82.2-45.34-.06-82.26-37.11-82.2-82.5.06-45.34,37.1-82.25,82.5-82.19Zm-.02,27.45c-30.24-.05-55.02,24.67-55.03,54.88,0,30.02,24.59,54.8,54.54,54.92,30.29.13,55.17-24.49,55.26-54.66.09-30.26-24.57-55.09-54.77-55.14Z" />
    </LocalizacaoStyled>
  );
};

const LocalizacaoStyled = styled.svg`
  fill: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
  width: 3rem;
  height: 3rem;
`;
export default Localizacao;
