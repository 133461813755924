import styled from "styled-components";

export const ButtonInteresseEscolherStyle = styled.button`
  border: 1px solid transparent;
  border-color: #dbdbdb;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(1em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(1em - 1px);
  text-align: center;
  white-space: normal;

  border-radius: 290486px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  font-weight: 700;
  background-color: ${(props) => {
    return props.theme.fundoTres;
  }};
  color: ${(props) => {
    return props.theme.fundoSecundario;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};

  &:active {
    filter: brightness(0.8);
  }

  .disabled {
    cursor: not-allowed;
    filter: contrast(0.5);
  }
`;
