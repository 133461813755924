import React from "react";
import { useSelector } from "react-redux";

function Image({
  src,
  alt,
  srcDesktop,
  altDesktop,
  className,
  classNameDesktop,
}) {
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  return (
    <>
      {isDesktopMode ? (
        <>
          {srcDesktop ? (
            <>
              <img
                className={classNameDesktop}
                src={srcDesktop}
                alt={altDesktop}
              />
            </>
          ) : (
            <>{src ? <img className={className} src={src} alt={alt} /> : ""}</>
          )}
        </>
      ) : (
        <>{src ? <img className={className} src={src} alt={alt} /> : ""}</>
      )}
    </>
  );
}

export default Image;
