import React from "react";
import { DetalhesDinamicaPresencialStyle } from "./style";
import CircleCheck from "../svg/circleCheck";
import { useStore } from "react-redux";
import Localizacao from "../svg/Localizacao";

export default function DetalhesDinamicaPresencial({
  voltar,
  dinamicaPresencial,
  titulo,
  page,
}) {
  const i18n = useStore().getState().i18n;

  return (
    <DetalhesDinamicaPresencialStyle>
      <div className="columns is-mobile mt-4">
        <div className="column">
          <button
            onClick={voltar}
            className="button is-text"
            aria-label={i18n.message(
              "aria.label.geral.mensagem.voltar.atividades",
              "Volta para a página de atividades.",
            )}
          >
            <span className="icon custom-color-icon">
              <span className="fa-stack fa-sm">
                <i className="far fa-circle fa-stack-2x "></i>
                <i className="fas fa-chevron-left fa-stack-1x "></i>
              </span>
            </span>
          </button>
        </div>

        <span className="column  has-text-centered">
          {page === "feedback" && (
            <span className="icon is-large">
              <CircleCheck></CircleCheck>
            </span>
          )}
          {page !== "feedback" && <Localizacao></Localizacao>}
        </span>

        <div className="column has-text-right" />
      </div>

      <h1 className="weex-l-texto-customizado">{titulo}</h1>
      <div className="mx-6 mt-5">
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: dinamicaPresencial?.description,
            }}
          ></div>
        </div>
        <p className="mt-4">
          <b>{i18n.message("dinamica.presencial.local", "Local:")}</b>{" "}
          {dinamicaPresencial?.mainAddress}
          <br />
        </p>
        <p className="mt-4">
          <b>{i18n.message("dinamica.presencial.horario", "Horário:")}</b>{" "}
          {dinamicaPresencial?.timeDescription}
          <br />
        </p>
      </div>
    </DetalhesDinamicaPresencialStyle>
  );
}
