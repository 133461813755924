import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import ParticipanteService from "../../services/ParticipanteService";
import UsuarioService from "../../services/UsuarioService";

function AtivarParticipante() {
  const i18n = useStore().getState().i18n;
  const [erro, setErro] = useState(null);

  const [processando, setProcessando] = useState(false);

  const navigate = useNavigate();
  let { code } = useParams();

  useEffect(() => {
    if (!processando) {
      setProcessando(true);
      const participanteService = new ParticipanteService();
      const usuarioService = new UsuarioService();
      const campanhaService = new CampanhaService();
      participanteService.ativarParticipante(code, (erro, autenticacao) => {
        if (erro) {
          if (
            erro.response &&
            erro.response.data &&
            erro.response.data.message
          ) {
            setErro(
              i18n.message(
                "ativarParticipante.erro.prefixo",
                "Erro ao ativar participante: ",
              ) + erro.response.data.message,
            );
          } else {
            setErro(
              i18n.message(
                "ativarParticipante.erro.prefixo",
                "Erro ao ativar participante: ",
              ) + erro,
            );
          }
          return;
        }
        if (autenticacao) {
          let campanhaCorrente = campanhaService.campanhaCorrente();
          usuarioService.setUsuarioCorrente(autenticacao);
          if (
            !campanhaCorrente ||
            campanhaCorrente.code !== autenticacao.campaignCode
          ) {
            navigate("/campaign/" + autenticacao.campaignCode);
          } else {
            navigate("/");
          }
        }
      });
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processando]);

  return <div>{erro}</div>;
}

export default AtivarParticipante;
