import styled from "styled-components";

export const CabecalhoATStyle = styled.div`
  .weex-l-cabecalho {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .weex-l-cabecalho-voltarAltura {
    height: 2.5rem;
  }

  .weex-l-cabecalho-btn-voltar {
    text-decoration: none;
  }

  .weex-l-cabecalho-custom-color-icon {
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-cabecalho-btn-text {
    margin-left: 0.8rem;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.75rem;
  }

  .weex-l-cabecalho-titulo {
    text-transform: none;
    font-size: 0.9rem;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-cabecalho-quantidadeQuestoes {
    font-weight: 800;
    text-align: right;
    text-transform: none;
    font-size: 0.9rem;
    color: ${(props) => props.theme.fundoSecundario || `#533888`};
  }

  .weex-l-cabecalho-titulo__composto {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-cabecalho {
      flex-direction: row;
      margin-left: 5rem;
      margin-right: 5rem;
    }

    .weex-l-cabecalho--filhos {
      flex: 1;
      text-align: center;
    }

    .weex-l-cabecalho-quantidadeQuestoes {
      text-align: right;
      line-height: 2.25;
      margin-right: 5rem;
      font-size: 1.5rem;
    }

    .weex-l-cabecalho-titulo {
      text-align: left;
      line-height: 2.25;
      margin-left: 2.5rem;
      font-size: 1.5rem;
    }
  }
  /* #endregion DESKTOP */
`;
