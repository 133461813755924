import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import MensagemVideoService from "../../services/MensagemVideoService";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { WeexModalDialog } from "./WeexModalDialog";
import ModalConclusao from "./ModalConclusao";

/**
 * @param {Object} props
 * @param {string} props.imagemSrc
 * @param {string} props.titulo
 * @param {string} props.mensagem
 * @param {string} props.uuid
 * @param {string} props.tipo
 * @param {function(): void} props.fecharModalCallback
 * @param {function(): void} props.adicionarHistoricoNavegacao
 */
function MensagemVideoModal({
  uuid,
  titulo,
  imagemSrc,
  mensagem,
  tipo,
  fecharModalCallback,
  adicionarHistoricoNavegacao,
}) {
  const [mostrarModal, setMostrarModal] = useState(true);
  const mensagemVideoService = new MensagemVideoService();
  const i18n = useStore().getState().i18n;
  const videoModalRef = useRef();
  const [concluiu, setConcluiu] = useState(false);
  const [pontos, setPontos] = useState(null);

  const marcarComoVistoMensagem = () => {
    mensagemVideoService.marcarComoVisto(uuid, (erroMensagemVideo, sucesso) => {
      fecharModal();
      if (sucesso) {
        setConcluiu(true);
        setPontos(sucesso.earnedPoints);
      }
    });
  };

  function fecharModal() {
    fecharModalCallback();
    setMostrarModal(false);
  }

  const text = () => {
    return (
      <div className="titulo-container">
        <h1 className="missao-cumprida__title foreground">
          {i18n.message("missaoCumprida.fim", "PARABÉNS!")}
        </h1>
        <h2 className="missao-cumprida__subtitle foreground">
          {i18n.message(
            "missaoCumprida.parabens",
            "Você concluiu a atividade:",
          )}
        </h2>
      </div>
    );
  };

  if (concluiu) {
    return <ModalConclusao pontos={pontos} text={text} />;
  }

  return mostrarModal ? (
    <WeexModalDialog dialogRef={videoModalRef} onPressESC={fecharModal}>
      <div className={"modal is-active animations-popIn"}>
        <div className="modal-background weex-modal-background"></div>
        <div className="modal-card weex-modal-card">
          <nav className="modal-card-head weex-modal-card-header">
            <button
              onClick={fecharModal}
              className="close-btn"
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </nav>
          <header className="weex-modal-card-header wex-modal-card-header-title">
            <h1
              id="tilteMensagemVideo"
              className="nome-atividade title-mensagem-video"
            >
              {titulo}
            </h1>
          </header>
          <section
            aria-labelledby="tilteMensagemVideo"
            className="modal-card-body has-text-centered"
          >
            {imagemSrc !== null ? (
              <div className="weex-modal-card-body__texto">
                <div className="modal-card-title wex-modal-card-img espacamento-fim">
                  <img
                    src={imagemSrc}
                    alt={i18n.message(
                      "alt.modal.apresentacao.thumbnail",
                      "thumbnail da vídeo mensagem",
                    )}
                    className="image is-2by1 retirar-padding"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="weex-modal-card-body__texto">
              <div>{mensagem !== null ? mensagem : ""}</div>
            </div>
          </section>
          <footer
            className="modal-card-foot weex-modal-card-footer"
            style={{ textAlign: "center" }}
          >
            <BotaoPrimario
              nome={
                tipo === "MENSAGEM"
                  ? i18n.message("mensagemvideo.campanha", "Concluir!")
                  : i18n.message("mensagemvideo.assistir", "Assistir!")
              }
              funcao={
                tipo === "MENSAGEM"
                  ? marcarComoVistoMensagem
                  : adicionarHistoricoNavegacao
              }
              ariaStatusMessage={i18n.message(
                "aria.status.mensagemvideomodal.botaoassistir",
                "Botão assistir clicado.",
              )}
            />
          </footer>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default MensagemVideoModal;
