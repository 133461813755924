import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import TagPlacarAT from "./TagPlacarAT";
import BotaoSecundario from "../botaoSecundario/BotaoSecundario";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { useNavigate } from "react-router-dom";
import { PlacarRespostasATStyle } from "./PlacarRespostasATStyle";

import TagTotalAT from "./TagTotalAT";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";

function PlacarRespostasAT() {
  const {
    acerteTempo,
    TipoComponenteEnum,
    obtemPlacarFinal,
    mudarComponentePagina,
    revisaoMostrarTagPontos,
    campaignType,
  } = useAcerteTempo();

  const scoreboard = obtemPlacarFinal;
  const presentationImage = acerteTempo?.image;

  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const onClickRevisao = () => {
    mudarComponentePagina(TipoComponenteEnum.modoRevisao);
    revisaoMostrarTagPontos();
  };

  const resultado = scoreboard?.scores.map((score, index) => {
    return (
      <li key={score.uuid} className="weex-l-acerteTempo-placar-pontuacoes">
        <span className="weex-l-acerteTempo-placar-pontuacoes--numeracao">
          {index + 1}
        </span>
        <TagPlacarAT
          campaignType={campaignType}
          correct={score?.correct}
          totalPoints={score?.totalPoints}
        />
      </li>
    );
  });

  const botoes = () => {
    return (
      <div className="weex-l-acerteTempo-placar-btns-container">
        <div className="weex-l-acerteTempo-placar-btns-espacamentoSuperior">
          <BotaoPrimario
            nome={i18n.message(
              "dinamica.acerteTempo.btn.salaDeTrofeus",
              "Sala de Troféus",
            )}
            disabled={""}
            ariaDisabled={""}
            ariaHidden={""}
            tabIndex={""}
            ariaLabelMsg={""}
            classeComplementar={""}
            funcao={() => {
              navigate("/trophy");
            }}
            ariaStatusMessage={""}
          />
        </div>
        <div className="weex-l-acerteTempo-placar-btns-espacamentoSuperior">
          <BotaoSecundario
            nome={i18n.message(
              "dinamica.acerteTempo.btn.revisao",
              "Minhas Respostas",
            )}
            funcao={onClickRevisao}
          />
        </div>
      </div>
    );
  };

  return (
    <PlacarRespostasATStyle className="weex-l-acerteTempo-textos-container">
      <div className="weex-l-acerteTempo-container weex-l-acerteTempo-container-rowReversed">
        <div className="weex-l-acerteTempo-textos-container weex-l-acerteTempo-container--flexFilhos">
          <h2 className="weex-l-acerteTempo-placar--titulo">
            {i18n.message(
              "dinamica.acerteTempo.subitulo.placar",
              "Seu placar:",
            )}
          </h2>
          <ul className="weex-l-acerteTempo-botoesContainer">{resultado}</ul>
          {campaignType !== "REGULAR" ? (
            <TagTotalAT totalPoints={scoreboard?.totalPointsRightHabit} />
          ) : (
            ""
          )}
        </div>

        <WeexDesktopMode
          className={
            "weex-l-acerteTempo-container--flexFilhos weex-l-acerteTempo-posicionamento-imagens"
          }
        >
          {presentationImage ? (
            <>
              <div>
                <img
                  className="image is-2by1 retirar-padding"
                  src={presentationImage}
                  alt={i18n.message(
                    "alt.dinamica.acerteTempo.imagemApresentacao",
                    "Imagem de apresentação da dinâmica Hábito Certo",
                  )}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </WeexDesktopMode>
      </div>

      {botoes()}
    </PlacarRespostasATStyle>
  );
}

export default PlacarRespostasAT;
