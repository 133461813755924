// import axios from 'axios';
import instance from "./Api";

export default class SenhaService {
  host() {
    return process.env.REACT_APP_HOST_API;
  }

  enviarEmailRestauroSenha(corpo, callback) {
    instance
      .post(this.host() + "/api/v1/passwordRecovery", corpo)
      .then((response) => {
        callback(null, true);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  alterarSenha(corpo, callback) {
    instance
      .post(this.host() + "/api/v1/passwordReset", corpo)
      .then((response) => {
        callback(null, true);
      })
      .catch((error) => {
        callback(error, null);
      });
  }
}
