import React from "react";
import { WeexProgressContainerStyle } from "./style";

function WeexProgressBar({ bgColor, completed, isAgendaProlongada }) {
  const containerStyles = {
    marginTop: "0.625rem",
    height: 2,
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgColor,
    borderRadius: "inherit",
    textAlign: "right",
    transition: "width 2s ease-in-out",
  };

  return (
    <WeexProgressContainerStyle
      $isAgendaProlongada={isAgendaProlongada}
      style={containerStyles}
    >
      <div style={fillerStyles}></div>
    </WeexProgressContainerStyle>
  );
}

export default WeexProgressBar;
