import styled from "styled-components";

export const AgendaHeaderStyle = styled.div`
  padding: 0.5rem 0.625rem 0.625rem;
  flex-grow: 1;

  .weex-l-titulo-da-agenda-corrente {
    color: ${(props) => {
      return props.theme.cardLogadoTextoPrimeiro;
    }};
    font-weight: 600;
    font-size: 0.813rem;
    line-height: 1.25rem;
    text-transform: none;
    text-align: left;
  }

  .weex-l-agenda-cabecalho {
    display: flex;
    justify-content: space-between;
  }

  .weex-l-barra-de-progresso-agenda {
    margin-top: 0.938rem;
    max-width: 70%;
  }

  .weex-l-informacoes-complementares {
    display: flex;
    gap: 1.563rem;
  }

  .weex-l-informacoes-complementares__textos {
    font-size: 0.75rem;
    line-height: 0.75rem;
    font-weight: 700;
    color: ${(props) => {
      return props.$completedColor;
    }};
    text-align: center;
    margin-bottom: 0.313rem;
  }

  .weex-l-dia-da-semana {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: #000000;
  }

  .weex-l-botao-esconder-atividades {
    border: none;
    background: transparent;
    padding: 0px;
    margin: 0px;
    cursor: pointer;

    :active {
      filter: brightness(0.5);
    }

    :hover {
      filter: brightness(0.5);
    }
  }

  /* #region DESKTOP */

  /* 75rem é o equivalente a 1200px */

  @media (min-width: 75rem) {
    .weex-l-barra-de-progresso-agenda {
      margin-top: 0.938rem;
      max-width: 85%;
    }

    .weex-l-agenda-cabecalho {
      display: block;
    }
  }

  /* #endregion DESKTOP */
`;
