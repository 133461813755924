import React from "react";
import { DinamicasHeaderStyle } from "./style";

/**
 * Cabeçalho com informações das dinâmicas.
 * Criado com a versão do pwa 2.0.
 * @param {*} props
 * @param {*} props.title - titulo da dinâmica ou das questões de uma dinâmica
 * @param {*} props.dinamicaAlt - descrição da imagem/ícone da dinâmica
 * @param {*} props.dinamicaImg - imamge/ícone da dinâmica
 * @param {*} props.type - tipo da dinâmica (usando no momento apenas para o vídeo)
 * @param {*} props.dinamicaNome - nome da dinâmica (vídeo, novos hábitos, etc)
 * @returns
 */
function DinamicasHeader({
  title,
  dinamicaAlt,
  dinamicaImg,
  type,
  dinamicaNome,
}) {
  return (
    <>
      <DinamicasHeaderStyle>
        <div>
          <h3 className="dinamica-title">{title}</h3>
          <div
            className="dinamica-nome"
            aria-disabled="true"
            aria-hidden="true"
          >
            <img
              alt={dinamicaAlt}
              src={dinamicaImg}
              className={
                type === "video" ? "dinamica-img" : "dinamica-img filter-color"
              }
            />
            <span>{dinamicaNome}</span>
          </div>
        </div>
      </DinamicasHeaderStyle>
    </>
  );
}

export default DinamicasHeader;
