import styled from "styled-components";

export const MedalhaStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .weex-l-trofeu-dia-link-as-a-button {
    border: 0.063rem solid
      ${(props) => (props.$activityAchieved ? `#00E291` : `#CECECE`)};
    border-radius: 0.5rem;
    padding: 0.625rem;
    margin-right: 0.375rem;
    margin-left: 0.375rem;
    display: inline-block;
  }

  .weex-l-trofeu-dia-link-as-a-button__icone {
    width: 2.125rem;
    height: 2.188rem;
    background-size: cover;
    max-height: 4.375rem;
    background-image: url("${(props) => props.url}");
  }

  .weex-l-trofeu-texto {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 0.625rem;
    line-height: 0.938rem;
    color: black;
    margin-top: 0.313rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-trofeu-texto {
      font-size: 0.75rem;
      line-height: 1.125rem;
    }
  }
  /* #endregion DESKTOP */
`;
