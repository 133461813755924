import React from "react";
import styled, { keyframes } from "styled-components";

function Spinner(props) {
  const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;
  const SpinnerLoad = styled.div`
    margin: auto;
    border: 5px solid #eee;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spin} 1s linear infinite;
  `;

  if (props.isVisivel) {
    return <SpinnerLoad />;
  } else {
    return <div></div>;
  }
}

export default Spinner;
