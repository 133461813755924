import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore, useDispatch } from "react-redux";
import DiaSemanaAgenda from "../../comps/agenda/CardDiaSemanaAtividades/DiaSemanaAgenda";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import MensagemVideoModal from "../../comps/weexModais/MensagemVideoModal";
import WeexModalAtividades from "../../comps/weexModais/WeexModalAtividades";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import FeedbackService from "../../services/FeedbackService";
import LoaderService from "../../services/LoaderService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import PagesHeader from "../../comps/pagesHeader/PagesHeader";
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import { AtividadesStyle } from "./style";
import PrepararAgenda from "../../services/PrepararAgenda";
import PreSelecaoInteressesService from "../../services/PreSelecaoInteressesService";

const prepararAgenda = new PrepararAgenda();

function Atividades() {
  const i18n = useStore().getState().i18n;
  const icone = useStore().getState().icone;

  const iconeCustomizado = icone?.finalIcone;
  const possuiIconeCustomizado = iconeCustomizado.length > 0;

  const dispatch = useDispatch();
  const usuarioService = new UsuarioService();
  const campanhaService = new CampanhaService();
  const feedbackService = new FeedbackService();
  const agendaService = new AgendaService();
  const mensagemVideoService = new MensagemVideoService();
  const navigate = useNavigate();

  const [feedback, setFeedback] = useState(null);
  const usuario = usuarioService.usuarioCorrente();
  const usuarioNaoAceitouLegalTerm = usuario && !usuario?.legalTerm;
  const campanha = campanhaService.campanhaCorrente();
  const campanhaPossuiLegalTerm = campanha && campanha?.legalTerm;
  const campanhaPossuiInteressesObrigatorios =
    campanha && campanha?.requiredInterests;
  const preSelecaoInteressesService = new PreSelecaoInteressesService();
  const adminOrGestor = usuarioService.isAdmin() || usuarioService.isGestor();

  const godModeEnabled = usuarioService.isAdmin() || usuarioService.isGestor();

  const [apresentacaoAtividade, setApresentacaoAtividade] = useState({
    apresentacao: "",
    apresentacaoCapa: "",
    nome: "",
    titulo: "",
    icone: "",
    executed: false,
  });
  const [diasSemana, setDiasSemana] = useState();
  const [processando, setProcessando] = useState(true);
  const [mensagemDeVideo, setMensagemDeVideo] = useState([]);
  const [, setErro] = useState(null);
  const [rota, setRota] = useState("");
  const [codigoAgenda, setCodigoAgenda] = useState("");
  const [codigoAtividade, setCodigoAtividade] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false);
  const [mostrarModalVideo, setMostrarModalVideo] = useState(false);
  const [isAtividadeClicadaCompleta, setIsAtividadeClicadaCompleta] =
    useState(false);

  if (!campanhaService.campanhaCorrente()) {
    navigate("/");
  }

  const sincronizarCampanha = useCallback(async () => {
    await new CampanhaService().useSincronizarCampanha();
  }, []);

  useEffect(() => {
    setProcessando(true);

    const sincronizarCampanhaCallback = async (callback) => {
      try {
        sincronizarCampanha();
        callback();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanhaCallback(async () => {
      if (usuarioNaoAceitouLegalTerm && campanhaPossuiLegalTerm) {
        navigate("/accept");
      }

      /**
       * Adiciona o background-img vindo do estilos
       */
      let service = new TemaService(campanhaService.campanhaCorrente());
      service.aplicarTema("HOME");

      /**
       * Mensagem em vídeo de boas vindas ou outras informações
       */
      mensagemVideoService.obterMensagemDeVideo((erro, sucesso) => {
        if (erro) {
          setErro(
            i18n.message(
              "atividades.erro.video.prefixo",
              "Erro ao obter mensagem de video da campanha: ",
            ) + erro,
          );
          return;
        }
        if (sucesso) {
          setMensagemDeVideo(sucesso);
          sucesso.length > 0
            ? setMostrarModalVideo(true)
            : setMostrarModalVideo(false);
        }
      });

      /**
       * Feedback da campanha geral
       */
      if (feedbackService.feedbackHabilitado()) {
        feedbackService.feedbackRespondido(callbackFeedback);
      }

      /**
       * Agenda
       */
      await handleAdminOrGestor();
      agendaService.agendaCorrente((erro, agenda) => {
        if (erro) {
          navigate("/");
          return;
        }
        if (agenda && !diasSemana) {
          campanhaService.contadorCampanha(async (erro, _sucesso) => {
            if (erro) {
              setErro(
                i18n.message(
                  "atividades.erro.dias.habilitados",
                  "Erro ao obter a lista de dias habilitados da campanha: ",
                ) + erro,
              );
              navigate("/");
              return;
            }
            if (
              campanhaPossuiInteressesObrigatorios &&
              !agenda?.pickedInterests &&
              !adminOrGestor
            ) {
              dispatch({ type: "campanhaComecou", payload: false });
              navigate("/interests");
              return;
            }
            dispatch({ type: "campanhaComecou", payload: true });
            setProcessando(false);
            const checkStartDaCampanha =
              campanhaService.obterCheckStartDaCampanha();
            prepararAgenda.prepareSchedule(
              agenda,
              checkStartDaCampanha,
              campanha.flow,
              possuiIconeCustomizado,
              iconeCustomizado,
              i18n,
              godModeEnabled,
              navegar,
            );

            let dias = agenda?.schedule?.map((agendaDoDia) => {
              return renderizaDiasDaAgenda(agendaDoDia);
            });
            setDiasSemana(dias);
          });
        }
      });
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdminOrGestor = async (agenda) => {
    if (adminOrGestor) {
      try {
        await registerInteressesAdminOrGestor(agenda);
        dispatch({ type: "campanhaComecou", payload: true });
      } catch (erro) {
        console.error("Erro ao registrar interesses:", erro);
      }
    }
  };

  const registerInteressesAdminOrGestor = (agenda) => {
    return new Promise((resolve, reject) => {
      if (campanhaPossuiInteressesObrigatorios && !agenda?.pickedInterests) {
        const interesseSubmit = [];
        preSelecaoInteressesService.selecaoDeInteresses(
          interesseSubmit,
          (erro, sucesso) => {
            if (erro) {
              console.error(
                "Se for gestor ou admin, deve registrar todos os interesses",
                erro,
              );
              reject(erro); // Rejeita a Promise com o erro
            } else if (sucesso) {
              console.info("Interesses criados com sucesso!!");
              resolve(sucesso); // Resolve a Promise com o sucesso
            }
          },
        );
      } else {
        resolve(); // Resolve a Promise caso o tipo de agendamento não seja "INTERESSES"
      }
    });
  };

  function renderizaDiasDaAgenda(agendaDoDia) {
    return (
      <div
        key={agendaDoDia?.scheduleCode}
        className="weex-l-atividade-card-container-desktop"
      >
        <div className="animations-popIn">
          <DiaSemanaAgenda agendaDoDia={agendaDoDia} />
        </div>
      </div>
    );
  }

  function navegar(
    rota,
    codigoAgenda,
    codigoAtividade,
    imagemTrofeuHabilitado,
    atividade,
    nomeDia,
    showPresentation,
  ) {
    setIsAtividadeClicadaCompleta(atividade.executed);
    if (
      atividade != null &&
      rota !== "presencial" &&
      rota !== "acerteTempo" &&
      showPresentation === true
    ) {
      setApresentacaoAtividade({
        apresentacao: atividade.presentation,
        apresentacaoCapa: atividade.presentationCover,
        nome: atividade.name,
        titulo: atividade.title,
        icone: atividade.enabledIcon,
        executed: atividade?.executed,
      });
      setRota(rota);
      setCodigoAgenda(codigoAgenda);
      setCodigoAtividade(codigoAtividade);
      setMostrarModal(true);
    } else {
      navigate(`/${rota}/${codigoAgenda}/${codigoAtividade}`, {
        state: {
          nomeDia: nomeDia,
        },
      });
    }
    new LoaderService().load(imagemTrofeuHabilitado);
  }

  function irParaDinamica() {
    navigate(`/${rota}/${codigoAgenda}/${codigoAtividade}`, {
      state: {
        icon: apresentacaoAtividade?.icone,
      },
    });
  }

  function adicionarHistoricoNavegacao() {
    if (mensagemDeVideo && mensagemDeVideo[0]?.video?.code) {
      navigate("/video-message/" + mensagemDeVideo[0]?.video?.code);
    }
  }

  function handleMensagemDeVideo() {
    setMensagemDeVideo(false);
  }

  function ocultarPopup() {
    setMostrarModal(false);
  }

  const callbackFeedback = (error, result) => {
    if (error) {
      setFeedback(null);
    } else {
      setFeedback(result);
    }
  };

  return (
    <AtividadesStyle>
      {mostrarModalVideo && (
        <MensagemVideoModal
          fecharModalCallback={handleMensagemDeVideo}
          adicionarHistoricoNavegacao={adicionarHistoricoNavegacao}
          uuid={mensagemDeVideo[0]?.uuid}
          titulo={mensagemDeVideo[0]?.title}
          imagemSrc={mensagemDeVideo[0]?.image}
          mensagem={mensagemDeVideo[0]?.message}
          tipo={mensagemDeVideo[0]?.type}
        />
      )}{" "}
      {mostrarModal ? (
        <WeexModalAtividades
          fecharModalCallback={ocultarPopup}
          irParaDinamica={irParaDinamica}
          rota={rota}
          conteudo_dangerouslySetInnerHTML={apresentacaoAtividade?.apresentacao}
          imagemSrc={apresentacaoAtividade?.apresentacaoCapa}
          atvName={apresentacaoAtividade?.nome}
          atvTitle={apresentacaoAtividade?.titulo}
          codigoAtividade={codigoAtividade}
          isAtividadeCompleta={isAtividadeClicadaCompleta}
          atvExecuted={apresentacaoAtividade?.executed}
        />
      ) : null}{" "}
      {processando === true ? (
        <WeexSpinner
          aguarde={i18n.message(
            "geral.carregando.atividades",
            "Carregando atividades...",
          )}
        />
      ) : (
        <>
          <PagesHeader
            title={i18n.message("atividades.titulo", "Atividades")}
            subtitle={i18n.message(
              "atividades.subtitulo",
              "Para realizar as atividades, clique nas dinâmicas dos dias que já estão disponíveis",
            )}
          />
          <div className="weex-l-atividade-agenda-container">{diasSemana}</div>
          {feedback && !feedback.answered && (
            <div className="weex-l-atividade-botao-feedback-container">
              <BotaoPrimario
                nome={i18n.message(
                  "feedback.acao",
                  "Conte-nos sobre sua experiência",
                )}
                funcao={() => {
                  navigate("/feedback");
                }}
              />
            </div>
          )}
        </>
      )}
    </AtividadesStyle>
  );
}

export default Atividades;
