import styled from "styled-components";

export const VideoIframeStyled = styled.div`
  .video-mural {
    width: 100%;
    margin: auto;
  }

  .video-mural > iframe {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .youtube {
    margin-top: 1em;
  }

  .modal-card-body-publicacao {
    padding: 0;
  }

  .video-publicacao-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
