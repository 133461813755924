import React from "react";
import BotaoGameAtividade from "../BotaoGameAtividade/BotaoGameAtividade";
import BotaoAtividadeDia from "../BotaoAtividadeDia/BotaoAtividadeDia";
import {
  AtividadesDoDiaContainerStyle,
  AtividadeComSubtitulos,
  AtividadeBotaoContainerStyle,
} from "./style";
import PropTypes from "prop-types";

export default function AtividadesDoDia({
  numeroItensPorLinha,
  activitiesWithSubtitlesNoGames,
  atividadesSemGameFinal,
  hasSubtitles,
  gamesFinal,
}) {
  AtividadesDoDia.propTypes = {
    numeroItensPorLinha: PropTypes.number,
    atividadesComSubtitulosSemGameFinal: PropTypes.array,
    desabilitarButton: PropTypes.bool,
    atividadesSemGameFinal: PropTypes.array,
    possuiSubtitulos: PropTypes.bool,
    gamesFinal: PropTypes.array,
  };

  const activityChunks = (atividades, chunks) => {
    let final = [];
    let n = atividades?.length;
    for (let i = 0; i < n; i += chunks) {
      final = [...final, atividades.slice(i, i + chunks)];
    }
    return final;
  };

  const atividadesRender = (items, keyPrefix) => {
    return (
      <AtividadeBotaoContainerStyle tamanho={20 * items.length}>
        {items.map(function (el, indice) {
          let habilitado = el?.enabled;
          return (
            <BotaoAtividadeDia
              key={`${keyPrefix}-${el.uuid}`}
              chave={indice}
              navegar={el?.navegar}
              habilitado={habilitado}
              imagemHabilitado={el?.enabledIcon}
              imagemDesabilitado={el?.disabledIcon}
              executado={el?.executed}
              size={{ width: `${100 / numeroItensPorLinha}%` }}
              type={el?.nome}
            />
          );
        })}
      </AtividadeBotaoContainerStyle>
    );
  };

  const atividadesComSubTitulos = () => {
    return (
      <>
        {activitiesWithSubtitlesNoGames.map((activityBySubtitle, index) => {
          let activitiesResults = activityChunks(
            activityBySubtitle?.activities,
            numeroItensPorLinha,
          );
          const keyBase = `subtitulo-$
        {activityBySubtitle?.subtitle?.subtitle}-${index}`;
          return (
            <AtividadeComSubtitulos key={keyBase}>
              <h2 className="weex-l-atividade-subtitulo">
                {activityBySubtitle?.subtitle?.subtitle}
              </h2>
              {activitiesResults.map((items, key) => (
                <div key={`${keyBase}-${key}`}>
                  {atividadesRender(items, `${keyBase}-${key}`)}
                </div>
              ))}
            </AtividadeComSubtitulos>
          );
        })}
      </>
    );
  };

  const atividadesSemSubtitulos = () => {
    const activitiesResults = activityChunks(
      atividadesSemGameFinal,
      numeroItensPorLinha,
    );

    return activitiesResults.map((items, key) => (
      <div key={`sem-subtitulo-${key}`}>
        {atividadesRender(items, `sem-subtitulo-${key}`)}
      </div>
    ));
  };

  const atividadesGame = () => {
    return (
      <>
        <BotaoGameAtividade
          games={
            hasSubtitles
              ? gamesFinal.filter((a) => a?.subtitle?.uuid)
              : gamesFinal
          }
          atividades={
            hasSubtitles
              ? atividadesSemGameFinal.filter((a) => a?.subtitle?.uuid)
              : atividadesSemGameFinal
          }
        />
      </>
    );
  };

  return (
    <AtividadesDoDiaContainerStyle className="weex-g-scrollbar">
      {hasSubtitles ? atividadesComSubTitulos() : atividadesSemSubtitulos()}
      {atividadesGame()}
    </AtividadesDoDiaContainerStyle>
  );
}
