import instance from "./Api";
import CampanhaService from "./CampanhaService";
import UsuarioService from "./UsuarioService";

export default class RankingIndividualService {
  campanha = new CampanhaService().campanhaCorrente();
  usuarioCorrente = new UsuarioService().usuarioCorrente();
  participante = this.usuarioCorrente.participantCode;
  getPontuacaoParticipanteCampanha() {
    return instance.get(
      this.host() + "/api/v1/participant/" + this.participante + "/score",
    );
  }

  getRankingParticipanteCampanha(filtros) {
    const rangkingRequest = {};
    rangkingRequest.pageSize = 50;
    rangkingRequest.page = 0;
    if (filtros) {
      if (filtros.units) {
        rangkingRequest.unitsUuid = filtros.units;
      }
    }
    if (filtros.participantName) {
      rangkingRequest.participantName = filtros.participantName;
    }

    return instance.post(
      this.host() +
        "/api/v1/event/" +
        this.campanha.uuid +
        "/rankingParticipant",
      rangkingRequest,
    );
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
