import React from "react";
import {
  BotaoAtividadeDiaContainerStyle,
  TextoDoBotaoAtividadeStyle,
} from "./style";
import WeexCheckExecutado from "../../WeexCheckExecutado/WeexCheckExecutado";

/**
 * @param {Object} props
 * @param {number | string} props.chave
 * @param {boolean} props.habilitado
 * @param {string} props.imagemHabilitado
 * @param {string} props.imagemDesabilitado
 * @param {boolean} props.executado
 * @param {string} props.type
 * @param {React.CSSProperties} props.size
 * @param {function(event): void} props.navegar
 */
export default function BotaoAtividadeDia({
  chave,
  navegar,
  habilitado,
  imagemHabilitado,
  imagemDesabilitado,
  executado,
  size,
  type,
}) {
  return (
    <BotaoAtividadeDiaContainerStyle
      style={size}
      onClick={navegar}
      disabled={!habilitado}
      key={"button" + chave}
      $habilitado={habilitado}
      $executado={executado}
      $imagemHabilitado={imagemHabilitado}
      $imagemDesabilitado={imagemDesabilitado}
    >
      <WeexCheckExecutado executado={executado} top={"0.75rem;"} />
      <div className="weex-l-botao-atividade weex-l-botao-atividade--executado">
        {habilitado ? (
          <div className="weex-l-botao-atividade__icone weex-l-botao-atividade__icone--habilitado weex-l-botao-atividade__icone--tamanho"></div>
        ) : (
          <div className="weex-l-botao-atividade__icone weex-l-botao-atividade__icone--desabilitado weex-l-botao-atividade__icone--tamanho"></div>
        )}
      </div>
      <TextoDoBotaoAtividadeStyle>
        <p className="weex-l-texto-botao-atividade">{type}</p>
      </TextoDoBotaoAtividadeStyle>
    </BotaoAtividadeDiaContainerStyle>
  );
}
