import React from "react";
import { Link } from "react-router-dom";
import Image from "../../ImageLogo/Image";

function NavbarElement({
  to,
  funcao,
  className,
  style,
  src,
  alt,
  srcDesktop,
  altDesktop,
}) {
  return (
    <>
      <Link
        to={to ? to : "#"}
        onClick={funcao}
        className={className}
        style={style}
      >
        <Image
          src={src}
          alt={alt}
          srcDesktop={srcDesktop}
          altDesktop={altDesktop}
        />
      </Link>
    </>
  );
}

export default NavbarElement;
