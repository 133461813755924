import React from "react";

const CheckExecutado = ({ label, height }) => {
  return (
    <svg
      aria-label={label}
      //width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48578 0 0 4.48578 0 10C0 15.5142 4.48578 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48578 15.5142 0 10 0Z"
        fill="#03E090"
      />
      <svg
        x="5"
        y="6"
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0682 1.88086L4.65152 7.29742C4.48901 7.45993 4.2757 7.54172 4.06238 7.54172C3.84906 7.54172 3.63574 7.45993 3.47324 7.29742L0.764954 4.58914C0.439026 4.26337 0.439026 3.73663 0.764954 3.41086C1.09073 3.08493 1.61731 3.08493 1.94324 3.41086L4.06238 5.53L8.88995 0.702576C9.21573 0.376648 9.74231 0.376648 10.0682 0.702576C10.394 1.02835 10.394 1.55493 10.0682 1.88086Z"
          fill="#FAFAFA"
        />
      </svg>
    </svg>
  );
};

export default CheckExecutado;
