import styled from "styled-components";

export const LoginRegisterContainerStyle = styled.div`
  width: 100%;
  min-width: 100%;

  .LoginRegister__container {
    margin-top: 0;
    padding: 0em 1em;
    margin-bottom: 1em;
  }
`;

export const WeexUserContainerStyle = styled.div`
  border: none;
  margin-top: 1em;
  padding: 1em;
  margin-bottom: 1em;
`;

export const ModoStyle = styled.ul`
  li {
    font-weight: 700;
    font-size: 1rem;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1rem;
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
`;

export const RegisterForm = styled.form`
  label,
  .strongSpan {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  a,
  .button-link {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  label&:hover {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  display: flex;
  flex-direction: column;

  .registerForm__fieldset--register {
    text-align: center;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .termsConditionais {
    text-align: center;
  }

  .botao-ja-possuo-cadastro-container {
    width: auto;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin: 0 21vw;
    .registerForm__fieldset {
      display: flex;
      gap: 10%;

      /* Aplica o tamanho de 50% nos inputs
      apenas dentro do formulário de registro */
      div {
        width: 45%;
      }
    }

    .termsConditionais {
      text-align: left;
    }

    .botao-ja-possuo-cadastro-container {
      width: 55%;
    }

    .registerForm__fieldset--register {
      padding-left: 15%;
      padding-right: 15%;
    }
  }
  /* #endregion DESKTOP */
`;

export const LoginCampaingStyle = styled.div`
  label {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  a,
  .button-link {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
    font-size: 1rem;
  }
  .espacamento-inicio {
    margin-top: 1.5rem;
  }
  label &&:hover {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  .weex-l-color {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }
  .weex-l-button-reenviar {
    width: 100%;
  }

  .weex-l-button-reenviar &:disabled {
    cursor: not-allowed;
    filter: opacity(0.5);
  }
`;
