import styled from "styled-components";

export const WeexHeaderStyle = styled.header`
  display: flex;
  flex-direction: column;

  .weex-l-navbar {
    display: flex;
    align-items: center;
    padding: 0.625rem;
  }

  .weex-l-navbar-com-trofeu {
    justify-content: space-between;
  }

  .weex-l-campanha-logo-empresa-centro--maxTamanho {
    max-width: 30%;
  }

  .weex-l-campanha-logo-empresa-centro {
    text-align: center;
  }

  .weex-l-campanha-nome-empresa-container {
    margin-top: 1em;
  }

  .weex-l-campanha-nome-empresa--titulo {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .weex-l-campanha-logo-empresa--subtitulo {
    text-align: center;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }

  .weex-l-campanha-nome-empresa--cor-deslogado {
    color: ${(props) => {
      return props.theme.textosCorFundoPrimario;
    }};
  }

  .weex-l-menu-desktop {
    display: flex;
    align-items: center;
    gap: 3.8rem;
    justify-content: space-between;
  }

  .weex-l-menu-burguer-icon {
    max-width: 1.25rem;
    position: relative;
    z-index: 102;
  }

  .weex-l-menu {
    background: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    position: absolute;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
  }

  .weex-l-menu__content {
    margin: 6em 2em 2em;
  }

  .weex-l-menu__list {
    text-align: center;
  }

  .weex-l-menu-item {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 0.875rem;
    font-weight: 700;
    color: ${(props) => {
      return props.theme.textosCorFundoSecundario;
    }};
  }

  .weex-l-menu-item--text-capitalized {
    text-transform: capitalize;
  }

  .weex-l-menu-item--botom-space {
    margin-bottom: 1.25rem;
  }

  .weex-l-menu-link {
    &:active {
      filter: opacity(0.5);
    }

    &:hover {
      filter: opacity(0.5);
    }
  }

  .weex-l-menu-usuario {
    pointer-events: none;
    font-size: 0.75em;
  }

  .weex-l-menu-item--sair {
    filter: opacity(0.7);
  }

  .weex-l-versao {
    font-size: 0.7rem;
    text-transform: lowercase;
  }

  .weex-l-menu-link--max-tamanho {
    max-width: 1.875rem;
  }

  /* #region DESKTOP */

  /* 75rem é o equivalente a 1200px */

  @media (min-width: 75rem) {
    .weex-l-navbar {
      height: 6.25rem;
    }

    .weex-l-navbar-com-trofeu {
      width: 100%;
    }

    .weex-l-campanha-nome-empresa-desktop-container {
      width: 30%;
      margin-left: 1%;
      margin-bottom: 1rem;
    }

    .weex-l-campanha-nome-empresa {
      text-align: left;
    }

    .weex-l-campanha-nome-empresa--center {
      text-align: center;
    }

    .weex-l-campanha-logo-empresa-centro--maxTamanho img {
      height: auto;
      max-width: 12vw;
      max-height: 10vh;
      margin-right: 4em;
    }

    .weex-l-campanha-logo-empresa-centro--naoLogado {
      max-width: 15vw;
    }

    .weex-l-campanha-nome-empresa-container__respiro-lateral {
      margin-left: 25vw;
      margin-right: 25vw;
    }
  }

  /* #endregion DESKTOP */
`;
