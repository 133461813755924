import React from "react";
import { PagesHeaderStyle } from "./style";

function PagesHeader({ title, subtitle, subtitleSecondPart }) {
  return (
    <>
      <PagesHeaderStyle id="page-header">
        <h1 className="weex-l-page-header-title">{title}</h1>
        <h2 className="weex-l-page-header-subtitle">{subtitle}</h2>
        {subtitleSecondPart && (
          <h2 className="weex-l-page-header-subtitle">{subtitleSecondPart}</h2>
        )}
      </PagesHeaderStyle>
    </>
  );
}

export default PagesHeader;
