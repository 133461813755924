import React from "react";
import { useStore, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CampanhaService from "../../../services/CampanhaService";

/**
 * @param {Object} props
 * @param {string} props.versao
 * @param {function(): void} props.toggle
 * @param {function(): void} props.sair
 * @param {Date} props.now
 */
function SuspendedMenuMobile({ now, toggle, sair, versao }) {
  const i18n = useStore().getState().i18n;

  const usuariologado = useSelector((state) => state.usuariologado);

  let location = useLocation();

  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const notLegalTerm = !usuariologado?.legalTerm;
  const locationPathname = location?.pathname;
  const naoMostrarInfoParticipante =
    campanhaService.naoMostrarInfoParticipante();
  const infoParticipante = campanhaService.getInformacaoParticipante();
  const beginDate = campanhaService.getBeginDate();
  let tipoInscricao;
  let legalTerm;
  let requiredInterests;
  let comoFunciona;
  if (campanhaCorrente) {
    tipoInscricao = campanhaCorrente.tipoInscricao;
    legalTerm = campanhaCorrente.legalTerm;
    requiredInterests = campanhaCorrente.requiredInterests;
    comoFunciona = campanhaCorrente.comoFunciona;
  }

  return (
    <>
      <div className="weex-header-menu">
        <div className="weex-header-menu-content">
          <ul>
            {naoMostrarInfoParticipante ? (
              ""
            ) : (
              <li>
                <p style={{ pointerEvents: "none", fontSize: "0.75em" }}>
                  {`${i18n.message(
                    "geral.menu.usuario",
                    "Participante",
                  )}  : ${infoParticipante}`}
                </p>
              </li>
            )}

            {(tipoInscricao === "ELEGIBILIDADE" &&
              legalTerm &&
              !notLegalTerm) ||
            (requiredInterests === true &&
              locationPathname === "/interests" &&
              beginDate <= now) ? null : (
              <li>
                <Link to="/" onClick={toggle}>
                  {i18n.message("geral.menu.inicio", "Início")}
                </Link>
              </li>
            )}
            <li>
              {comoFunciona && comoFunciona.helpMessage ? (
                <Link to="/help" onClick={toggle}>
                  {i18n.message("geral.menu.comoFunciona", "Como funciona")}
                </Link>
              ) : (
                ""
              )}
            </li>
            <li>
              <button className="button-link" type="button" onClick={sair}>
                {i18n.message("geral.menu.sair", "Sair")}
              </button>
            </li>
            <li>
              <p className="versao">{`v. ${versao}`}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SuspendedMenuMobile;
