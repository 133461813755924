import React, { useState } from "react";
import MarcadorProgresss from "../../marcadorProgresso/MarcadorProgresso";
import EnunciadoQuestao from "./EnunciadoQuestao";
import ImagemQuestao from "./ImagemQuestao";
import FeedBackResposta from "./FeedBackResposta";
import FatoFakeRevisaoBotoes from "./FatoFakeRevisaoBotoes";

/**
 * @param {Object} props
 * @param {questoes} props.questoes
 * @param {respostas} props.respostas
 * @param {() => void} props.onConcluir
 */
export default function CardFatoFakeRevisao({
  questoes,
  respostas,
  onConcluir,
}) {
  const [indiceQuestao, setIndiceQuestao] = useState(0);

  const questaoAtual = questoes[indiceQuestao];
  const respostaAtual = respostas[indiceQuestao];
  const [firstLoad, setFirstLoad] = useState(true);

  function avancarResposta() {
    if (indiceQuestao < questoes.length - 1) {
      setIndiceQuestao((prev) => prev + 1);
      setFirstLoad(false);
    }
  }

  function voltarResposta() {
    if (indiceQuestao > 0) {
      setIndiceQuestao((prev) => prev - 1);
      setFirstLoad(false);
    }
  }

  return (
    <div className="weex-l-dinamica-FatoFake-card">
      <div className="weex-l-dinamica-FatoFake-card weex-l-FatoFakeRevisao-content weex-l-dinamica-FatoFake-card--border">
        {questaoAtual?.picture && <ImagemQuestao questao={questaoAtual} />}
        {firstLoad ? (
          <>
            <EnunciadoQuestao questao={questaoAtual} />
            <FeedBackResposta questao={questaoAtual} resposta={respostaAtual} />
          </>
        ) : (
          <div role="alert">
            <EnunciadoQuestao questao={questaoAtual} />
            <FeedBackResposta questao={questaoAtual} resposta={respostaAtual} />
          </div>
        )}
        <FatoFakeRevisaoBotoes
          indiceQuestao={indiceQuestao}
          totalQuestoes={questoes.length}
          onAvancar={avancarResposta}
          onVoltar={voltarResposta}
          onConcluir={onConcluir}
        />
      </div>
      <MarcadorProgresss
        indiceAtual={indiceQuestao}
        totalDeItems={questoes.length}
      />
    </div>
  );
}
