import styled from "styled-components";

export const PlacarRespostasATStyle = styled.div`
  .weex-l-acerteTempo-placar--titulo {
    font-weight: 800;
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }

  .weex-l-acerteTempo-placar-btns-container {
    display: flex;
    flex-direction: column;
  }

  .weex-l-acerteTempo-placar-btns-espacamentoSuperior {
    margin-top: 2rem;
  }

  .weex-l-acerteTempo-placar-pontuacoes {
    display: flex;
    gap: 1.5rem;
    align-items: baseline;
  }

  .weex-l-acerteTempo-placar-pontuacoes--numeracao {
    font-size: 1.3rem;
    text-align: left;
    margin-bottom: 1rem;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-acerteTempo-placar--titulo {
      font-size: 1.2rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    .weex-l-acerteTempo-placar-btns-container {
      flex-direction: row;
      gap: 3rem;
      padding-left: 12rem;
    }

    .weex-l-acerteTempo-placar-pontuacoes {
      gap: 5rem;
    }

    .weex-l-acerteTempo-placar-pontuacoes--numeracao {
      font-size: 1.5rem;
      font-weight: 800;
    }

    .weex-l-acerteTempo-placar--listaResultadosRespiroLateral {
      padding-left: 8rem;
      padding-right: 14rem;
    }
  }
  /* #endregion DESKTOP */
`;
