import React from "react";
import { useStore } from "react-redux";
import { CabecalhoPaginasStyle } from "./style";

/**
 * Nova versão do cabeçalho a ser usado em páginas e dinâmicas.
 * Ele faz parte do novo design que está sendo apresentado em novas telas
 * feitas pelo Yan da Weex.
 * @param {*} props
 * @param {function(event): void} props.voltar função que retorna para a página de atividades
 * @param {string} props.titulo título da página
 * @param {ReactNode} props.children children onde deve ser adicionado o ícone da página. Para exemplo ver arquivo Convite.js
 * @returns
 */
function CabecalhoPaginas({ voltar, titulo, children }) {
  const i18n = useStore().getState().i18n;

  const cabecalhoVoltar = () => {
    voltar();
  };

  const botaoVoltar = () => {
    return (
      <div className="weex-l-cabecalho-voltarAltura">
        <button
          onClick={cabecalhoVoltar}
          className="button is-text weex-l-cabecalho-btn-voltar"
          aria-label={i18n.message(
            "aria.label.geral.mensagem.voltar.atividades",
            "Volta para a página de atividades.",
          )}
        >
          <span className="icon weex-l-cabecalho-custom-color-icon">
            <span className="fa-stack fa-sm">
              <i className="far fa-circle fa-stack-2x "></i>
              <i className="fas fa-chevron-left fa-stack-1x "></i>
            </span>
          </span>
          <span className="weex-l-cabecalho-btn-text">Início</span>
        </button>
      </div>
    );
  };

  return (
    <>
      <CabecalhoPaginasStyle>
        {botaoVoltar()}
        <div className="weex-l-cabecalho">
          <div className="weex-l-cabecalho--filhos">{children}</div>
          <div className="weex-l-cabecalho--filhos">
            <h1 className="weex-l-cabecalho-titulo">{titulo}</h1>
          </div>
        </div>
      </CabecalhoPaginasStyle>
    </>
  );
}

export default CabecalhoPaginas;
