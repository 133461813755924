import React, { useEffect, useMemo, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import Contador from "../../comps/contador/Contador";
import MensagemVideoModal from "../../comps/weexModais/MensagemVideoModal";
import CampanhaService from "../../services/CampanhaService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import { HomeStyle } from "./style";
import UsuarioService from "../../services/UsuarioService";

function Home() {
  const mensagemVideoService = new MensagemVideoService();
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  const campanhaService = useMemo(() => new CampanhaService(), []);
  const campanha = campanhaService.campanhaCorrente();
  const [mensagemDeVideo, setMensagemDeVideo] = useState([]);
  const [mostrarModalVideo, setMostrarModalVideo] = useState(false);
  const [erro, setErro] = useState(null);
  const usuarioService = new UsuarioService();
  const adminOrGestor = usuarioService.isAdmin() || usuarioService.isGestor();

  useEffect(() => {
    const sincronizarCampanha = async () => {
      try {
        await campanhaService.useSincronizarCampanha();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha();
    let temaService = new TemaService(campanhaService.campanhaCorrente());
    temaService.aplicarTema("HOME");

    mensagemVideoService.obterMensagemDeVideo((error, sucesso) => {
      if (error) {
        setErro(
          i18n.message(
            "atividades.erro.video.prefixo",
            "Erro ao obter mensagem de video da campanha: ",
          ) + erro,
        );
        return;
      }
      if (sucesso) {
        setMensagemDeVideo(sucesso);
        sucesso.length > 0
          ? setMostrarModalVideo(true)
          : setMostrarModalVideo(false);
      }
    });

    if (adminOrGestor) {
      navigate("/atividades");
      return;
    }

    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        return erro;
      }
      if (sucesso) {
        if (sucesso.length > 0) {
          dispatch({ type: "campanhaComecou", payload: true });
          navigate("/atividades");
        }
      }
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = campanha.startDate.split("/");
  let horas = data[2].split(" ");
  data[2] = horas[0];
  horas = horas[1].split(":");

  const inicioCampanhaContador = new Date(
    parseInt(data[2]),
    parseInt(data[1]) - 1,
    parseInt(data[0]),
    parseInt(horas[0]),
    parseInt(horas[1]),
    parseInt(horas[2]),
  ).getTime();

  function handleMensagemDeVideo() {
    setMostrarModalVideo(false);
  }

  function adicionarHistoricoNavegacao() {
    if (mensagemDeVideo && mensagemDeVideo[0]?.video.code) {
      navigate("/video-message/" + mensagemDeVideo[0].video.code);
    }
  }
  return (
    <HomeStyle>
      {mostrarModalVideo && (
        <MensagemVideoModal
          fecharModalCallback={handleMensagemDeVideo}
          adicionarHistoricoNavegacao={adicionarHistoricoNavegacao}
          uuid={mensagemDeVideo[0].uuid}
          titulo={mensagemDeVideo[0].title}
          imagemSrc={mensagemDeVideo[0].image}
          mensagem={mensagemDeVideo[0].message}
          tipo={mensagemDeVideo[0].type}
        />
      )}
      <div className="weex-content-home">
        <div className="info-campanha-container-interesse">
          <br />
          <h1>{i18n.message("home.datainicio", "faltam")}</h1>
        </div>
        <div className="contador-campanha-container-interesse">
          <div>
            <Contador
              inicioCampanha={inicioCampanhaContador}
              home={true}
            ></Contador>
          </div>

          <div className="info-campanha-container-interesse">
            <br />
            <h3>{i18n.message("home.dataFim", "para começar!")}</h3>
          </div>
          <br></br>
          <div className="weex-l-contador-botoes">
            {campanha &&
            campanha?.comoFunciona &&
            campanha.comoFunciona?.helpMessage ? (
              <div className="content-botoes">
                <BotaoPrimario
                  classeComplementar="home-btn-padding-como-funciona"
                  nome={i18n.message(
                    "home.instrucoes",
                    "CONFIRA COMO FUNCIONA",
                  )}
                  funcao={() => navigate("/help")}
                  ariaStatusMessage={i18n.message(
                    "aria.status.home.botaoconfiracomofunciona",
                    "Botao confira como funciona clicado.",
                  )}
                />
              </div>
            ) : (
              ""
            )}

            {campanha.requiredInterests ? (
              <div className="content-botoes">
                <BotaoSecundario
                  classeComplementar="home-btn-padding-interesses"
                  nome={i18n.message(
                    "home.interesse.definir",
                    "DEFINA SEUS INTERESSES",
                  )}
                  funcao={() => navigate("/interests")}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </HomeStyle>
  );
}

export default Home;
