import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import TemaService from "../../services/TemaService";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { MissaoCumpridaFeedbackModalStyle } from "./StyleMissaoCumpridaFeedback";
import { WeexModalDialog } from "./WeexModalDialog";

function ModalConclusao({ pontos, text }) {
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const [processando, setProcessando] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(true);
  const dialogRef = useRef(null);

  useEffect(() => {
    let campanhaService = new CampanhaService();
    let temaService = new TemaService(campanhaService.campanhaCorrente());
    temaService.aplicarTema("HOME");
    if (!processando) {
      setProcessando(true);
      lottie.loadAnimation({
        container: document.getElementById(
          "missao-cumprida-container-confetes",
        ),
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/confete.json",
      });
    }
  }, [processando]);

  function voltar() {
    dialogRef.current.close();
    setMostrarModal(false);
    navigate("/atividades");
  }

  return mostrarModal ? (
    <WeexModalDialog dialogRef={dialogRef} onPressESC={voltar}>
      <div className="modal is-active animations-popIn">
        <div className="modal-background weex-modal-background"></div>
        <div
          className="modal-card weex-modal-card"
          style={{ padding: "20px", paddingBottom: "50px" }}
        >
          <header className="modal-card-head weex-modal-card-header">
            <button
              className="close-btn"
              style={{ zIndex: "1003" }}
              aria-label={i18n.message(
                "aria.label.fechar.modal",
                "Fecha a janela e volta para a página atividades.",
              )}
              onClick={voltar}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message(
                  "alt.button.imagem.fechar",
                  "ícone em formato de um X indicando o encerramento de uma ação.",
                )}
              />
            </button>
          </header>
          <MissaoCumpridaFeedbackModalStyle>
            <div
              id="missao-cumprida-container-confetes"
              className="missao-cumprida-container-confetes foreground"
            ></div>
            <div>
              <div className="modal-card-title wex-modal-card-img">
                <img
                  src="/new_obrigado.svg"
                  alt={i18n.message(
                    "alt.feedback.imagem.obrigado",
                    "Imagem de fogos de artificio agradecendo por ter respondido ao feedback",
                  )}
                  className="image is-2by1 retirar-padding"
                ></img>
              </div>
            </div>
            {text()}

            {pontos && pontos > 0 && (
              <div className="pontuacao">
                <div className="pontos-obtidos">
                  {pontos}{" "}
                  {pontos === 1
                    ? i18n.message("geral.pt", "PT")
                    : i18n.message("geral.pts", "PTS")}
                </div>
                <br></br>
              </div>
            )}
            <div style={{ marginTop: "35px", marginBottom: "2rem" }}>
              <BotaoPrimario
                nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                ariaLabelMsg={i18n.message(
                  "aria.label.geral.mensagem.voltar.atividades",
                  "Volta para a página de atividades.",
                )}
                funcao={voltar}
                classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
                ariaStatusMessage={i18n.message(
                  "aria.status.mensagemreutilizavel.botaovoltarclicado",
                  "Botão voltar clicado.",
                )}
              />
            </div>
          </MissaoCumpridaFeedbackModalStyle>
        </div>
      </div>
    </WeexModalDialog>
  ) : (
    <></>
  );
}

export default ModalConclusao;
