import styled from "styled-components";

export const WeexDesktopModeStyle = styled.div`
  display: none;
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    display: block;
  }
  /* #endregion DESKTOP */
`;

export const WeexMobileModeStyle = styled.div`
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    display: none;
  }
  .alinhamento {
    display: flex;
    flex-direction: column;
  }
  /* #endregion DESKTOP */
`;
