import React from "react";
import { useStore } from "react-redux";

/**
 * @param {Object} props
 * @param {string} props.mensagem valor do formulário
 * @param {boolean} props.isDesktopMode se aplicativo esta no modo desktop ou não
 * @param {function(React.ChangeEventHandler<HTMLTextAreaElement): void} props.callback onChange onde será setada o valor da mensagem
 */
function Mensagem({ mensagem, callback, isDesktopMode }) {
  const i18n = useStore().getState().i18n;

  return (
    <div className="is-flex-tablet is-justify-content-center">
      <div
        className={
          !isDesktopMode
            ? ""
            : "feedback-conteiner-width-desktop text-area-feedback  "
        }
      >
        <textarea
          value={mensagem}
          className="textarea"
          onChange={callback}
          rows="5"
          maxLength="250"
          aria-label={i18n.message(
            "aria.label.feedback.comentario",
            "campo para escrever um comentário (opcional).",
          )}
        />
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "14px",
            paddingTop: "0",
            marginTop: "0",
          }}
        >
          <span
            style={{
              fontSize: "0.8em",
              color: 250 - mensagem.length <= 20 ? "red" : "black",
            }}
          >
            {mensagem ? mensagem.length : 0}/250
          </span>
        </div>
      </div>
    </div>
  );
}

export default Mensagem;
