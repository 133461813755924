import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AtividadesService from "../../services/AtividadesService";
function CallbackGame(props) {
  let { codigo } = useParams();
  let navigate = useNavigate();
  const [erro, setErro] = useState(null);
  let service = new AtividadesService();
  let actionGame = localStorage.getItem("actionGame");
  service.finalizarAtividade(
    codigo,
    actionGame,
    "GAME",
    {},
    (erro, sucesso) => {
      if (erro) {
        setErro("Erro ao obter ação");
      } else if (sucesso) {
        navigate("/missionFinished/" + codigo + "/GAME/" + actionGame);
      }
    },
  );

  return <div>{erro}</div>;
}

export default CallbackGame;
