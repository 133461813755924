import styled from "styled-components";

export const CardDiaSemanaAgendaStyle = styled.div`
  border-radius: 0.5rem;
  margin-bottom: 1.25rem;
  background-color: #ffffffff;
  max-width: 100vw;
  border: 0.063rem solid
    ${(props) => {
      if (props.$habilitado === true) {
        return props.theme.fundoSecundario;
      } else if (props.$habilitado === false) {
        return props.theme.cardDoDiaAgendaBordaDataDesabilitado || `#DFDDE3`;
      } else {
        return "#FFFFFF";
      }
    }};

  .weex-l-card-header-container {
    display: flex;
    max-height: 5rem;
  }

  .weex-l-card-header-container--noDate {
    margin-left: 4%;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    min-height: 27rem;
    width: 26vw;
    max-height: 27rem;

    .weex-l-card-header-container {
      max-height: 4rem;
    }
  }
  /* #endregion DESKTOP */
`;
