import styled from "styled-components";

export const WeexProgressContainerStyle = styled.div`
  width: ${(props) => {
    return props.$isAgendaProlongada ? "200%" : "100%";
  }};

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    width: 100%;
  }
  /* #endregion DESKTOP */
`;
