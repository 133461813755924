import styled from "styled-components";

export const ExplanationStyle = styled.div`
  .weex-l-acerteTempo-explicacao-acerto strong {
    color: #00e291;
    font-size: 1.2rem;
  }

  .weex-l-acerteTempo-explicacao-acerto {
    color: #00e291;
    font-size: 1.2rem;
  }

  .weex-l-acerteTempo-explicacao-error strong {
    color: #fe001a;
    font-size: 1.2rem;
  }

  .weex-l-acerteTempo-explicacao-error {
    color: #fe001a;
    font-size: 1.2rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;
