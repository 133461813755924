import React from "react";
import { WeexMobileModeStyle } from "./style";

/**
 * @param {Object} props
 * @param {String} props.asHtmlTag renderiza o componente com a tag que desejar caso necessário
 * @param {String} props.ariaDisabled caso o wrapper não deva ser habilitado  ou lido por leitores de tela, valor padrão false
 * @param {String} props.ariaHidden caso o wrapper não deva ser habilitado  ou lido por leitores de tela, valor padrão false
 * @param {Object} props.style caso o componente tenha que ser renderizado com um style especifico
 * @param {Object} props.className caso o componente tenha que ser renderizado com uma classe especifica
 * @param {JSX.Element} props.children
 */
function WeexMobileMode({
  asHtmlTag,
  ariaDisabled,
  ariaHidden,
  style,
  className,
  children,
}) {
  let ariaDisabledDefault = ariaDisabled ? ariaDisabled : "false";
  let ariaHiddenDefault = ariaHidden ? ariaHidden : "false";
  return (
    <WeexMobileModeStyle
      as={asHtmlTag}
      className={`${className}`}
      aria-disabled={ariaDisabledDefault}
      aria-hidden={ariaHiddenDefault}
      style={style}
    >
      {children}
    </WeexMobileModeStyle>
  );
}

export default WeexMobileMode;
