import styled from "styled-components";

export const AtividadesStyle = styled.div`
  .weex-l-atividade-agenda-container {
    margin-top: 4vh;
  }

  .weex-l-atividade-botao-feedback-container {
    padding: 0 0 1em;
    text-align: center;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-atividade-agenda-container {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 2em;
    }

    .weex-l-atividade-card-container-desktop {
      margin-left: 2em;
    }

    .weex-l-atividade-botao-feedback-container {
      padding: 2em 0;
      text-align: center;
      width: 23%;
      margin: auto;
    }
  }
  /* #endregion DESKTOP */
`;
