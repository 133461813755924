import React from "react";

/**
 * @param {Object} props
 * @param {FatoFakeQuestao} props.questao
 */
export default function EnunciadoQuestao({ questao }) {
  return (
    <div className="weex-l-dinamica-FatoFake-card-content-respiro">
      <div
        className="weex-l-dinamica-FatoFake-card-content__markup"
        dangerouslySetInnerHTML={{ __html: questao?.enunciation }}
      />
    </div>
  );
}
