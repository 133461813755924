import React, { useEffect, useCallback, useState } from "react";
import { FlipClockStyle } from "./style";
import ContainerFlip from "./ContainerFlip";

const Contador = (props) => {
  const [state, setState] = useState({
    dias: 0,
    diasShuffle: true,
    horas: 0,
    horasShuffle: true,
    minutos: 0,
    minutosShuffle: true,
  });

  const updateTime = useCallback(() => {
    let horaCorrente = new Date();
    let horaLimite = new Date(props.inicioCampanha);
    let tempoRestante = horaLimite.getTime() - horaCorrente.getTime();

    if (tempoRestante < 0) {
      tempoRestante = 0;
    }

    let tempoTemp = tempoRestante;
    const dias = parseInt(tempoTemp / 86400000);
    tempoTemp = tempoTemp % 86400000;
    const horas = parseInt(tempoTemp / 3600000);
    tempoTemp = tempoTemp % 3600000;
    const minutos = parseInt(tempoTemp / 60000);

    if (dias !== state.dias) {
      const diasSuffle = !state.diasSuffle;
      setState((prevState) => ({
        ...prevState,
        dias,
        diasSuffle,
      }));
    }

    if (horas !== state.horas) {
      const horasShuffle = !state.horasShuffle;
      setState((prevState) => ({
        ...prevState,
        horas,
        horasShuffle,
      }));
    }

    if (minutos !== state.minutos) {
      const minutosShuffle = !state.minutosShuffle;
      setState((prevState) => ({
        ...prevState,
        minutos,
        minutosShuffle,
      }));
    }
  }, [props, state]);

  useEffect(() => {
    const timerID = setInterval(() => updateTime(), 1000);
    return () => {
      clearInterval(timerID);
    };
  }, [updateTime]);

  const { dias, horas, minutos, diasSuffle, horasShuffle, minutosShuffle } =
    state;

  return (
    <FlipClockStyle>
      <ContainerFlip
        unit={"dias"}
        digit={dias}
        shuffle={diasSuffle}
        home={props.home}
      />
      <h2 className="separador">:</h2>
      <ContainerFlip
        unit={"horas"}
        digit={horas}
        shuffle={horasShuffle}
        home={props.home}
      />
      <h2 className="separador">:</h2>
      <ContainerFlip
        unit={"minutos"}
        digit={minutos}
        shuffle={minutosShuffle}
        home={props.home}
      />
    </FlipClockStyle>
  );
};

export default Contador;
