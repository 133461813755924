import React, { useEffect, useRef, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CardNovosHabitos from "../../comps/cardNovosHabitos/CardNovosHabitos";
import BotaoAsALink from "../../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import WeexModal from "../../comps/weexModais/WeexModal";
import AgendaService from "../../services/AgendaService";
import AtividadesService from "../../services/AtividadesService";
import LoaderService from "../../services/LoaderService";
import { NovosHabitosStyled } from "./NovosHabitosStyled";

function NovosHabitos() {
  /*   const css = {
    width: "100%",
  }; */
  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();
  const location = useLocation();
  let { codigo, codigoAtividade } = useParams();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const loaderService = new LoaderService();
  const [erro, setErro] = useState(null);
  const [novoHabito, setNovoHabito] = useState();
  const [indiceNovoHabito, setIndiceNovoHabito] = useState(0);
  const [marcadorProgresso, setMarcadorProgresso] = useState();
  const [primeiraExecucao, setPrimeiraExecucao] = useState(true);
  const [bloquearBotao, setBloquearBotao] = useState(true);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const [key, setKey] = useState(0);
  const ref = useRef();
  const effectRan = useRef(false);

  useEffect(() => {
    //TODO - No modo de desenvolvimento do React 18, os componentes podem ser renderizados duas vezes para detectar efeitos colaterais inseguros.
    if (effectRan.current === false) {
      effectRan.current = true;
      iniciarAtividade();
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iniciarAtividade = () => {
    atividadesService.iniciarAtividade(
      codigo,
      codigoAtividade,
      (erro, atividade) => {
        if (atividade) {
          setNovoHabito(atividade.newHabit);
          if (atividade?.dateFinished !== null) {
            atualizarAgenda(atividade);
          }

          let dimensoes =
            atividade.newHabit.cards.length < 10
              ? 4
              : 40 / atividade.newHabit.cards.length;

          if (isDesktopMode) {
            dimensoes = dimensoes / 4 + "vw";
          } else {
            dimensoes = dimensoes + "vw";
          }

          if (atividade.newHabit.cards.length > 1) {
            loaderService.load(atividade.newHabit.cards[1].picture);
          }

          let progresso = atividade.newHabit.cards.map((a, i) => {
            return indiceNovoHabito >= 0 ? (
              i <= indiceNovoHabito ? (
                <div
                  className="marcador marcador-concluido"
                  style={{ width: dimensoes, height: dimensoes }}
                  key={i}
                ></div>
              ) : (
                <div
                  className="marcador"
                  style={{ width: dimensoes, height: dimensoes }}
                  key={i}
                ></div>
              )
            ) : null;
          });
          setMarcadorProgresso(progresso);
          setBloquearBotao(false);
        }
        if (erro) {
          if (
            erro?.response &&
            erro?.response?.data &&
            erro?.response?.data?.message
          ) {
            setErro({
              titulo: i18n.message(
                "dinamica.erro.iniciar.titulo",
                "Erro ao iniciar atividade",
              ),
              mensagem: erro.response.data.message,
              voltarAgenda: true,
            });
            return;
          }
          setErro({
            titulo: i18n.message(
              "dinamica.erro.iniciar.titulo",
              "Erro ao iniciar atividade",
            ),
            mensagem: i18n.message(
              "dinamica.erro.iniciar.mensagem",
              "Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
            voltarAgenda: true,
          });
          return;
        }
      },
    );
  };

  const atualizarAgenda = (atividade) => {
    agendaService.atualizarAgenda(
      codigo,
      codigoAtividade,
      "NEW_HABIT",
      atividade.newHabit.cards.length,
      atividade.score,
      (erro, sucesso) => {
        if (sucesso) {
          setPrimeiraExecucao(false);
          return;
        }
        if (erro) {
          setErro({
            titulo: i18n.message(
              "dinamica.erro.jaexecutada.titulo",
              "Erro ao exibir atividade já executada",
            ),
            mensagem: i18n.message(
              "dinamica.erro.jaexecutada.mensagem",
              "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
            ),
            voltarAgenda: true,
          });
          return;
        }
      },
    );
  };

  const callbackModal = () => {
    setErro(null);
    if (erro.voltarAgenda) {
      navigate("/atividades");
    }
  };

  const finalizar = () => {
    if (!bloquearBotao) {
      setBloquearBotao(true);
      window.scrollTo(0, 0);
      if (primeiraExecucao) {
        atividadesService.finalizarAtividade(
          codigo,
          codigoAtividade,
          "NEW_HABIT",
          {},
          (erroFinalizarAtividade, atividade) => {
            if (atividade) {
              navigate(
                `/missionFinished/${codigo}/NEW_HABIT/${codigoAtividade}`,
                {
                  state: atividade,
                },
              );
              setBloquearBotao(false);
            }
            if (erroFinalizarAtividade) {
              if (
                erroFinalizarAtividade.response &&
                erroFinalizarAtividade.response.data &&
                erroFinalizarAtividade.response.data.message
              ) {
                setErro({
                  titulo: i18n.message(
                    "dinamica.erro.finalizar.titulo",
                    "Erro ao finalizar atividade",
                  ),
                  mensagem: erroFinalizarAtividade.response.data.message,
                  voltarAgenda: false,
                });
              } else {
                setErro({
                  titulo: i18n.message(
                    "dinamica.erro.finalizar.titulo",
                    "Erro ao finalizar atividade",
                  ),
                  mensagem: i18n.message(
                    "dinamica.erro.finalizar.mensagem",
                    "Entre em contato com o suporte ou tente novamente mais tarde.",
                  ),
                  voltarAgenda: false,
                });
              }
              setBloquearBotao(false);
            }
          },
        );
      } else {
        atividadesService.finalizarAtividade(
          codigo,
          codigoAtividade,
          "NEW_HABIT",
          {},
          (erroFinalizarAtividade, sucesso) => {
            if (sucesso) {
              setBloquearBotao(false);
              navigate("/");
            } else if (erroFinalizarAtividade) {
              setBloquearBotao(false);
              setErro({
                titulo: i18n.message(
                  "dinamica.erro.finalizar.titulo",
                  "Erro ao finalizar atividade",
                ),
                mensagem: i18n.message(
                  "dinamica.erro.finalizar.mensagem",
                  "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
                ),
                voltarAgenda: false,
              });
            }
          },
        );
      }
    }
  };

  const proximoCard = () => {
    ref.current.scrollTo(0, 0);
    window.scrollTo(0, 0);
    let indice = indiceNovoHabito + 1;
    if (indice === novoHabito.cards.length) {
      return;
    }
    if (novoHabito.cards.length > indice + 1) {
      loaderService.load(novoHabito.cards[indice + 1].picture);
    }
    setIndiceNovoHabito(indice);
    let dimensoes =
      novoHabito.cards.length < 10 ? 4 : 40 / novoHabito.cards.length;
    if (isDesktopMode) {
      dimensoes = dimensoes / 4 + "vw";
    } else {
      dimensoes = dimensoes + "vw";
    }
    let progresso = novoHabito.cards.map((a, i) => {
      return i <= indice ? (
        <div
          className="marcador marcador-concluido"
          style={{ width: dimensoes, height: dimensoes }}
          key={i}
        ></div>
      ) : (
        <div
          className="marcador"
          style={{ width: dimensoes, height: dimensoes }}
          key={i}
        ></div>
      );
    });
    setMarcadorProgresso(progresso);
    setKey(key + 1);
  };

  return (
    <NovosHabitosStyled>
      <div className="desktop-novos-habitos">
        {erro != null ? (
          <WeexModal
            fecharModalCallback={callbackModal}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        ) : null}
        {isDesktopMode ? (
          <div className="botao-voltar-desktop">
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message(
                "aria.label.geral.mensagem.voltar.atividades",
                "Volta para a página de atividades.",
              )}
              funcao={() => {
                navigate("/home");
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div className="dinamica-container">
          {novoHabito != null ? (
            <div className="desktop-content">
              <div className="desktop-content-left">
                <DinamicasHeader
                  title={
                    novoHabito?.cards[indiceNovoHabito]?.title ||
                    novoHabito?.title ||
                    novoHabito?.name
                  }
                  dinamicaNome={i18n.message(
                    "dinamica.novoshabitos.nome",
                    "Novos Hábitos",
                  )}
                  dinamicaAlt={i18n.message(
                    "alt.dinamica.novoshabitos",
                    "Ícone de uma cabeça com uma engrenagem dentro dando a entender que está pensando.",
                  )}
                  dinamicaImg={
                    location && location.state && location.state.icon
                      ? location.state.icon
                      : "/habito.png"
                  }
                />
              </div>
              <div className="desktop-content-right">
                <div className="card-novos-habitos box-custom">
                  <section
                    ref={ref}
                    className="desktop-content-right-CardNovosHabitos"
                  >
                    <CardNovosHabitos
                      ultimo={indiceNovoHabito >= novoHabito.cards.length - 1}
                      cardPictureSrc={
                        indiceNovoHabito >= 0
                          ? novoHabito.cards[indiceNovoHabito].picture
                          : null
                      }
                      cardDescription={
                        indiceNovoHabito >= 0
                          ? novoHabito.cards[indiceNovoHabito].description
                          : null
                      }
                      proximoHabitoCallback={proximoCard}
                      bloquearBotao={bloquearBotao}
                      finalizarCallback={finalizar}
                      key={key}
                    />
                  </section>

                  {isDesktopMode ? (
                    ""
                  ) : (
                    <>
                      <div
                        className="marcador-progresso"
                        style={{ marginBottom: "15px" }}
                      >
                        {marcadorProgresso}
                      </div>
                      <div
                        className="botão-voltar-mobile"
                        style={{ marginBottom: "2rem" }}
                      >
                        <BotaoAsALink
                          nome={i18n.message(
                            "missaoCumprida.repetir",
                            "Voltar",
                          )}
                          ariaLabelMsg={i18n.message(
                            "aria.label.geral.mensagem.voltar.atividades",
                            "Volta para a página de atividades.",
                          )}
                          funcao={() => navigate("/")}
                        />
                      </div>
                    </>
                  )}
                </div>
                {isDesktopMode ? (
                  <div
                    className="marcador-progresso"
                    style={{ marginBottom: "15px" }}
                  >
                    {marcadorProgresso}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </NovosHabitosStyled>
  );
}

export default NovosHabitos;
