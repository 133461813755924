import React from "react";
import { useSelector } from "react-redux";

/**
 * Pódio do ranking da campanha, usado tanto no ranking do game como no da competição individual.
 * @param {Object} props
 * @param {Object} props.topRanking
 * @returns
 */
function LeadershipRanking({ topRanking }) {
  const i18n = useSelector((state) => state.i18n);

  const topNaoDefinidas = () => {
    const topRankingNaoDefinidos = [];
    if (topRanking.length < 3) {
      for (let i = topRanking.length; i < 3; i++) {
        topRankingNaoDefinidos.push(
          <div key={i} className="content">
            <img
              alt={i18n.message("alt.feedback.estrela", "Ícone de estrela ")}
              src="/new_star_empty.svg"
            />
            <span className="pontuacao">0{i + 1}</span>
            <p className="nome-ranking">Não definido</p>
            <hr className="ranking-base"></hr>
            <small className="pontuacao-ranking"> &nbsp; </small>
          </div>,
        );
      }
      return topRankingNaoDefinidos;
    }
  };

  const ranking = () => {
    if (topRanking) {
      const topRankingNaoDefinidos = topNaoDefinidas();
      const ranking = topRanking.map((ranking, index) => {
        return (
          <div
            key={index}
            className={`content content-${ranking.position || `0${index + 1}`}`}
          >
            <img
              alt={i18n.message("alt.feedback.estrela", "Ícone de estrela ")}
              src="/new_star_empty.svg"
            />
            <span className="pontuacao" aria-hidden="true">
              {ranking.position || `0${index + 1}`}
            </span>
            <span className="weex-g-visually-hidden">{`${
              index + 1
            }º ${i18n.message("rankings.aria.label.lugar", "lugar")}, ${
              ranking.name
            }`}</span>
            <p className="nome-ranking" aria-hidden="true">
              {ranking.name}
            </p>
            <hr className="ranking-base"></hr>
            <small className="pontuacao-ranking" aria-hidden="true">
              {ranking.rating} &nbsp; {i18n.message("geral.pts", "PTS")}
            </small>
            <span className="weex-g-visually-hidden">{`${
              ranking.rating
            }${i18n.message("rankings.aria.label.pontos", "pontos")}`}</span>
          </div>
        );
      });
      if (topRankingNaoDefinidos && topRankingNaoDefinidos.length > 0) {
        topRankingNaoDefinidos.forEach((element) => {
          ranking.push(element);
        });
      }
      return ranking;
    }
  };

  return (
    <>
      <h4 className="dinamica-title-lideranca">
        {i18n.message("ranking.lideranca", "PÓDIO")}
      </h4>
      <div className="lideranca-content">{ranking()}</div>
    </>
  );
}

export default LeadershipRanking;
