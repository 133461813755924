import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import AgendaService from "../../../services/AgendaService";
import PreSelecaoInteressesService from "../../../services/PreSelecaoInteressesService";
import UsuarioService from "../../../services/UsuarioService";
import BotaoInteressesEscolha from "../../botaoInteressesEscolha/BotaoInteressesEscolha";
import BotaoInteressesEscolher from "../../botaoInteressesEscolher/BotaoInteressesEscolher";
import BotaoPrimario from "../../botaoPrimario/BotaoPrimario";
import InteressesConfirmacaoModal from "../../weexModais/InteressesConfirmacaoModal";
import WeexModal from "../../weexModais/WeexModal";
import { InteresseDesktopStyle } from "./InteresseDesktopStyle";

const InteresseDesktop = (props) => {
  const navigate = useNavigate();
  const preSelecaoInteressesService = new PreSelecaoInteressesService();
  const agendaService = new AgendaService();
  const i18n = useStore().getState().i18n;
  const [processando, setProcessando] = useState(false);
  const [interessesDesktop, setInteressesDesktop] = useState([]);
  const [interessesEscolhidos, setInteressesEscolhidos] = useState([]);
  const [interessesEscolhidosMatriz, setInteressesEscolhidosMatriz] = useState(
    [],
  );
  const [jaEscolhido, setJaEscolhido] = useState(false);
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });
  const [salvando, setSalvando] = useState({
    isSaved: false,
    titulo: "",
    mensagem: "",
  });

  const [confirmando, setConfirmando] = useState({
    isConfirm: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  useEffect(() => {
    const isUnidadeUsuario = (a) => {
      if (a.find((a) => a === new UsuarioService().usuarioCorrente().unit)) {
        return true;
      }
    };

    const arrayEmMatriz = (array, cols) => {
      let matriz = [];

      if (array) {
        const interessesUsuario = array.filter(
          (a) => a.units === null || isUnidadeUsuario(a.units),
        );

        for (let i = 0; i < interessesUsuario.length; i += cols) {
          matriz.push(interessesUsuario.slice(i, i + cols));
        }
      }

      return matriz;
    };
    setInteressesDesktop(arrayEmMatriz(props.campanha, 3));
    setInteressesEscolhidosMatriz(arrayEmMatriz(interessesEscolhidos, 2));
  }, [interessesEscolhidos, props]);

  const handleChangeInteresse = (event, action) => {
    if (props.canSelectInterets) {
      if (action === "add") {
        const interesseExitente = interessesEscolhidos.find(
          (i) => i.uuid === event.uuid,
        );

        if (!interesseExitente) {
          setInteressesEscolhidos([...interessesEscolhidos, event]);
        } else {
          setJaEscolhido(true);
        }
      } else {
        const interesseRemover = interessesEscolhidos.find(
          (i) => i.uuid === event.uuid,
        );
        if (interesseRemover) {
          const index = interessesEscolhidos.indexOf(interesseRemover);
          interessesEscolhidos.splice(index, 1);
          setInteressesEscolhidos([...interessesEscolhidos]);
        }
      }
    }
  };

  const confirmacaoDeEnvioDeInteresses = () => {
    setConfirmando({
      isConfirm: true,
      titulo: i18n.message(
        "interesse.selecaoObrigatoria.mensagemConfirmaTitulo",
        "Confirma seleção de interesses?",
      ),
      mensagem: i18n.message(
        "interesse.selecaoObrigatoria.mensagemConfirmaConteudo",
        "Você não poderá alterar mais tarde seus interesses. Deseja Continuar?",
      ),
    });
  };

  const voltarInteresse = () => {
    if (confirmando.isConfirm === true) {
      setConfirmando({ isConfirm: false });
    }
  };

  const submitInteresse = (event) => {
    event.preventDefault();
    setConfirmando({ isConfirm: false });
    const interesseSubmit = interessesEscolhidos.map((i) => {
      return {
        uuid: i.uuid,
        name: i.name,
      };
    });

    if (!interesseSubmit.length) {
      setErro({
        isWrong: true,
        titulo: i18n.message(
          "interesse.erro.aoAdicionarInteresse.titulo",
          "Erro ao adicionar interesse",
        ),
        mensagem: i18n.message(
          "interesse.erro.quantidadeDeInteresses.mensagem",
          "Selecione pelo menos um tema de interesse para continuar.",
        ),
        voltar: true,
      });
      setProcessando(false);
      return;
    }

    if (interesseSubmit.length > props.daysOfEvent) {
      setErro({
        isWrong: true,
        titulo: i18n.message(
          "interesse.erro.aoAdicionarInteresse.titulo",
          "Erro ao adicionar interesse",
        ),
        mensagem:
          i18n.message(
            "interesse.erro.quantidadeDeInteresses.mensagem.part1",
            "Você deve selecionar no máximo ",
          ) +
          props.daysOfEvent +
          i18n.message(
            "interesse.erro.quantidadeDeInteresses.mensagem.part2",
            " temas de interesse para continuar.",
          ),
        voltar: true,
      });
      setProcessando(false);
      return;
    }
    preSelecaoInteressesService.selecaoDeInteresses(
      interesseSubmit,
      (erro, sucesso) => {
        if (erro) {
          setErro({
            isWrong: true,
            titulo: i18n.message(
              "interesse.erro.aoAdicionarInteresse.titulo",
              "Erro ao adicionar interesse",
            ),
            mensagem: i18n.message(
              "interesse.erro.quantidadeDeInteresses.mensagem",
              "Você deve selecionar no máximo " +
                props.daysOfEvent +
                " temas de interesse para continuar.",
            ),
            voltar: true,
          });
          setProcessando(false);
        }
        if (sucesso) {
          agendaService.atualizarInteresse(true);
          setProcessando(false);
          setErro({ isWrong: false });
          setSalvando((prevState) => ({
            ...prevState,
            isSaved: true,
            titulo: i18n.message(
              "interesse.sucesso.interesseAdicionado.titulo",
              "Interesses adicionados",
            ),
            mensagem: i18n.message(
              "interesse.sucesso.interessesSalvos.mensagem",
              "Os interesses foram salvos com sucesso",
            ),
          }));
          agendaService.atualizarInteresse(true);
          setProcessando(false);
        }
      },
    );
  };

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
      navigate("/interests");
    }
  };

  const callbackModalJaEscolhido = () => {
    setJaEscolhido(false);
  };

  const callbackModalSuccess = () => {
    if (salvando.isSaved === true) {
      setSalvando({ isSaved: false });
      navigate("/");
    }
  };

  if (!processando) {
    setProcessando(true);
    agendaService.agendaCorrente((erro, interesseJaSelecionado) => {
      if (interesseJaSelecionado) {
        let interesseEditado = interesseJaSelecionado.interests;
        setInteressesEscolhidos(interesseEditado);
      }
      if (erro) {
        setErro(erro);
      }
    });
  }

  const renderInteresses = (linhaInteresses) => {
    let interessesLinha = "";
    if (linhaInteresses.length > 0) {
      interessesLinha = linhaInteresses.map((interesse) => {
        return (
          <div className="interesse-item" key={interesse.uuid}>
            <BotaoInteressesEscolher
              funcao={handleChangeInteresse}
              nome={interesse.name}
              interesse={interesse}
            ></BotaoInteressesEscolher>
          </div>
        );
      });
      return interessesLinha;
    }
  };

  const renderInteressesEscolhidos = (linhaInteresses) => {
    let interessesLinha = "";
    if (linhaInteresses.length > 0) {
      interessesLinha = linhaInteresses.map((i) => {
        return (
          <div className="interesse-item" key={i.uuid}>
            <BotaoInteressesEscolha
              funcao={handleChangeInteresse}
              nome={i.name}
              interesse={i}
            ></BotaoInteressesEscolha>
          </div>
        );
      });
      return interessesLinha;
    }
  };

  const osInteresses = interessesDesktop.map((linhaInteresses, index) => {
    return (
      <div className="interesses" key={index}>
        {renderInteresses(linhaInteresses)}
      </div>
    );
  });

  const InteressesEscolhidos = interessesEscolhidosMatriz.map(
    (linhaInteresses, index) => {
      return (
        <div className="interesses" key={index}>
          {renderInteressesEscolhidos(linhaInteresses)}
        </div>
      );
    },
  );

  return (
    <>
      {confirmando.isConfirm === true ? (
        <InteressesConfirmacaoModal
          confirmarCallback={submitInteresse}
          cancelarCallback={voltarInteresse}
          titulo={confirmando.titulo}
          conteudo={confirmando.mensagem}
        />
      ) : null}
      {salvando.isSaved === true ? (
        <WeexModal
          fecharModalCallback={callbackModalSuccess}
          titulo={salvando.titulo}
          conteudo={salvando.mensagem}
        />
      ) : null}
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}

      {jaEscolhido ? (
        <WeexModal
          fecharModalCallback={callbackModalJaEscolhido}
          conteudo={i18n.message(
            "interesse.titulo.modal.jaEscolhido",
            "Interesse já foi escolhido!",
          )}
        />
      ) : (
        ""
      )}
      <InteresseDesktopStyle>
        <div className="interesse-content-escolha">
          <div className="interesse-escolha">
            {osInteresses}

            {props.requiredInterests === true &&
            props.beginDate <= props.now ? (
              <div className="button-custom-interesse-contente">
                <div className="interesse-btn-container">
                  <BotaoPrimario
                    nome={
                      salvando.isSaved === false
                        ? i18n.message("interesse.salvar", "SALVAR")
                        : i18n.message("interesse.salvando", "SALVANDO...")
                    }
                    disabled={props.canSelectInterets ? false : true}
                    funcao={confirmacaoDeEnvioDeInteresses}
                    ariaStatusMessage={i18n.message(
                      "aria.status.mensagemreutilizavel.botaosalvarclicado",
                      "Botão salvar clicado.",
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className="button-custom-interesse-contente">
                <div className="interesse-btn-container">
                  <BotaoPrimario
                    nome={
                      salvando.isSaved === false
                        ? i18n.message("interesse.salvar", "SALVAR")
                        : i18n.message("interesse.salvando", "SALVANDO...")
                    }
                    disabled={!props.canSelectInterets}
                    funcao={submitInteresse}
                    ariaStatusMessage={i18n.message(
                      "aria.status.mensagemreutilizavel.botaosalvarclicado",
                      "Botão salvar clicado.",
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="interesses-escolhidos">{InteressesEscolhidos}</div>
        </div>

        <hr />
      </InteresseDesktopStyle>
    </>
  );
};

export default InteresseDesktop;
