import React, { useEffect, useRef, useState, useMemo } from "react";
import { useStore } from "react-redux";
import MuralService from "../../services/MuralService";
import UsuarioService from "../../services/UsuarioService";
import VideoIframe from "../VideoIframe/VideoIframe";
import ModalExclusao from "../weexModais/ModalExclusao";
import {
  ComentariosCurtidasStyle,
  ConteudoPublicacao,
  HeaderPublicacao,
  PublicacaoMuralStyled,
  QuebraEntreComentarios,
} from "./style";

function PublicacaoMural({
  elemento,
  publicacao,
  isPublicacao,
  mural,
  ariaLabelButtonComentario,
  ariaLabelButtonCurtida,
  imagemPublicacaoMural,
  msgErroImagem,
  msgProcessando,
  msgExcluir,
  comentarios,
  curtir,
  deletar,
}) {
  const i18n = useStore().getState().i18n;
  const [curtida, setCurtida] = useState(elemento.curtiu);
  const [mostrarModalExclusao, setMostrarModalExclusao] = useState(false);
  const [isGestorOrAdmin, setisGestorOrAdmin] = useState(false);
  const [numerosCurtidas, setNumerosCurtidas] = useState(elemento.qtLikes);
  const [fixado, setFixado] = useState(elemento.fix);
  const [mensagemCurtidas, setMensagemCurtidas] = useState("");
  const [botaoBloquado, setBotaoBloqueado] = useState(false);
  const service = useMemo(() => new MuralService(), []);
  const usuarioService = useMemo(() => new UsuarioService(), []);
  const ref = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const isDefinedGestorOrAdmin = () => {
      return usuarioService.isGestor() || usuarioService.isAdmin();
    };
    setisGestorOrAdmin(isDefinedGestorOrAdmin());

    const onBodyClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        return;
      }
    };

    document.body.addEventListener("click", onBodyClick);

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, [usuarioService]);

  function curtirPublicacao() {
    setBotaoBloqueado(true);
    let like = !curtida;
    if (elemento.qtLikes != null) {
      if (like) {
        setNumerosCurtidas(numerosCurtidas + 1);
        elemento.qtLikes = elemento.qtLikes + 1;
      } else {
        setNumerosCurtidas(numerosCurtidas - 1);
        elemento.qtLikes = elemento.qtLikes - 1;
      }
    }
    setCurtida(like);
    curtir(like, elemento.uuid)
      .then(() => {
        const mensagemCurtiu = i18n.message(
          "aria.hidden.publicacao.mural.curtido",
          "curtiu publicação!",
        );
        const mensagemDescurtiu = i18n.message(
          "aria.hidden.publicacao.mural.descurtido",
          "descurtiu publicação!",
        );
        const mensagem = `${like ? mensagemCurtiu : mensagemDescurtiu}`;
        setMensagemCurtidas(mensagem);
        setBotaoBloqueado(false);
      })
      .catch((erro) => {
        console.log("Erro ao curtir/descurtir: ", erro);
      });
  }

  function carregarComentarios() {
    const request = {};
    comentarios(elemento.uuid, request);
  }

  const atualizarPagina = () => {
    document.location.reload(true);
  };

  const fixarOuDesfixarPublicacao = () => {
    service.fixarPublicacao(elemento.uuid, (error, sucess) => {
      if (sucess) {
        setFixado(sucess.fix);
      }
      if (error) {
        alert(error.response.data.message);
      }
    });
  };

  const abrirModal = () => {
    setMostrarModalExclusao(true);
  };

  const fecharModal = () => {
    setMostrarModalExclusao(false);
  };

  const deletarComentario = () => {
    service.deleteComentario(publicacao, elemento.uuid, (error, sucess) => {
      if (sucess) {
        deletar(elemento.uuid);
      }
      if (error) {
        alert("Você não tem permissão para deletar este comentário!");
      }
    });
  };

  const deletarPublicacao = () => {
    service.deletePublicacao(elemento.uuid, (error, sucess) => {
      if (sucess) {
        deletar(elemento.uuid);
      }
      if (error) {
        alert("Você não tem permissão para deletar esta publicação!");
      }
    });
  };

  const validateUrl = (value) => {
    return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    );
  };

  let conteudo = elemento.conteudo;
  conteudo = conteudo.split(" ");
  const conteudoRender = conteudo.map((el) => {
    if (validateUrl(el)) {
      return (
        <a href={el} target="_blank" rel="noopener noreferrer">
          {el}{" "}
        </a>
      );
    }
    return `${el} `;
  });

  return (
    <PublicacaoMuralStyled>
      <div className="publicacao-mural-content">
        {mostrarModalExclusao &&
          (isPublicacao ? (
            <ModalExclusao
              fecharModalCallback={fecharModal}
              conteudo={msgExcluir}
              deletar={
                elemento.isPublicacao ? deletarPublicacao : deletarComentario
              }
              isComentario={false}
            />
          ) : (
            <ModalExclusao
              fecharModalCallback={fecharModal}
              conteudo={msgExcluir}
              deletar={deletarComentario}
              isComentario={true}
            />
          ))}
        <div>
          <section className="modal-card-body has-text-centered mural_text">
            <HeaderPublicacao>
              <div className="nome-time">
                <div className="titulo-container">
                  {!isGestorOrAdmin && elemento.isPublicacao && fixado ? (
                    <button
                      className="icon button-link"
                      aria-label={i18n.message(
                        "aria.label.mural.fixar.publicacao",
                        "Fixar publicação",
                      )}
                    >
                      <i class="fas fa-thumbtack"></i>
                    </button>
                  ) : (
                    ""
                  )}
                  {isGestorOrAdmin && elemento.isPublicacao ? (
                    <>
                      {fixado ? (
                        <>
                          <button
                            className="icon has-text-success pointer button-link"
                            onClick={fixarOuDesfixarPublicacao}
                          >
                            <i className="fas fa-thumbtack"></i>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="icon has-text-info pointer button-link"
                            onClick={fixarOuDesfixarPublicacao}
                          >
                            <i className="fas fa-thumbtack"></i>
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <h3 className="titulo-container__nome-participante">
                    {elemento.name}
                    {isGestorOrAdmin && elemento.isPublicacao ? (
                      <>
                        {fixado ? (
                          <span>
                            - {i18n.message("dinamica.mural.fixado", "Fixado")}
                          </span>
                        ) : (
                          <span>
                            -{" "}
                            {i18n.message(
                              "dinamica.mural.nao.fixado",
                              "Não fixado",
                            )}
                          </span>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
                <label>{elemento.tempo}</label>
              </div>
              {elemento.doUsuario || isGestorOrAdmin ? (
                <div>
                  <button
                    ref={ref}
                    aria-label={i18n.message(
                      "aria.label.excluir.publicacao",
                      "excluir publicação",
                    )}
                    className="icon pointer button-link"
                    onClick={abrirModal}
                  >
                    <i className="material-icons icon-trash">delete</i>
                  </button>
                </div>
              ) : (
                ""
              )}
            </HeaderPublicacao>
            <ConteudoPublicacao>
              <div className="weex-modal-card-body__texto mural_text">
                <p className="text">{conteudoRender ? conteudoRender : ""}</p>
              </div>
              {elemento.erroImagem ? (
                <div className="weex-modal-card-body__texto imagem-postagem">
                  <p>
                    <mark>{msgErroImagem}</mark>{" "}
                  </p>
                </div>
              ) : (
                ""
              )}
              {elemento.processando ? (
                <div className="weex-modal-card-body__texto imagem-postagem margin-imagem-erro">
                  <p>
                    <mark>{msgProcessando}</mark>
                  </p>
                  <button
                    className="icon pointer atualizar-card"
                    onClick={atualizarPagina}
                  >
                    <i className="fas fa-2x fa-redo-alt has-text-grey-light"></i>
                  </button>
                </div>
              ) : (
                ""
              )}
              {elemento.imagem && elemento.isPublicacao ? (
                <div className="weex-modal-card-body__texto imagem-postagem">
                  <div className="modal-card-title wex-modal-card-img">
                    <img
                      src={elemento.imagem}
                      alt={imagemPublicacaoMural}
                      loading="lazy"
                      className="image is-2by1 retirar-padding"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </ConteudoPublicacao>
            <VideoIframe
              urlVideo={elemento.urlVideo}
              mural={mural}
              showControls={true}
              bloquearTelaCheia={false}
              title={i18n.message(
                "aria.label.mural.videoPublicado",
                "Vídeo publicado",
              )}
            ></VideoIframe>
            {elemento.isPublicacao ? (
              <>
                <ComentariosCurtidasStyle>
                  <button
                    className="icon btn-comentario "
                    aria-label={ariaLabelButtonComentario}
                    onClick={carregarComentarios}
                  >
                    <i className="fa-2x fas fa-comments has-text-grey-light"></i>
                    {elemento.qtdComentarios ? (
                      <span
                        aria-hidden="true"
                        className="comentarios values has-background-success has-text-white"
                      >
                        {elemento.qtdComentarios}
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                  <button
                    onClick={curtirPublicacao}
                    aria-label={ariaLabelButtonCurtida}
                    className={
                      "btn-hidden pointer curtida " +
                      (curtida ? "has-text-danger" : "has-text-grey-light")
                    }
                    disabled={botaoBloquado}
                  >
                    <i className="pointer fas fa-2x fa-heart"></i>
                    {numerosCurtidas ? (
                      <span className="comentarios values has-background-success has-text-white">
                        {numerosCurtidas}
                      </span>
                    ) : (
                      ""
                    )}
                  </button>
                </ComentariosCurtidasStyle>
                <span className="weex-g-visually-hidden" role="status">
                  {mensagemCurtidas}
                </span>
              </>
            ) : (
              ""
            )}
          </section>
        </div>
        {elemento.isPublicacao ? (
          ""
        ) : (
          <QuebraEntreComentarios></QuebraEntreComentarios>
        )}
      </div>
    </PublicacaoMuralStyled>
  );
}
export default PublicacaoMural;
