import React from "react";

const CardEstatico = ({ position, digit, hidden }) => {
  return (
    <div className={position} aria-hidden={hidden}>
      <span>{digit}</span>
    </div>
  );
};

export default CardEstatico;
