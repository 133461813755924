import React from "react";
import { useSelector } from "react-redux";
import UsuarioService from "../../services/UsuarioService";

function PontuacaoUsuario({ pontuacaoUsuario, foraDoRanking, isParticipante }) {
  const i18n = useSelector((state) => state.i18n);
  const usuario = new UsuarioService()?.usuarioCorrente();
  const mensagemCompInd = i18n.message(
    "ranking.naoParticipouCompInd",
    "Você não participou de nenhuma atividade ",
  );
  const mensagemCompIndDois = i18n.message(
    "ranking.naoParticipouCompIndDois",
    "Faça atividades para ganhar pontos e melhorar sua posição no ranking",
  );
  const mensagemgameranking = i18n.message(
    "ranking.game.naoParticipou",
    "Você ainda não pontuou no game !",
  );
  const mensagemgamerankingDois = i18n.message(
    "ranking.game.naoParticipouDois",
    "Clique em concluir ao finalizar um game para registrar sua pontuação",
  );

  const isUsuarioCorrente = () => {
    return pontuacaoUsuario?.uuidParticipante === usuario?.participantCode;
  };

  return (
    <>
      {pontuacaoUsuario?.rating > 0 ? (
        <>
          <div className="participante-container">
            <div className="participante-info">
              <div className="content-one">
                <div
                  className={
                    isUsuarioCorrente()
                      ? "participante-position-usuario-corrente"
                      : "participante-position"
                  }
                >
                  {pontuacaoUsuario.position ? (
                    <>
                      <span className="weex-g-visually-hidden">
                        {`${pontuacaoUsuario.position}º ${i18n.message(
                          "rankings.aria.label.lugar",
                          "lugar",
                        )}`}
                      </span>
                      <p
                        className={
                          isUsuarioCorrente()
                            ? "participante-info-posicao participante-info-name-usuario-corrente"
                            : "participante-info-posicao"
                        }
                        aria-hidden="true"
                      >
                        {pontuacaoUsuario.position + "º"}
                      </p>
                    </>
                  ) : foraDoRanking ? (
                    <>
                      <span className="weex-g-visually-hidden">
                        {`${i18n.message(
                          "out.of.rankings.aria.label.lugar",
                          "fora do top 50",
                        )}`}
                      </span>
                      <p
                        className="participante-info-name-usuario-corrente"
                        aria-hidden="true"
                      >
                        {i18n.message(
                          "rankings.aria.label.lugar",
                          "maior que 50",
                        )}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="participante-info-name">
                  <p
                    className={
                      isUsuarioCorrente()
                        ? "participante-info-name-usuario-corrente"
                        : ""
                    }
                  >
                    {pontuacaoUsuario?.name}
                  </p>
                  <div className="participante-unidade">
                    <p
                      className={
                        isParticipante
                          ? "participante-info-unidade-isparticipante"
                          : "participante-info-unidade"
                      }
                    >
                      {pontuacaoUsuario?.unit}
                    </p>
                  </div>
                </div>
                <div>
                  <span className="weex-g-visually-hidden">
                    {`${pontuacaoUsuario?.rating}${i18n.message(
                      "rankings.aria.label.pontos",
                      "pontos",
                    )}`}
                  </span>
                  <p
                    className={
                      isUsuarioCorrente()
                        ? "participante-info-name-usuario-corrente participante-info-participante-corrente-pontos"
                        : "participante-info-pts"
                    }
                    aria-hidden="true"
                  >
                    {pontuacaoUsuario?.rating +
                      i18n.message("geral.pts", "PTS")}
                  </p>
                </div>
              </div>
            </div>
            <hr className="hr-ranking-game"></hr>
          </div>
        </>
      ) : (
        <>
          <div className="participante-container">
            <div className="participante-info">
              <div>
                <div>
                  <p className="participante-info-pts-msg">
                    {pontuacaoUsuario?.isCompind
                      ? mensagemCompInd
                      : mensagemgameranking}
                  </p>
                </div>
                <div>
                  <p className="participante-info-pts-msg2">
                    {pontuacaoUsuario?.isCompind
                      ? mensagemCompIndDois
                      : mensagemgamerankingDois}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PontuacaoUsuario;
