import React from "react";
import { useStore } from "react-redux";
import AtividadeEntity from "../AtividadeEntity";
import GameSVG from "../../svg/GameSVG";
import {
  BotaoGameAtividadeContainerStyle,
  BotaoGameAtividadeStyle,
} from "./style";
import WeexCheckExecutado from "../../WeexCheckExecutado/WeexCheckExecutado";

function BotaoGameAtividade({ games, atividades }) {
  const i18n = useStore().getState().i18n;

  const isEnable = () => {
    if (atividades.length > 0) {
      return atividades.reduce((isEnable, a) => {
        const atividade = new AtividadeEntity(a);
        return (
          (atividade.executed || atividade.type === "PRESENCIAL") && isEnable
        );
      }, true);
    }
    return false;
  };

  const renderizaBotoesDoGame = () => {
    return games.map((game) => {
      const gameRender = new AtividadeEntity(game);
      return (
        <div
          className="weex-l-game"
          key={gameRender.uuid}
          aria-disabled="true"
          aria-hidden="true"
        >
          <WeexCheckExecutado executado={game.executed} top={"0.75rem"} />
          <BotaoGameAtividadeStyle
            gameExecuted={game.executed}
            onClick={gameRender.navegar}
            title="Clique para jogar o jogo"
            tabIndex="-1"
            aria-hidden="true"
            aria-disabled="true"
          >
            <span>
              <GameSVG />
            </span>
            <span className="weex-l-botao-game-atividade__texto">
              {i18n.message(
                "game.jogar.habilitado",
                "Que tal uma dose de diversão?",
              )}
            </span>
          </BotaoGameAtividadeStyle>
        </div>
      );
    });
  };

  const renderizaBotoesDoGameBloqueado = () => {
    return (
      <BotaoGameAtividadeStyle disabled aria-disabled="true" aria-hidden="true">
        <span>
          <GameSVG fill={"#c1c1c1"} />
        </span>
        <span
          className="weex-l-botao-game-atividade__texto"
          aria-disabled="true"
          aria-hidden="true"
        >
          {i18n.message(
            "game.jogar.desabilitado",
            "Conclua as atividades para desbloquear",
          )}
        </span>
      </BotaoGameAtividadeStyle>
    );
  };

  const verificaSeAtividadesEstaoDisponiveisEExecutadas = () => {
    if (isEnable()) {
      return games.length > 0 ? renderizaBotoesDoGame() : "";
    }
    return games.length > 0 ? renderizaBotoesDoGameBloqueado() : "";
  };

  return (
    <BotaoGameAtividadeContainerStyle>
      {verificaSeAtividadesEstaoDisponiveisEExecutadas()}
    </BotaoGameAtividadeContainerStyle>
  );
}

export default BotaoGameAtividade;
