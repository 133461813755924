import React from "react";
import { useStore } from "react-redux";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useNavigate } from "react-router-dom";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";
import ContadorRegressivoRevisaoAT from "./ContadorRegressivoRevisaoAT";
import TagRespostaRevisaoAT from "./TagRespostaRevisaoAT";
import AlternativaRevisaoAT from "./AlternativaRevisaoAT";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";

const ModoRevisaoAT = () => {
  const {
    acerteTempo,
    proximoAcerteTempoRevisao,
    totalDeQuestoes,
    anteriorAcerteTempoRevisao,
    indiceAcerteTempo,
    obtemPlacarFinal,
  } = useAcerteTempo();

  const i18n = useStore().getState().i18n;
  const navigate = useNavigate();

  const imagemQuestao = () => {
    if (acerteTempo?.questions[indiceAcerteTempo]?.image) {
      return (
        <>
          <img
            className="image is-2by1 retirar-padding"
            src={acerteTempo?.questions[indiceAcerteTempo]?.image}
            alt={i18n.message(
              "alt.dinamica.acerteTempo.imagemQuestao",
              "Imagem da questão da dinâmica Hábito Certo",
            )}
          />
        </>
      );
    }
  };

  const enunciadoQuestao = () => {
    return (
      <>
        <div
          className="weex-l-acerteTempo-textos-container"
          dangerouslySetInnerHTML={{
            __html: acerteTempo?.questions[indiceAcerteTempo]?.statement,
          }}
        ></div>
      </>
    );
  };

  const revisaoBtnVoltarProximo = () => {
    return (
      <>
        <div className="weex-l-acerteTempo-revisao-btnContainer">
          <BotaoSecundario
            nome={
              <span className="weex-l-acerteTempo-revisao-btnTexto">
                <span>
                  <i className="fas fa-chevron-left" />
                </span>
                <span>
                  {i18n.message("dinamica.acerteTempo.btn.voltar", "Voltar")}
                </span>
              </span>
            }
            disabled={indiceAcerteTempo === 0}
            funcao={anteriorAcerteTempoRevisao}
            disabledOnClick={false}
          />
          {indiceAcerteTempo === totalDeQuestoes - 1 ? (
            <>
              <BotaoPrimario
                nome={i18n.message(
                  "dinamica.acerteTempo.btn.revisao.concluir",
                  "CONCLUIR",
                )}
                ariaDisabled={false}
                ariaHidden={false}
                ariaLabelMsg={i18n.message(
                  "dinamica.acerteTempo.btn.revisao.concluir",
                  "CONCLUIR",
                )}
                ariaStatusMessage={i18n.message(
                  "dinamica.acerteTempo.btn.revisao.concluir.ariaLabel",
                  "botão CONCLUIR clicado",
                )}
                disabled={false}
                funcao={() => navigate("/")}
              />
            </>
          ) : (
            <>
              <BotaoSecundario
                nome={
                  <span className="weex-l-acerteTempo-revisao-btnTexto">
                    <span>
                      {i18n.message(
                        "dinamica.acerteTempo.btn.proximo",
                        "Próximo",
                      )}
                    </span>
                    <span>
                      <i className="fas fa-chevron-right" />
                    </span>
                  </span>
                }
                disabled={indiceAcerteTempo === totalDeQuestoes - 1}
                funcao={proximoAcerteTempoRevisao}
                disabledOnClick={false}
              />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <section className="weex-l-acerteTempo-container weex-l-acerteTempo-container-row">
        <WeexDesktopMode className={"weex-l-acerteTempo-container--flexFilhos"}>
          <div className="weex-l-acerteTempo-posicionamento-imagens">
            {imagemQuestao()}
          </div>
          <ContadorRegressivoRevisaoAT
            tempoEmSegundos={acerteTempo?.questions[indiceAcerteTempo]?.time}
            tempoParticipanteRespondeu={
              obtemPlacarFinal?.scores[indiceAcerteTempo]?.answeredTimeInSeconds
            }
          />
          <TagRespostaRevisaoAT />
          <div className="weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperiorMenor">
            {revisaoBtnVoltarProximo()}
          </div>
        </WeexDesktopMode>
        <div className="weex-l-acerteTempo-container--flexFilhos">
          {enunciadoQuestao()}

          <WeexDesktopMode>
            <AlternativaRevisaoAT />
          </WeexDesktopMode>
        </div>
      </section>

      <WeexMobileMode className={"weex-l-acerteTempo-botaoRespiroSuperior"}>
        <ContadorRegressivoRevisaoAT
          tempoEmSegundos={acerteTempo?.questions[indiceAcerteTempo]?.time}
          tempoParticipanteRespondeu={
            obtemPlacarFinal?.scores[indiceAcerteTempo]?.answeredTimeInSeconds
          }
        />
        <AlternativaRevisaoAT />
        <div className="weex-l-acerteTempo-botoesContainer weex-l-acerteTempo-botaoRespiroSuperior">
          {revisaoBtnVoltarProximo()}
        </div>
      </WeexMobileMode>
    </>
  );
};

export default ModoRevisaoAT;
