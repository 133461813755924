import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import Image from "../ImageLogo/Image";
function Footer() {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const campanhaService = new CampanhaService();
  const campanha = campanhaService.campanhaCorrente();
  let logoCentroFooter = null;
  let logoCentroFooterDesktop = null;
  if (campanha) {
    if (!campanha?.logoRight) {
      logoCentroFooter = "";
    } else {
      logoCentroFooter = campanha.logoRight;
    }
    // #region DESKTOP
    if (!campanha?.topRightDesktop) {
      logoCentroFooterDesktop = "";
    } else {
      logoCentroFooterDesktop = campanha.topRightDesktop;
    }
    // #endregion DESKTOP
  }
  let location = useLocation();
  const telaInicial = location?.pathname === "/";
  const isDesktopMode = useSelector((state) => state.isDesktopMode);

  return (
    <>
      {location?.pathname === "/home" ||
      location?.pathname === "/resetPassword" ||
      location?.pathname === "/passwordReset" ||
      location?.pathname === "/" ? (
        <footer
          className={
            usuariologado && !telaInicial
              ? `weex_footer weex-container weex-content`
              : `weex_footer weex-container`
          }
          style={{ paddingTop: "0px" }}
        >
          {logoCentroFooterDesktop && isDesktopMode && usuario === false ? (
            <Image
              className="campanha_logosTopo__centro__footer"
              src={logoCentroFooter}
              alt={i18n.message(
                "alt.logo.secundario.footer.mobile",
                "Logo secundário da empresa",
              )}
              classNameDesktop="campanha_logosTopo__centro__footer"
              srcDesktop={logoCentroFooterDesktop}
              altDesktop={i18n.message(
                "alt.logo.secundario.footer.desktop",
                "Logo secundário da empresa",
              )}
            />
          ) : logoCentroFooter && !isDesktopMode && usuario === false ? (
            <Image
              className="campanha_logosTopo__centro__footer"
              src={logoCentroFooter}
              alt={i18n.message(
                "alt.logo.secundario.footer.mobile",
                "Logo secundário da empresa",
              )}
              classNameDesktop="campanha_logosTopo__centro__footer"
              srcDesktop={logoCentroFooterDesktop}
              altDesktop={i18n.message(
                "alt.logo.secundario.footer.desktop",
                "Logo secundário da empresa",
              )}
            />
          ) : (
            ""
          )}
        </footer>
      ) : (
        ""
      )}
    </>
  );
}

export default Footer;
