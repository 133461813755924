import styled from "styled-components";

export const BotaoFatoFakeStyle = styled.button`
  padding-top: 5px;
  padding-bottom: 5px;
  width: 45%;
  letter-spacing: 0.2rem;
  color: #777777;
  text-transform: uppercase;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  background-color: ${(props) => {
    return props.theme.fundoTres;
  }};
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};
  cursor: pointer;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(1.1);
  }

  .weex-l-botaoFatoFake-conteudo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .weex-l-botaoFatoFake-conteudo__icone {
    margin-right: 0.313rem;
    margin-left: 1rem;
  }

  @media screen and (max-width: 600px) {
    padding-left: 0em;
    padding-right: 0em;
  }
`;
