import React from "react";
import { useStore } from "react-redux";
import BotaoFato from "./botaoFF/BotaoFato";
import BotaoFake from "./botaoFF/BotaoFake";

export default function CardFatoFake({ questao, responderFatoFake, disabled }) {
  const i18n = useStore().getState().i18n;

  return (
    <div className="weex-l-dinamica-FatoFake-card weex-l-dinamica-FatoFake-card--border">
      {questao?.picture && (
        <figure className="weex-l-dinamica-FatoFake-card__imagem-container animations-HideUntilLoaded">
          <img
            className="weex-l-dinamica-FatoFake-card__imagem"
            src={questao.picture}
            alt={i18n.message(
              "alt.dinamica.imagem.fatofake",
              "Imagem ilustrativa das questões de fato ou fake",
            )}
          />
        </figure>
      )}
      <div className="weex-l-dinamica-FatoFake-card-content">
        <div className="weex-l-dinamica-FatoFake-card-content-respiro">
          <div
            className="weex-l-dinamica-FatoFake-card-content__markup"
            dangerouslySetInnerHTML={{ __html: questao?.enunciation }}
          ></div>
        </div>
        <div className="weex-l-dinamica-FatoFake-card-botoes-container">
          <BotaoFato
            disabled={disabled}
            funcao={() => {
              responderFatoFake(true, questao?.uuid);
            }}
          />
          <BotaoFake
            disabled={disabled}
            funcao={() => {
              responderFatoFake(false, questao?.uuid);
            }}
          />
        </div>
      </div>
    </div>
  );
}
