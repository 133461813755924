import React, { useEffect, useRef, useState } from "react";
import { ButtonSecundarioStyle } from "./style";

/**
 * Componente de botão secundário.
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.classeComplementar - Classe CSS adicional para o botão.
 * @param {function} props.funcao - Função de clique do botão.
 * @param {boolean} props.disabled - Indica se o botão está desabilitado.
 * @param {string} props.nome - Texto exibido no botão.
 * @param {string} props.ariaLabelMsg - Texto exibido para leitores de tela.
 * @returns {JSX.Element} - Elemento JSX representando o botão secundário.
 */
function BotaoSecundario({
  id = "botao-secundario",
  classeComplementar,
  funcao,
  disabled,
  nome,
  ariaLabelMsg,
  ariaStatusMessage,
  isForm,
  disabledOnClick = true,
}) {
  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] =
    useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (disabled) {
      buttonRef.current.disabled = true;
      return;
    }
    buttonRef.current.disabled = false;
  }, [disabled]);

  function onClick(event) {
    if (disabled) return;
    if (!isForm) {
      if (disabledOnClick) {
        buttonRef.current.disabled = true;
      }
      setAriaAtivarMensagemDeClique(true);
      funcao && funcao(event);
    }
  }

  return (
    <ButtonSecundarioStyle
      id={id}
      ref={buttonRef}
      className={
        disabled ? `${classeComplementar} disabled` : `${classeComplementar}`
      }
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabelMsg}
      aria-disabled={disabled ? "true" : "false"}
      type="submit"
    >
      <span className="content-botao">{nome}</span>
      <span className="weex-g-visually-hidden" role="status">
        {ariaAtivarMensagemDeClique ? ariaStatusMessage : ""}
      </span>
    </ButtonSecundarioStyle>
  );
}

export default BotaoSecundario;
