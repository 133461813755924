import styled from "styled-components";

export const DataCardAgendaStyle = styled.div`
  position: relative;
  bottom: ${(props) => {
    return props.$dayCompleted === true ? `1.688rem` : `0.063rem`;
  }};
  right: 0.063rem;

  .weex-l-icone-check-executado-canto-direito {
    position: relative;
    top: 1rem;
    left: 2.813rem;
  }

  .weex-l-card-data-container {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 0.5rem;
    background: ${(props) => {
      if (props.$habilitado === true) {
        return props.theme.fundoSecundario;
      } else {
        return props.theme.cardDoDiaAgendaBordaDataDesabilitado || `#DFDDE3`;
      }
    }};
    box-shadow: ${(props) => {
      return props.theme.sombraPrimaria;
    }};
  }

  .weex-l-card-data-container--position-textual {
    padding-bottom: 1rem;
    padding-top: 0.625rem;
    margin-right: 3vw;
  }

  .weex-l-card-data {
    color: #ffffff;
    font-family: Poppins, sans-serif;
    font-weight: 700;
    text-align: center;
  }

  .weex-l-card-data__dia {
    font-size: 1.375rem;
    line-height: 1.5rem;
  }

  .weex-l-card-data__mes {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }

  /* #region DESKTOP */

  /* 75rem é o equivalente a 1200px */

  @media (min-width: 75rem) {
    .weex-l-card-data-container {
      width: 3.5rem;
      height: 3.5rem;
    }

    .weex-l-card-data-container--position-textual {
      margin-right: 3%;
      margin-bottom: 3vh;
    }
  }

  /* #endregion DESKTOP */
`;
