import styled from "styled-components";

export const TrofeuDaCampanhaContainerStyle = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;

  .button-ranking-campanha {
    margin-bottom: 30px;
  }

  .weex-l-trofeu-campanha-informacoes {
    max-width: 3.438rem;
    width: 3.438rem;
    height: 3.438rem;
    position: relative;
    bottom: 1.875rem;
  }

  .weex-l-trofeu-campanha-botoes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
  }

  .weex-l-trofeu-campanha-botoes__botao {
    width: 100%;
  }

  .weex-l-trofeu-campanha-informacoes__textos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0.625rem;
  }

  .weex-l-trofeu-campanha-informacoes__textos {
    font-weight: 500;
    font-size: 0.938rem;
    line-height: 0.938rem;
    color: ${(props) => {
      return props.$porcentagemFinal === "100" ? "#00E291" : "#847D92";
    }};
  }

  .weex-l-trofeu-campanha-progress-bar-container {
    max-width: 50%;
    max-height: 50%;
    margin-bottom: 2.813rem;
  }

  /* #region DESKTOP */

  /* 75rem é o equivalente a 1200px */

  @media (min-width: 75rem) {
    min-width: 29%;
    margin-top: 1em;
    /* margin-right: 8rem; */

    .weex-l-trofeu-campanha-progress-bar-container {
      max-width: 100%;
      max-height: 100%;
    }
  }

  /* #endregion DESKTOP */
`;
