import React from "react";
import { WeexSpinnerStyle } from "./Style";

function WeexSpinner(props) {
  return (
    <WeexSpinnerStyle>
      <div className="weexLoader">Aguarde...</div>
      <p>{props.aguarde}</p>
    </WeexSpinnerStyle>
  );
}

export default WeexSpinner;
