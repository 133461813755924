import styled from "styled-components";

export const FlipClockStyle = styled.div`
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  .separador {
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  .contador-campanha-dias_horas_minutos {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => {
      return props.theme.cardLogadoTextoTerceiro;
    }};
  }

  .flipUnitContainer {
    display: block;
    position: relative;
    /* width: 21vw;
  height: 18vw; */
    width: 4.125rem; // 66px
    height: 4.18rem; // 67px
    -webkit-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
    -webkit-perspective: 45vw;
    perspective: 45vw;
    /* background-color: ${(props) => {
      return props.theme.fundoTres;
    }}; */
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 10px 0px -10px grey;
    text-align: center;
    text-transform: uppercase;
  }

  .upperCard,
  .lowerCard {
    /* background-color: ${(props) => {
      return props.theme.fundoTres;
    }}; */
    background-color: #ffffff;
  }

  .upperCard,
  .lowerCard {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    height: 50%;
    overflow: hidden;
    /* border: 1px solid #000000; */
  }
  .upperCard span,
  .lowerCard span {
    font-size: 28px;
    line-height: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }

  .upperCard {
    -webkit-box-align: end;
    align-items: flex-end;
    border-bottom: 1px solid #dfdfdf;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .upperCard span {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  .lowerCard {
    -webkit-box-align: start;
    align-items: flex-start;
    /* background-color: ${(props) => {
      return props.theme.fundoTres;
    }}; */
    background-color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .lowerCard span {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .flipCard {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .flipCard span {
    font-size: 28px;
    line-height: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
  }
  .flipCard.unfold {
    top: 50%;
    -webkit-box-align: start;
    align-items: flex-start;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    /* background-color: ${(props) => {
      return props.theme.fundoTres;
    }}; */
    background-color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .flipCard.unfold span {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .flipCard.fold {
    top: 0%;
    -webkit-box-align: end;
    align-items: flex-end;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    background-color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    /* border: 0.5px solid #000000; */
    /* border-bottom: 0.5px solid #000000; */
  }
  .flipCard.fold span {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);

    /* color: ${(props) => {
      return props.theme.fundoTres;
    }}; */
    color: #ffffff;
  }

  .fold {
    -webkit-animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
      normal forwards;
    animation: fold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
      forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .unfold {
    -webkit-animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1
      normal forwards;
    animation: unfold 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal
      forwards;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  @-webkit-keyframes fold {
    0% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
      transform: rotateX(-180deg);
    }
  }

  @keyframes fold {
    0% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
      transform: rotateX(-180deg);
    }
  }
  @-webkit-keyframes unfold {
    0% {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }
  @keyframes unfold {
    0% {
      -webkit-transform: rotateX(180deg);
      transform: rotateX(180deg);
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
    }
  }

  /*media query min-width 600px e max-height 800px;*/
  @media screen and (max-height: 800px) {
    .flipUnitContainer {
      height: 4rem !important;
    }
  }

  /*media query min-width 600px*/
  @media screen and (min-width: 600px) {
    .flipUnitContainer {
      /* width: 31.5% !important; */
      /* height: 7.5rem !important; */
      width: 4.125rem !important; // 66px
      height: 4.18rem !important; // 67px
      perspective-origin: 50% 50% !important;
    }
    /*media query min-width 600px e max-height 800px;*/
    @media screen and (max-height: 800px) {
      .flipUnitContainer {
        height: 4rem !important;
      }
    }
  }

  @media screen and (min-width: 700px) {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    width: 100%;

    .flipUnitContainer {
      margin: 0 0.5em;
    }
    .flipUnitHome {
      width: 5rem !important;
      height: 5rem !important;
    }
    .contador-campanha-dias_horas_minutos-home {
      font-size: 0.95em;
    }
  }
`;
