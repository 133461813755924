import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import HabitoSVG from "../svg/HabitoSVG";
import { CabecalhoATStyle } from "./CabecalhoATStyle";
import CircleCheck from "../svg/circleCheck";
import WeexModal from "../weexModais/WeexModal";
import WeexDesktopMode from "../WeexMode/WeexDesktopMode";
import WeexMobileMode from "../WeexMode/WeexMobileMode";

function CabecalhoAT() {
  const {
    voltar,
    erro,
    mudarComponentePagina,
    componentePagina,
    TipoComponenteEnum,
    acerteTempo,
    indiceAcerteTempo,
  } = useAcerteTempo();
  const titleOrName = acerteTempo?.title || acerteTempo?.name;

  const i18n = useStore().getState().i18n;

  const cabecalhoVoltar = () => {
    if (componentePagina === TipoComponenteEnum.modoRevisao) {
      mudarComponentePagina(TipoComponenteEnum.placar);
      return;
    }
    voltar();
  };

  const botaoVoltar = () => {
    return (
      <div className="weex-l-cabecalho-voltarAltura">
        {componentePagina !== TipoComponenteEnum?.atividadeIniciada ||
        componentePagina === TipoComponenteEnum?.placar ? (
          <button
            onClick={cabecalhoVoltar}
            className="button is-text weex-l-cabecalho-btn-voltar"
            aria-label={i18n.message(
              "aria.label.geral.mensagem.voltar.atividades",
              "Volta para a página de atividades.",
            )}
          >
            <span className="icon weex-l-cabecalho-custom-color-icon">
              <span className="fa-stack fa-sm">
                <i className="far fa-circle fa-stack-2x "></i>
                <i className="fas fa-chevron-left fa-stack-1x "></i>
              </span>
            </span>
            <span className="weex-l-cabecalho-btn-text">
              {componentePagina === TipoComponenteEnum?.modoRevisao
                ? i18n.message("dinamica.acerteTempo.placar", "Placar")
                : i18n.message("dinamica.acerteTempo.inicio", "Inicio")}
            </span>
          </button>
        ) : (
          ""
        )}
      </div>
    );
  };

  const icones = () => {
    if (componentePagina === TipoComponenteEnum?.placar) {
      return (
        <span className="icon is-large">
          <CircleCheck />
        </span>
      );
    } else {
      return (
        <span className="icon is-large">
          <HabitoSVG />
        </span>
      );
    }
  };

  const quantidadeQuestoesDesktop = () => {
    if (
      componentePagina === TipoComponenteEnum?.atividadeIniciada ||
      componentePagina === TipoComponenteEnum?.modoRevisao
    ) {
      return (
        <h2 className="weex-l-cabecalho-quantidadeQuestoes">
          {indiceAcerteTempo + 1}/{acerteTempo?.questions?.length}
        </h2>
      );
    }
  };

  const quantidadeQuestoesMobile = () => {
    if (
      componentePagina === TipoComponenteEnum?.atividadeIniciada ||
      componentePagina === TipoComponenteEnum?.modoRevisao
    ) {
      return (
        <>
          {indiceAcerteTempo + 1}/{acerteTempo?.questions?.length}
        </>
      );
    }
  };

  return (
    <>
      {erro != null && (
        <WeexModal
          fecharModalCallback={voltar}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      )}
      <CabecalhoATStyle>
        {botaoVoltar()}
        <WeexDesktopMode>{quantidadeQuestoesDesktop()}</WeexDesktopMode>
        <div className="weex-l-cabecalho">
          <div className="weex-l-cabecalho--filhos">{icones()}</div>
          <WeexDesktopMode className="weex-l-cabecalho--filhos">
            <h1 className="weex-l-cabecalho-titulo">
              {componentePagina === TipoComponenteEnum?.comoFunciona ? (
                i18n.message("geral.menu.comoFunciona", "Como funciona")
              ) : (
                <>
                  {componentePagina === TipoComponenteEnum?.atividadeIniciada ||
                  componentePagina === TipoComponenteEnum?.modoRevisao
                    ? acerteTempo?.questions[indiceAcerteTempo]?.title
                    : titleOrName}
                </>
              )}
            </h1>
          </WeexDesktopMode>
          <WeexMobileMode style={{ width: "100%" }}>
            <h1 className="weex-l-cabecalho-titulo weex-l-cabecalho-titulo__composto">
              <span></span>
              <span>
                {componentePagina === TipoComponenteEnum?.comoFunciona
                  ? i18n.message("geral.menu.comoFunciona", "Como funciona")
                  : componentePagina ===
                        TipoComponenteEnum?.atividadeIniciada ||
                      componentePagina === TipoComponenteEnum?.modoRevisao
                    ? acerteTempo?.questions[indiceAcerteTempo]?.title
                    : titleOrName}
              </span>
              <span>{quantidadeQuestoesMobile()}</span>
            </h1>
          </WeexMobileMode>
        </div>
      </CabecalhoATStyle>
    </>
  );
}

export default CabecalhoAT;
