import React from "react";
import { VideoIconStyle } from "./style";
const iconVideo = (props) => {
  return (
    <VideoIconStyle
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 400"
      width="15"
      height="20"
    >
      <path d="m0,383.68V10.26C3.69,2.28,10.02-.01,18.59,0c145.05.17,290.11.12,435.16.12,13.73,0,18.32,4.53,18.32,18.1,0,119.39,0,238.79,0,358.18,0,13.08-4.65,17.77-17.69,17.77-145.52,0-291.03-.04-436.55.11-8.52,0-14.57-2.57-17.84-10.61Zm177.55-186.77c0,14.43-.07,28.86.06,43.29.03,3.17.21,6.55,1.3,9.47,4.15,11.1,16.58,15.08,27.97,8.56,26.36-15.08,52.58-30.39,78.69-45.9,12.08-7.18,12.12-23.14.05-30.32-26.09-15.53-52.47-30.58-78.63-46.01-13.46-7.94-30.23.54-29.56,17.62.56,14.41.12,28.86.11,43.29ZM74.63,28.1H27.99v23.39h46.63v-23.39Zm74.13-.07h-45.97v23.55h45.97v-23.55Zm222.21,0h-45.97v23.55h45.97v-23.55ZM27.89,342.61v23.63h46.58v-23.63H27.89Zm120.8,23.65v-23.47h-45.94v23.47h45.94Zm176.27-23.61v23.45h45.94v-23.45h-45.94ZM176.8,51.43h46.04v-23.28h-46.04v23.28Zm119.89.24v-23.61h-45.62v23.61h45.62Zm-119.73,314.45h45.88v-23.32h-45.88v23.32Zm119.73.14v-23.51h-45.7v23.51h45.7ZM399.11,27.96v23.53h44.95v-23.53h-44.95Zm45.03,338.32v-23.61h-44.93v23.61h44.93Z" />

      <title>{props.label}</title>
    </VideoIconStyle>
  );
};

export default iconVideo;
