export default class LoaderService {
  elementoLoader() {
    let elemento = document.getElementById("weex-pre-loader");
    if (!elemento) {
      elemento = document.createElement("div");
      elemento.setAttribute("id", "weex-pre-loader");
      elemento.style.display = "none";
      document.appendChild(elemento);
      return elemento;
    }
  }

  load(url) {
    if (document.images) {
      new Image().src = url;
    }
  }
}
