import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import packageInfo from "../../../package.json";
import InfosTextuaisMobile from "../../comps/LogosCampanha/InfosTextuaisMobile";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";
import Navbar from "./navbar/Navbar";
import { WeexHeaderStyle } from "./HeaderStyle";
import WeexMobileMode from "../WeexMode/WeexMobileMode";

function Header() {
  const usuario = useSelector((state) => state.usuario);
  const usuariologado = useSelector((state) => state.usuariologado);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState(false);
  let sair = () => {
    dispatch({ type: "usuario", payload: false });
    new UsuarioService().logout(() => {
      window.location = "/";
    });
  };

  let toggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const campanhaService = new CampanhaService();
  let campanhaCorrente = campanhaService.campanhaCorrente();
  let location = useLocation();
  let beginDate;
  let now = new Date().getTime();
  let tipoInscricao;
  let legalTerm;
  let requiredInterests;

  if (campanhaCorrente) {
    beginDate = campanhaService.getBeginDate();
    tipoInscricao = campanhaCorrente.tipoInscricao;
    legalTerm = campanhaCorrente.legalTerm;
    requiredInterests = campanhaCorrente.requiredInterests;
  }

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px",
    height: isDesktopMode ? "100px" : "",
    width: isDesktopMode ? "100%" : "",
  };
  const styleWithoutTrophy = {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    height: isDesktopMode ? "100px" : "",
    justifyContent: "space-between",
    width: isDesktopMode ? "100%" : "",
  };
  const styleElement = { display: "block", margin: "0px auto 0px auto" };

  if (location.pathname === "/accept") {
    return null;
  }
  return (
    <>
      {usuariologado && usuario === true ? (
        <WeexHeaderStyle className={`weex-container`}>
          {(tipoInscricao === "ELEGIBILIDADE" &&
            legalTerm &&
            !usuariologado.legalTerm) ||
          (requiredInterests === true &&
            location?.pathname === "/interests" &&
            beginDate) <= now ? (
            <Navbar
              style={isDesktopMode ? style : styleWithoutTrophy}
              styleElement={isDesktopMode ? {} : styleElement}
              toggle={toggle}
              toggleMenu={toggleMenu}
              now={now}
              sair={sair}
              versao={packageInfo.version}
            />
          ) : (
            <Navbar
              style={style}
              toggle={toggle}
              toggleMenu={toggleMenu}
              now={now}
              sair={sair}
              versao={packageInfo.version}
            />
          )}
        </WeexHeaderStyle>
      ) : (
        ""
      )}
      <WeexMobileMode>
        {campanhaCorrente?.titleVisible && <InfosTextuaisMobile />}
      </WeexMobileMode>
    </>
  );
}

export default Header;
