import React from "react";
import { useStore } from "react-redux";

// Definindo o componente funcional Pontuacao
const TagPontuacao = ({ totalPoints }) => {
  const i18n = useStore().getState().i18n;
  return (
    <span className="column has-text-centered">
      <h1 className="weex-l-acerteTempo-resposta-tag--textos weex-l-acerteTempo-resposta-tag--pontos-uppercase">
        +{totalPoints || 0}
        {i18n.message("geral.pts", "PTS")}
      </h1>
    </span>
  );
};

export default TagPontuacao;
