import styled from "styled-components";

export const BotaoGameAtividadeContainerStyle = styled.div`
  margin: 1% 4% 4%;

  .weex-l-game {
    margin-top: 1rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-game:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
  /* #endregion DESKTOP */
`;

export const BotaoGameAtividadeStyle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${(props) => {
    return props.theme.cardLogadoTextoPrimeiro;
  }};
  border-radius: 0.625rem;
  border-width: 0.063rem;
  border-style: solid;
  border-color: ${(props) => {
    return props.gameExecuted ? "#00E291" : props.theme.fundoSecundario;
  }};
  width: 100%;
  box-shadow: ${(props) => {
    return props.theme.sombraSecundaria;
  }};

  .weex-l-botao-game-atividade__texto {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
    width: 100%;
  }

  :active {
    filter: brightness(0.8);
  }

  :disabled {
    cursor: not-allowed;
    color: #776969;
    border-width: 0.031rem;
    border-color: #bcb3b3;
    filter: contrast(0.5);
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    cursor: pointer;

    .weex-l-botao-game-atividade__texto {
      width: 78%;
    }
  }
  /* #endregion DESKTOP */
`;
