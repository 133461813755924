import styled from "styled-components";

export const BotaoAtividadeDiaContainerStyle = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  padding-right: 0.5em;
  margin: 0px;

  .weex-l-botao-atividade {
    border-radius: 0.5rem;
    padding: 0.625rem;
    cursor: ${({ $habilitado }) => ($habilitado ? "pointer" : "not-allowed")};
  }

  .weex-l-botao-atividade--executado {
    border: ${({ $executado }) =>
      $executado ? "0.063rem solid #00E291" : "0.063rem solid #CECECE"};
  }

  .weex-l-botao-atividade__icone {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .weex-l-botao-atividade__icone--habilitado {
    background-image: url(${({ $imagemHabilitado }) => $imagemHabilitado});
  }

  .weex-l-botao-atividade__icone--desabilitado {
    background-image: url(${({ $imagemDesabilitado }) => $imagemDesabilitado});
  }

  .weex-l-botao-atividade__icone--tamanho {
    width: 2.125rem;
    height: 2.188rem;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-botao-atividade {
      padding: 0px;
    }

    .weex-l-botao-atividade__icone {
      background-size: 60%;
    }

    .weex-l-botao-atividade__icone--tamanho {
      width: 4vw;
      height: 4vw;
    }
  }
  /* #endregion DESKTOP */
`;

export const TextoDoBotaoAtividadeStyle = styled.div`
  margin-top: 0.5rem;

  .weex-l-texto-botao-atividade {
    font-family: Poppins, sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: black;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-texto-botao-atividade {
      font-size: 0.75rem;
    }
  }
  /* #endregion DESKTOP */
`;
