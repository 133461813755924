import React from "react";
import styled from "styled-components";
const StarCompleta = (props) => {
  return (
    <StarStyled
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 481.57 458.97"
    >
      <path d="m43.35,259.51c-8.5,2.58-16.85,5.69-25.41,8.04-7.66,2.1-14.26,5.28-17.94,12.77v8.46c5.14,10.24,11.46,14.36,20.03,11.88,11.53-3.33,22.96-7.08,34.21-11.23,7.84-2.89,11.47-12.1,8.64-19.85-3.01-8.27-11.23-12.6-19.54-10.08Z" />
      <path d="m357.56,244.38c17.81-16.73,35.2-33.92,52.57-51.1,11.05-10.93,14.03-24.21,9.13-38.78-4.66-13.86-14.56-22.41-29.18-24.66-23.42-3.61-46.86-7.09-70.34-10.24-6.62-.89-10.94-2.82-14.06-9.62-9.83-21.45-20.62-42.47-31.15-63.6-6.84-13.72-18.13-20.63-28.88-21.07-19.56-.48-32.37,7.79-39.5,22.34-10.86,22.18-21.87,44.3-32.62,66.54-1.53,3.16-3.52,4.12-6.79,4.53-9.73,1.21-19.41,2.84-29.11,4.26-13.87,2.02-27.72,4.23-41.64,5.92-15.52,1.89-27.4,8.85-33.15,23.79-6.13,15.93-2.49,30.04,9.71,41.94,16.15,15.75,32.15,31.66,48.56,47.14,5.34,5.04,7.94,9.39,6.2,17.5-4.57,21.24-7.67,42.8-11.31,64.23-1.26,7.41-2.16,14.79.25,22.22,7.41,22.85,31.78,33.33,53.29,22.4,22.03-11.2,43.87-22.77,65.63-34.47,4.23-2.27,7.37-2.09,11.51.14,21.47,11.57,43.1,22.85,64.72,34.14,13.58,7.09,26.98,6.28,39.41-2.43,11.99-8.4,17.78-20.51,15.63-34.98-3.65-24.6-8.27-49.06-12.68-73.55-.91-5.05-.38-8.65,3.8-12.58Z" />
      <path d="m470.7,270.06c-10.3-3.55-20.67-6.87-31.04-10.19-9.64-3.08-18.36,1.26-21.29,10.47-2.69,8.45,2.11,17.15,11.31,20.18,9.02,2.97,18.1,5.76,27.15,8.65,2.81.9,5.51,2.28,8.21,1.87,8.31-.01,14.99-5.55,16.31-13.21,1.31-7.58-3.07-15.16-10.65-17.77Z" />
      <path d="m100.55,51.79c3.26,4.46,7.65,6.77,12.15,6.73,7.47,0,12.19-2.93,15.18-8.45,3.22-5.95,2.48-11.8-1.37-17.2-6.08-8.53-12.29-16.98-18.47-25.44-5.81-7.96-15.37-9.73-23.07-4.27-7.21,5.11-8.68,15.07-3.17,22.83,6.15,8.67,12.46,17.24,18.74,25.82Z" />
      <path d="m369.15,58.52c4.11.18,8.37-2.22,11.52-6.46,6.53-8.78,13.03-17.58,19.25-26.57,5.29-7.63,3.51-17.41-3.67-22.4-7.46-5.19-16.79-3.73-22.39,3.77-6.55,8.76-12.96,17.61-19.27,26.54-3.75,5.3-3.99,11.06-.93,16.77,2.99,5.58,7.78,8.33,15.49,8.35Z" />
      <path d="m240.57,394.17c-8.54.01-15.71,7.19-15.82,15.92-.07,5.33-.01,10.66-.01,15.98,0,5.8-.07,11.6.02,17.4.13,8.66,7.38,15.57,16.13,15.5,8.77-.07,15.77-7.01,15.81-15.78.05-10.97.04-21.94,0-32.91-.04-9-7.21-16.12-16.11-16.11Z" />
    </StarStyled>
  );
};

const StarStyled = styled.svg`
  fill: ${(props) => {
    return props.theme.fundoSecundario || `#533888`;
  }};
`;
export default StarCompleta;
