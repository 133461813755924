import React from "react";
import { useAcerteTempo } from "../../pages/acerteTempo/AcerteTempoContext";
import { useStore } from "react-redux";
import { TagRespostaStyle } from "./TagRespostaStyle";
import TagPontuacao from "./TagPontuacao";

function TagRespostaRevisaoAT() {
  const {
    showTagPontuacao,
    obtemPlacarFinal,
    indiceAcerteTempo,
    campaignType,
  } = useAcerteTempo();

  let correct =
    obtemPlacarFinal?.scores[indiceAcerteTempo]?.correct !== null &&
    obtemPlacarFinal?.scores[indiceAcerteTempo]?.correct !== undefined
      ? obtemPlacarFinal?.scores[indiceAcerteTempo]?.correct
      : null;
  let totalPoints =
    obtemPlacarFinal?.scores[indiceAcerteTempo]?.totalPoints !== null &&
    obtemPlacarFinal?.scores[indiceAcerteTempo]?.totalPoints !== undefined
      ? obtemPlacarFinal?.scores[indiceAcerteTempo]?.totalPoints
      : 0;

  const i18n = useStore().getState().i18n;

  const icons = () => {
    if (correct) {
      return (
        <>
          <span className="weex-l-acerteTempo-placar-desktopIconeSize column has-text-left icon-text weex-l-acerteTempo-resposta-tag--textos weex-l-acerteTempo-icone">
            <i className="fas fa-check"></i>
          </span>
        </>
      );
    } else {
      return (
        <span className="weex-l-acerteTempo-placar-desktopIconeSize column has-text-left icon-text weex-l-acerteTempo-resposta-tag--textos weex-l-acerteTempo-icone">
          <i className="fas fa-times"></i>
        </span>
      );
    }
  };

  if (showTagPontuacao && campaignType !== "REGULAR") {
    return (
      <>
        <TagRespostaStyle correto={correct}>
          <div className="columns is-mobile weex-l-acerteTempo-resposta-tag--bulmaColumn weex-l-acerteTempo-background">
            {icons()}
            <TagPontuacao totalPoints={totalPoints} />
            <div className="column has-text-right" />
          </div>
        </TagRespostaStyle>
        <span className="weex-g-visually-hidden">{`${totalPoints} ${i18n.message(
          "alt.dinamica.acerteTempo.tag.pontosExtenso",
          "pontos",
        )}`}</span>
      </>
    );
  } else {
    return <></>;
  }
}

export default TagRespostaRevisaoAT;
