import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";
import Image from "../ImageLogo/Image";
import InfosTextuais from "./InfosTextuais";
import { CampanhaLogosStyle } from "./style";

const usuarioService = new UsuarioService();
const campanhaService = new CampanhaService();

const fontSizeForTitlesDefautl = "weex-l-font-size-title__default";
const fontSizeForTitlesMedium = "weex-l-font-size-title__medium";
const fontSizeForTitlesLarge = "weex-l-font-size-title__large";

function LogosCampanha() {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  let fontSizeForTitles = fontSizeForTitlesDefautl;
  let usuariologado = !!(
    usuarioService.usuarioCorrente() &&
    usuarioService.usuarioCorrente().token != null
  );
  let campanha = campanhaService.campanhaCorrente();
  let logoCentro = "";
  let logoCentroDesktop = "";
  let campanhaName = "";
  let title = "";
  let titleVisible = true;
  if (campanha) {
    // #region DESKTOP
    if (!campanha.topLeftDesktop) {
      logoCentroDesktop = "";
    } else {
      logoCentroDesktop = campanha.topLeftDesktop;
    }
    // #endregion DESKTOP
    if (!campanha.logoLeft) {
      logoCentro = "";
    } else {
      logoCentro = campanha.logoLeft;
    }
    if (!campanha.title) {
      title = "";
    } else {
      title = campanha.title;
    }
    if (!campanha.name) {
      campanhaName = "";
    } else {
      campanhaName = campanha.name;
    }

    titleVisible = campanha.titleVisible;
  }
  let location = useLocation();

  if (campanha?.styles) {
    let styleByPageEntrada = campanha?.styles?.find(
      (style) => style.page === "ENTRADA",
    );
    if (styleByPageEntrada) {
      switch (styleByPageEntrada.titleFontSize) {
        case "GRANDE":
          fontSizeForTitles = fontSizeForTitlesLarge;
          break;
        case "MEDIO":
          fontSizeForTitles = fontSizeForTitlesMedium;
          break;
        default:
          fontSizeForTitles = fontSizeForTitlesDefautl;
          break;
      }
    }
  }

  const shouldRenderHeader =
    location.pathname === "/" &&
    !usuariologado &&
    usuario === false &&
    !logoCentro &&
    !logoCentroDesktop &&
    !titleVisible;

  if (shouldRenderHeader) {
    return null;
  }

  return (
    <>
      {(location?.pathname === "/atividades" ||
        location?.pathname === "/" ||
        location?.pathname === "/trophy" ||
        location?.pathname === "/interests" ||
        location?.pathname === "/home" ||
        location?.pathname === "/resetPassword" ||
        location?.pathname === "/passwordReset") &&
      !usuariologado &&
      usuario === false ? (
        <header
          className={
            usuariologado && usuario === true
              ? `weex-container weex-cabecalho`
              : `weex-container`
          }
        >
          <CampanhaLogosStyle>
            <div className={`campanha_logosTopo ${fontSizeForTitles}`}>
              {(logoCentro || logoCentroDesktop) &&
              !usuariologado &&
              usuario === false ? (
                <div style={{ marginTop: "100px", marginBottom: "15px" }}>
                  <Image
                    className="campanha_logosTopo__centro"
                    src={logoCentro}
                    alt={i18n.message(
                      "alt.logo.principal.mobile",
                      "Logo principal da empresa",
                    )}
                    classNameDesktop={
                      usuariologado
                        ? "campanha_logosTopo__centro"
                        : "campanha_logosTopo__centro__notLogged"
                    }
                    srcDesktop={logoCentroDesktop}
                    altDesktop={i18n.message(
                      "alt.logo.principal.desktop",
                      "Logo principal da empresa",
                    )}
                  />
                </div>
              ) : (
                ""
              )}

              <InfosTextuais
                campanhaName={campanhaName}
                title={title}
                titleVisible={titleVisible}
              />
            </div>
          </CampanhaLogosStyle>
        </header>
      ) : (
        ""
      )}
    </>
  );
}

export default LogosCampanha;
